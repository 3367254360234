import { Box, makeStyles, } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Logo from '../../Images/logo-ok.svg'
import { setLangClient } from '../../store/actions/userAction'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        background: "white",
        position: "fixed",
        zIndex: "1000",
        top: "0"
    },
    linkAct: {
        fontSize: "1vw",
        lineHeight: "1vw",
        fontWeight: "500",
        color: "#28B0E6",
        textDecoration: "none",
    },
    linkNon: {
        fontSize: "1vw",
        lineHeight: "15px",
        fontWeight: "500",
        color: "black",
        textDecoration: "none",
        '&:hover': {
            color: '#28B0E6',
        },
    },
    lang: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        width: "2.7vw",
        height: "2.7vw",
        color: "white"
    },
    textlang: {
        borderRadius: "50%", padding: "0px", width: "2vw", color: "white", height: "2vw", border: "0.25vw solid white", display: "flex", alignItems: "center", justifyContent: "center",
        fontSize: "1vw",
        fontWeight: "500"

    },
}))
export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()

    function setLang(e, lang) {
        e.preventDefault()
        dispatch(setLangClient(lang))
    }
    
    return (
        <>
            <Box className={classes.root}>
                <Box width="15%" display="flex" justifyContent="center" alignItems="center" paddingY="1vw">
                    <img src={Logo} style={{ height: "80%" }} />
                </Box>
                <Box display="flex" justifyContent="space-between" width="75%" alignItems="center">
                    <Link underline="none" to="/" className={history.location.pathname == '/' ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "HOME" : "BERANDA"}</Link>
                    <Link underline="none" to="/dive-location" className={history.location.pathname.includes('dive-location') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "DIVE LOCATION" : "LOKASI SELAM"}</Link>
                    <Link underline="none" to="/dive-center" className={history.location.pathname.includes('dive-center') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "DIVE CENTER" : "OPERATOR SELAM"}</Link>
                    <Link underline="none" to="/liveaboard" className={history.location.pathname.includes('liveaboard') ? classes.linkAct : classes.linkNon}>LIVEABOARD</Link>
                    <Link underline="none" to="/freediving" className={history.location.pathname.includes('freediving') ? classes.linkAct : classes.linkNon}>FREEDIVING</Link>
                    <Link underline="none" to="/cavediving" className={history.location.pathname.includes('cavediving') ? classes.linkAct : classes.linkNon}>CAVE DIVING</Link>
                    <Link underline="none" to="/livingreefs" className={history.location.pathname.includes('livingreefs') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "LIVING REEFS" : "TERUMBU KARANG"}</Link>
                    <Link underline="none" to="/hyperbaric-chambers" className={history.location.pathname.includes('hyperbaric-chambers') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "HYPERBARIC CHAMBERS" : "FASILITAS HIPERBARIK"}</Link>
                    <Link underline="none" to="/gallery" className={history.location.pathname.includes('gallery') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "PHOTO GALLERY" : "GALERI"}</Link>
                    <Link underline="none" to="/regulations" className={history.location.pathname.includes('regulations') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "REGULATIONS" : "REGULASI"}</Link>
                </Box>
                <Box width="10%" display='flex' alignItems="center" justifyContent="center">
                    <Box style={{ cursor: "pointer" }} variant="contained" bgcolor={langClient == "EN" ? "#28B0E6" : "#CECECE"} className={classes.lang}
                        onClick={(e) => setLang(e, "EN")} marginRight="0.5rem">
                        <Box variant="contained" bgcolor={langClient == "EN" ? "#28B0E6" : "#CECECE"} className={classes.textlang}>
                            EN
                        </Box>
                    </Box>
                    <Box style={{ cursor: "pointer" }} variant="contained" bgcolor={langClient == "IN" ? "#28B0E6" : "#CECECE"} className={classes.lang} onClick={(e) => setLang(e, "IN")} >
                        <Box variant="contained" bgcolor={langClient == "IN" ? "#28B0E6" : "#CECECE"} className={classes.textlang} >
                            IN
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
