import { Typography, Box, Grid, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import Modal from '../../../Components/ModalGallery'
import BoxHover from '../../../Components/BoxHover'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { setGallery } from '../../../store/actions/userAction'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import Axios from 'axios'
import server from '../../../config/server'

export default function Index() {
    const [openModal, setOpenModal] = useState(false)
    const [start, setStart] = useState(0)
    const [openBoxHover, setOpenBoxHover] = useState([false, false, false])
    const [flag, setflag] = useState(true)
    const [gallery, setGallery] = useState([])
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()
    const [load, setLoad] = useState(1)
    const [images, setImages] = useState([])

    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/gallery/offset/0/16`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                Swal.close()
                setGallery(data.data)
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }, [])

    function loadmore() {
        let offset = 16 * load
        let oldData = gallery
        let loadCount = load
        Swal.showLoading()
        Axios({
            url: `${server}/gallery/offset/${offset}/16`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                let newData = oldData.concat(data.data)
                setGallery(newData)
                loadCount++
                setLoad(loadCount)
                Swal.close()
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }

    function handleHover(e, i, stat) {
        e.preventDefault()
        let temp = openBoxHover
        temp[i] = stat
        setOpenBoxHover(temp)
        setflag(!flag)
    }

    function clickImg(i) {
        if (!matches) {
            handleOpenModal()
            setStart(i)
        }

    }

    function handleOpenModal() {
        setOpenModal(true)
    }

    function closeModal() {
        setOpenModal(false)
    }

    function changeOpenModal(i) {
        setOpenModal(true)
        setStart(i)
    }  
    
    useEffect(() => {
        let img = [];

        gallery.map((item) => {
            img.push({
                original: item.photo,
            });
        })

        setImages(img)

    }, [gallery])

    return (
        <>
            <div>
                <Modal open={openModal} start={start} images = {images} handleClose={closeModal} />
            </div>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "10rem" : "0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" textAlign="center" marginBottom="5rem">
                <Box marginTop="5rem" display="flex" flexDirection="column" alignItems="center">
                    <Typography variant="h3" className="titlePage" style={{ fontWeight: "900" }}>{langClient == 'EN' ? "Photo Gallery" : "Galeri"}</Typography>
                </Box>
                <Box marginTop="5rem">
                    <Grid container spacing={3}>
                        {
                            gallery.map((loc, i) => {
                                return (
                                    <Grid key={i} item xs={12} sm={3}>
                                        <Box borderRadius="4px" onClick={() => clickImg(i)} style={{ cursor: "pointer" }}>
                                            <Box position="relative" onPointerEnter={matches?((e) => handleHover(e, i, true)):null} onPointerLeave={(e) => handleHover(e, i, false)}>
                                                <Box height={matches?"15vw":"250px"} style={{ backgroundImage: `url(${loc.photo})`, backgroundSize: "cover", backgroundPosition: "center" }}></Box>
                                                {openBoxHover[i] ? <BoxHover changeOpenModal={()=>changeOpenModal(i)} i={i} gallery={true} /> : null}
                                            </Box>
                                            <Box textAlign="left" marginY="1rem">
                                                <Typography>{langClient == "EN" ? loc.captionEng : loc.captionInd}</Typography>
                                                <Box marginTop="0.5rem">
                                                    <Typography variant="caption" style={{ color: "#666666" }}>Photographer: {loc.photographer}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Box marginTop='2rem'>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={loadmore}>Load More</Button>
                    </Box>
                </Box>
            </Box>
            <Box marginTop='10rem'>
                <Footer />
            </Box>
        </>
    )
}
