import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles({
    buttonOK: {
        padding: "0.5em",
        textTransform: "none",
        marginRight: "1rem"
    },
    boxCircle: {
        borderRadius: "50%",
        height: "9rem",
        width: "9rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#EEFAFF"
    },
    buttonBatal: {
        textTransform: 'none',
        padding: "0.5em",
        marginLeft: "1rem"
    }
})


export default function Index(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(props.open);
    const history = useHistory()

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])



    console.log(open)

    return (
        <>
            <Box>
                
            </Box>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" maxWidth="sm" open={open}>
                <Box align="center" marginTop="2rem">
                    <Box className={classes.boxCircle}><i className="fa fa-check-circle" aria-hidden="true" style={{ color: "#28B0E6", fontSize: "8rem" }}/></Box>
                </Box>
                <Box paddingX="6rem" paddingTop="1rem"><Typography variant="h5" style={{fontWeight: "500"}} align="center"> Berhasil Menambahkan {props.location} ke Daftar {props.title} </Typography></Box>
                <Box display="flex" justifyContent="center" marginY="2em">
                    <Box width={1 / 4}><Button variant="contained" fullWidth  color="primary" onClick={props.handleClose} className={classes.buttonOK}>Tambah Lagi</Button></Box>
                    <Box width={1 / 4}><Button variant="outlined" fullWidth color="primary" onClick={(e) => [history.push(`${props.to}`)]}  className={classes.buttonBatal} >Tidak</Button></Box>
                </Box>
            </Dialog>
        </>
    )
}
