import { Typography, Box, Grid, Button, FormControl, InputLabel, Select, MenuItem, } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../../Components/AppBarClient'
import Footer from '../../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../../Components/Drawer'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useParams } from 'react-router-dom'
import Axios from 'axios'
import server from '../../../../config/server'
import BoxList from './BoxList'
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 3, 4, 5, 6, 7]

export default function Index() {
    const [diveCenter, setDiveCenter] = useState([])
    const [data, setData] = useState(diveCenter)
    const matches = useMediaQuery('(min-width:600px)');
    const [locations, setLocations] = useState({ sum: ['Weh Island', 'Riau Islands', 'Bangka Belitung', 'North Sumatra'], sul: ['North Sulawesi', 'Central Sulawesi', 'South Sulawesi', 'South East Sulawesi'] })
    const [location, setLocation] = useState('')
    const { island } = useParams()
    const [load, setLoad] = useState(1)
    const langClient = useSelector(state => state.data.langClient)

    function changeLocation(e) {
        setLocation(e.target.value)
    }

    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/divecenter/island/${island}/offset/0/8`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                setDiveCenter(data.data)
                Swal.close()
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }, [])

    function loadmore() {
        let offset = 8*load
        let oldData = diveCenter
        let loadCount = load
        Swal.showLoading()
        Axios({
            url: `${server}/divecenter/island/${island}/offset/${offset}/8`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                let newData = oldData.concat(data.data) 
                setDiveCenter(newData)
                loadCount++
                setLoad(loadCount)
                Swal.close()
            })
            .catch(err => {
                console.log(err,'=====errro')
            })

    }


    useEffect(() => {
        let temp = diveCenter
        if (location) {
            temp = diveCenter.filter((item) => item.location.toLowerCase() == location.toLowerCase())
        }
        setData(temp)

    }, [location])

    console.log(diveCenter, '=============')

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "10rem" : "0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" textAlign="center" marginBottom="5rem">
                <Box marginBottom="4rem">
                    <Typography style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}>{langClient == "EN" ? "Dive Centers in" : "Operator Selam di"} {island}</Typography>
                </Box>
                {
                    island == 'Sumatra' || island == 'Sulawesi' ? <Box display="flex" marginTop="3rem" marginBottom="1rem">
                        <Box width={matches ? "15rem" : "12rem"} marginRight="1rem">
                            <FormControl variant="outlined" size="small" fullWidth>

                                <InputLabel><Box display="flex" alignItems="center"><LocationOnIcon style={{ fontSize: "1.1rem" }} />Select Location</Box></InputLabel>
                                <Select
                                    label={<><Box display="flex" alignItems="center"><LocationOnIcon style={{ fontSize: "1.1rem" }} />Select Location</Box></>}
                                    value={location}
                                    onChange={changeLocation}
                                >
                                    <MenuItem value=""><Box width="100%" textAlign="center" style={{ color: "#28B0E6" }}>Reset Filter</Box></MenuItem>
                                    {
                                        (island == 'Sumatra' ? locations.sum : locations.sul).map((loc, i) => {
                                            return (<MenuItem key={i} value={loc}>{loc}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display='flex' alignItems="center">
                            <Typography>{location ? `Location in ${location}` : "All Location"}</Typography>
                        </Box>
                    </Box> : null
                }
                <Box style={{ color: "#666666" }}>
                    <Grid container spacing={3}>
                        {
                            (location ? data : diveCenter).map((loc, i) => {
                                return (
                                    <Grid key={i} item xs={12} sm={3}>
                                        <Box display="flex" justifyContent={matches ? "" : "center"}>
                                            <BoxList loc={loc} />
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Box marginTop='100px' marginBottom='146px'>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={loadmore}>Load More</Button>
                    </Box>
                </Box>
            </Box>
            <Footer />
        </>
    )
}
