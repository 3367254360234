import React from 'react';
import { Switch, Route } from 'react-router-dom'
import Dashboard from './Views/Admin/index'
import Login from './Views/Admin/Login'
import AddLocation from './Views/Admin/DiveLocation/AddLocation'
import EditLocation from './Views/Admin/DiveLocation/EditLocation'
import AddArea from './Views/Admin/DiveLocation/AddAreaVisit'
import EditArea from './Views/Admin/DiveLocation/EditAreaVisit'
import AddDiveCenter from './Views/Admin/DiveOperator/AddDiveCenter'
import EditDiveCenter from './Views/Admin/DiveOperator/EditDiveCenter'
import AddLiveOnBoard from './Views/Admin/LiveOnBoard/AddLiveOnBoard'
import EditLiveOnBoard from './Views/Admin/LiveOnBoard/EditLiveOnBoard'
import AddHyperbaric from './Views/Admin/Hyperbaric/AddHyperbaric'
import EditHyperbaric from './Views/Admin/Hyperbaric/EditHyperbaric'
import AddGallery from './Views/Admin/Gallery/AddGallery'
import EditGallery from './Views/Admin/Gallery/EditGallery'
import AddNews from './Views/Admin/News/Add'
import EditNews from './Views/Admin/News/Edit'


import DetailArea from './Views/Client/DiveLocation/DetailAreaToVVisit'
import ViewLocation from './Views/Client/DiveLocation/viewLocation'
import DiveLocation from './Views/Client/DiveLocation'
import DiveCenter from './Views/Client/DiveCenter'
import ViewDiveCenter from './Views/Client/DiveCenter/ViewDiveCenter'
import Liveaboard from './Views/Client/Liveaboard'
import Freediving from './Views/Client/Freediving'
import Cavediving from './Views/Client/Cavediving'
import DetailCavediving from './Views/Client/Cavediving/Detail'
import Landingpage from './Views/Client'
import Livingreefs from './Views/Client/Livingreefs'
import Hyperbaric from './Views/Client/Hyperbaric'
import Regulation from './Views/Client/Regulation'
import Countries from './Views/Client/Regulation/Countries'
import Gallery from './Views/Client/Gallery'
import News from './Views/Client/News'
import './App.css';

function App() {
   
   return (
      <Switch>
         <Route path="/admin/news/edit/:id" component={EditNews} />
         <Route path="/admin/news/add" component={AddNews} />
         <Route path="/admin/gallery/edit/:id" component={EditGallery} />
         <Route path="/admin/gallery/add" component={AddGallery} />
         <Route path="/admin/hyperbaric-chambers/:location/edit/:id" component={EditHyperbaric} />
         <Route path="/admin/hyperbaric-chambers/:location/add" component={AddHyperbaric} />
         <Route path="/admin/liveaboard/edit/:id" component={EditLiveOnBoard} />
         <Route path="/admin/liveaboard/add" component={AddLiveOnBoard} />
         <Route path="/admin/dive-center/:island/edit/:id" component={EditDiveCenter} />
         <Route path="/admin/dive-center/:island/add" component={AddDiveCenter} />
         <Route path="/admin/dive-location/area/:island/edit/:id" component={EditArea} />
         <Route path="/admin/dive-location/area/:island/add" component={AddArea} />
         <Route path="/admin/dive-location/add" component={AddLocation} />
         <Route path="/admin/dive-location/edit/:id" component={EditLocation} />
         <Route path="/admin/login" component={Login} />
         <Route path="/admin/" component={Dashboard} />

         <Route path="/dive-location/:island/:id" component={DetailArea} />
         <Route path="/dive-location/:island" component={ViewLocation} />
         <Route path="/dive-location" component={DiveLocation} />
         <Route path="/dive-center/:island" component={ViewDiveCenter} />
         <Route path="/dive-center" component={DiveCenter} />
         <Route path="/liveaboard" component={Liveaboard} />
         <Route path="/freediving" component={Freediving} />
         <Route path="/cavediving/:loc" component={DetailCavediving} />
         <Route path="/cavediving" component={Cavediving} />
         <Route path="/livingreefs" component={Livingreefs} />
         <Route path="/hyperbaric-chambers" component={Hyperbaric} />
         <Route path="/gallery" component={Gallery} />
         <Route path="/news/:id" component={News} />
         <Route path="/regulations/countries" component={Countries} />
         <Route path="/regulations" component={Regulation} />
         <Route exact path="/" component={Landingpage} />
      </Switch>
   );
}

export default App;
