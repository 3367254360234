import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import store from './store'
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core';

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#28B0E6",
      light: '#53bfeb',
      dark: '#1c7ba1',
      contrastText: '#fff',
    },
    secondary: {
      main: "#6D519D",
      light: "#8a73b0",
      dark: "#4c386d",
      contrastText: "#fff"
    },
  },
  typography: {
    "fontFamily": `'Signika', sans-serif`,
   }
  
});

theme=responsiveFontSizes(theme)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <Router>
          <App />
        </Router>
      </ThemeProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
