export const rows = {
    A: ["Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan"],
    B: [
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bhutan",
        "Bolivia",
        "Bosnia & Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
    ],
    C: [
        "Cambodia",
        "Canada",
        "Cape Verde",
        "Chad",
        "Chile",
        "China",
        "Comoros",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
    ],
    D: [
        "Denmark",
        "Dominica (Commonwealth)",
        "Dominican Republic",
    ],
    E: [
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Estonia",
    ],
    F: [
        "Fiji",
        "Finland",
        "France",
    ],
    G: [
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Grenada",
        "Guatemala",
        "Guyana",
    ],
    H: [
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
    ],
    I: [
        "Iceland",
        "India",
        "Ireland",
        "Italy",
        "Ivory Coast",
    ],
    J: [
        "Jamaica",
        "Japan",
        "Jordan",
    ],
    K: [
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Kuwait",
        "Kyrgyzstan",
    ],
    L: [
        "Lao, People’s Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liechtenstein",
        "Lithuania",
        "Luxemburg",
    ],
    M: [
        "Macao",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Morocco",
        "Mozambique",
        "Myanmar",
    ],
    N: [
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Norway",
    ],
    O: [
        "Oman"
    ],
    P: [
        "Palau",
        "Palestine",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Puerto Rico",
    ],
    Q: [
        "Qatar"
    ],
    R: [
        "Romania",
        "Russian Federation",
        "Rwanda",
    ],
    S: [
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "South Africa",
        "South Korea",
        "Spain",
        "Sri Lanka",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
    ],
    T: [
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Timor Leste",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Tuvalu",
    ],
    U: [
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekista",
    ],
    V: [
        "Vanuatu",
        "Vatican City State",
        "Venezuela",
        "Vietnam",
    ],
    Z: [
        "Zambia",
        "Zimbabwe"
    ]
};


function createData (A, B, C, D, E, F, G, H) {
    return (A, B, C, D, E, F, G, H)
}

// const data = [
//     createData()
// ]
