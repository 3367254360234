import { Box, Button, Typography, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../../Components/AppBarClient'
import Footer from '../../../../Components/Footer'
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DrawerMobile from '../../../../Components/Drawer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import server from '../../../../config/server';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2';

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                color: "#444444"
            }
        }
    }
})

export default function Index() {
    const [by, setBy] = useState("Land")
    const matches = useMediaQuery('(min-width:600px)');
    const { id } = useParams()
    const [data, setData] = useState({})
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()

    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/visit/detail/${id}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {
                Swal.close()
                setData(data)
                // setAreas(data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    console.log(data.photo)

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }
    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            {   data &&
                // <Box width="100%" height={matches ? "62vw" : '321px'} style={data.photo ? { backgroundImage: `url(${data.photo})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: 'no-repeat' } : { backgroundColor: "white" }}>
                // </Box>
                <Box marginTop='4vw'>
                    <img src={data.photo} style={{ width: '100%' }} />
                </Box>
            }
            <Box display="flex" justifyContent="center">
                <Box width={matches ? '950px' : '100%'}>
                    <Box style={{ textAlign: "center" }} marginY="2rem">
                        <Typography variant='h4' style={{ fontWeight: "500" }}>{langClient == "EN" ? data.nameEng : data.nameInd}</Typography>
                        <Typography variant='h6' style={{ fontWeight: "400" }}>{langClient == "EN" ? data.cityEng : data.cityInd}</Typography>
                    </Box>
                    <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginY="2rem">
                        <Box textAlign="center">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor
                                    defaultValue={langClient == "EN" ? data.descriptionEng : data.descriptionInd}
                                    readOnly={true}
                                    toolbar={false}
                                />
                            </MuiThemeProvider>
                        </Box>
                        <Box bgcolor="#FFFFFF" boxShadow="0px 10px 30px 10px rgba(117, 117, 117, 0.1)" padding={matches ? "1rem 1.5rem" : "1rem 0.5rem"} marginTop="2rem">
                            <Box>
                                <Typography variant="h5" style={{ fontWeight: "500" }} >{langClient == 'EN' ? 'How To Get There' : 'Cara Menuju ke Sana'}</Typography>
                            </Box>
                            <Box marginTop="1rem">
                                <Grid container spacing={1} >
                                    <Grid item xs={12} sm={3} container={!matches} justify="space-around">
                                        <Box width={matches ? "100%" : "30%"}>
                                            <Button fullWidth variant={by === "Land" ? "contained" : "outlined"} color={by === "Land" ? "secondary" : ""}
                                                onClick={e => setBy('Land')} style={{ textTransform: "none", padding: "0.25rem" }}>
                                                <Box marginRight={matches ? "1rem" : "0.5rem"} display="flex" alignItems="center"><AirportShuttleIcon /></Box>
                                                {langClient == 'EN' ? 'By Land' : 'Akses Jalur Darat'}
                                            </Button>
                                        </Box>
                                        <Box width={matches ? "100%" : "30%"} marginY={matches ? "1rem" : "0rem"} >
                                            <Button fullWidth variant={by === "Sea" ? "contained" : "outlined"} color={by === "Sea" ? "secondary" : ""} onClick={e => setBy('Sea')} style={{ textTransform: "none", padding: "0.25rem" }}>
                                                <Box marginRight={matches ? "1rem" : "0.5rem"} display="flex" alignItems="center"><DirectionsBoatIcon /></Box>
                                                {langClient == 'EN' ? 'By Sea' : 'Akses Jalur Laut'}
                                            </Button>
                                        </Box>
                                        <Box width={matches ? "100%" : "30%"}>
                                            <Button fullWidth variant={by === "Air" ? "contained" : "outlined"} color={by === "Air" ? "secondary" : ""} onClick={e => setBy('Air')} style={{ textTransform: "none", padding: "0.25rem" }}>
                                                <Box marginRight={matches ? "1rem" : "0.5rem"} display="flex" alignItems="center"><FlightTakeoffIcon /></Box>
                                                {langClient == 'EN' ? 'By Air' : 'Akses Jalur Udara'}
                                            </Button>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={9}>
                                        <Box bgcolor="#FBF9FF" p="1rem" border="1px solid #E5D6FF" borderRadius="4px">
                                            {by == 'Land' ? <MuiThemeProvider theme={defaultTheme}>
                                                <MUIRichTextEditor
                                                    defaultValue={langClient == "EN" ? data.landEng : data.landInd}
                                                    readOnly={true}
                                                    toolbar={false}
                                                />
                                            </MuiThemeProvider> : null}
                                            {by == 'Sea' ? <MuiThemeProvider theme={defaultTheme}>
                                                <MUIRichTextEditor
                                                    defaultValue={langClient == "EN" ? data.seaEng : data.seaInd}
                                                    readOnly={true}
                                                    toolbar={false}
                                                />
                                            </MuiThemeProvider> : null}
                                            {by == 'Air' ? <MuiThemeProvider theme={defaultTheme}>
                                                <MUIRichTextEditor
                                                    defaultValue={langClient == "EN" ? data.airEng : data.airInd}
                                                    readOnly={true}
                                                    toolbar={false}
                                                />
                                            </MuiThemeProvider> : null}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Box marginTop='5rem'>
                <Footer />
            </Box>
        </>
    )
}
