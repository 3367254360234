import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import chse from '../../../Images/chse.pdf'
import chseInd from '../../../Images/chse-1-IND.pdf'
import { useSelector } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  boxFile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#EEF7FF",
    borderRadius: "4px"
  },
  download: {
    textTransform: "none", width: "10rem", boxShadow: "none"
  },
  downloadNative: {
    textTransform: "none", width: "3rem", fontSize: "11px", boxShadow: "none", padding: "0rem"
  },
  filename: {
    fontWeight: "500",
    color: "#999999"
  }
}))

export default function ScrollDialog() {
  const langClient = useSelector(state => state.data.langClient)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const matches = useMediaQuery('(min-width:600px)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={() => handleClickOpen()} color="primary" variant="contained" style={{ textTransform: "none", width: "10rem", boxShadow: "none" }}>Detail</Button>
      <Dialog
      
      style={{maxHeight:"80%", marginTop:"5%"}}
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title"> {langClient == 'EN'? 'Guidance for Hygiene, Health, Safety, and Environmental Preservation':'Panduan Pelaksanaan Kebersihan, Kesehatan, Keselamatan, dan Kelestarian Lingkugan Usaha Wisata Selam'}</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
              <Grid container spacing={matches?3:1}>
                <Grid item xs={4} sm={4}>
                  <Box className={classes.boxFile} padding={matches?"2rem":"0.5rem"}>
                    <DescriptionIcon style={matches?{ fontSize: "4rem" }:{ fontSize: "2.5rem" }} />
                    <Box marginY={matches?"1rem":"0.5rem"}>
                      <Typography style={matches?{ fontSize: "14px" }:{ fontSize: "11px" }} className={classes.filename}>CHSE</Typography>
                    </Box>
                    <a href={langClient =='EN'? chse:chseInd} target="_blank" style={{ textDecoration: 'none' }} download> 
                    <Button variant="contained" color="primary" className={matches?classes.download:classes.downloadNative}>Download</Button>
                    </a>
                  </Box>
                </Grid>
                {/* <Grid item xs={4} sm={4}>
                  <Box className={classes.boxFile} padding={matches?"2rem":"0.5rem"}>
                    <DescriptionIcon style={matches?{ fontSize: "4rem" }:{ fontSize: "2.5rem" }} />
                    <Box marginY={matches?"1rem":"0.5rem"}>
                      <Typography style={matches?{ fontSize: "14px" }:{ fontSize: "11px" }} className={classes.filename}>File Name</Typography>
                    </Box>
                    <Button  variant="contained" color="primary" className={matches?classes.download:classes.downloadNative}>Download</Button>
                  </Box>
                </Grid>
                <Grid item xs={4} sm={4}>
                  <Box className={classes.boxFile} padding={matches?"2rem":"0.5rem"}>
                    <DescriptionIcon style={matches?{ fontSize: "4rem" }:{ fontSize: "2.5rem" }} />
                    <Box marginY={matches?"1rem":"0.5rem"}>
                      <Typography style={matches?{ fontSize: "14px" }:{ fontSize: "11px" }} className={classes.filename}>File Name</Typography>
                    </Box>
                    <Button variant="contained" color="primary" className={matches?classes.download:classes.downloadNative}>Download</Button>
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
           {
             langClient == 'EN'?
             <Box marginTop="1rem">
             Since early 2020, the COVID-19 (or coronavirus) pandemic has been changing life as we know it. One major impact of the pandemic has been an increased awareness of personal hygiene with people adopting precautionary measures to prevent the disease from spreading. These precautions must also be extended to all activities related to tourism.
             <br/><br/>
             To ensure that all activities related to diving tourism in Indonesia are carried out in accordance with best practices for preventing the spread of COVID-19, the Ministry of Tourism and Creative Economy/the Tourism Ministry and the Creative Economy Agency of the Republic of Indonesia together with the Divers Alert Network (DAN) Indonesia has compiled a handbook to provide guidance for hygiene, health, safety, and environmental preservation in the diving industry in the post-COVID-19 world, called “Hygiene, Health, Safety, and Environmental Preservation Guidance for Indonesia’s Dive Industry”
             <br/><br/>
             This handbook is intended as a guide for everyone working in dive tourism, and their customers, providing a framework and reference for safely conducting diving activities in Indonesia.
             </Box>:
              <Box marginTop="1rem">
              Indonesia merupakan negara kepulauan yang terletak di garis khatulistiwa dan wilayah coral triangle sehingga kaya akan keanekaragaman hayati biota laut. Berkat kekayaan biota laut yang melimpah, Indonesia berhasil meraih awards sebagai World’s Best Scuba Dive Destination selama 3 tahun berturut-turut (2017-2019) dari media divemagazine.uk.
              <br/><br/>
              Pada tahun 2020, seluruh dunia tidak terkecuali Indonesia, dilanda pandemi global berupa Corona Virus Disease 19 (COVID-19) yang secara sistematis mengubah gaya hidup manusia untuk lebih meningkatkan kesadaran akan kebersihan agar terhindar dari penyakit, terutama penularan COVID-19 pada aktivitas di lingkungan masyarakat termasuk aktivitas pariwisata. Mengingat pentingnya kewaspadaan terhadap hal tersebut, maka pada penerapan aktivitas wisata khususnya wisata selam diperlukan adanya handbook sebagai turunan dari Keputusan Menteri Kesehatan (KMK) No. 382 Tahun 2020 yang berisi panduan pelaksanaan kebersihan, kesehatan, keselamatan, dan dan kelestarian lingkungan untuk situasi kenormalan baru atau new normal. Penyusunan handbook ini menekankan penyesuaian pada aktivitas wisata selam terhadap protokol kesehatan yang harus dipatuhi selama pandemi COVID-19.
              <br/><br/>
              Sebagai bentuk keberlangsungan dan pengembangan wisata selam di Indonesia, Kementerian Pariwisata dan Ekonomi Kreatif/Badan Pariwisata dan Ekonomi Kreatif bekerja sama dengan Divers Alert Network (DAN) Indonesia untuk menyusun handbook Panduan Pelaksanaan Kebersihan, Kesehatan, Keselamatan, dan Kelestarian Lingkungan untuk Usaha Wisata Selam yang secara baku akan menjadi panduan bagi pelaku usaha wisata selam, pekerja, pelanggan dan/atau wisatawan dalam melakukan aktivitas wisata selam.
              <br/><br/>
              Dengan adanya handbook ini diharapkan seluruh pelaku usaha wisata selam di Indonesia siap untuk memulai aktivitas wisata selam yang memperhatikan situasi tatanan baru dan protokol new normal. Selain untuk meminimalisasi penyebaran COVID-19, handbook ini tentu memiliki tujuan agar wisata selam di Indonesia memenuhi standar keselamatan serta mendapatkan kepercayaan dalam aspek kesehatan dari wisatawan selam nusantara maupun mancanegara, sehingga industri wisata selam di Indonesia dapat bangkit kembali.
              </Box> 
           }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}