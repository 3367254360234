import { Typography, Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import AppBarClient from '../../../../Components/AppBarClient'
import Footer from '../../../../Components/Footer'
import ImageGallery from 'react-image-gallery';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../../Components/Drawer'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import kupang1 from '../../../../Images/CD_kupang1_L.png'
import kupang2 from '../../../../Images/CD_kupang2_L.png'
import kupangM1 from '../../../../Images/CD_kupang1.jpg'
import kupangM2 from '../../../../Images/CD_kupang2.jpg'

import bau1 from '../../../../Images/CD_Baubau002_L.jpg'
import bau2 from '../../../../Images/CD_Baubau001_L.jpg'
import bauM1 from '../../../../Images/CD_Baubau001.jpg'
import bauM2 from '../../../../Images/CD_Baubau002.jpg'

export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const { loc } = useParams()
    const [images, setImages] = useState([
        {
            original: kupang1,
        },
        {
            original: kupang2,
        },

    ])
    const [imagesMobile, setImagesMobile] = useState([
        {
            original: kupangM1,
        },
        {
            original: kupangM2,
        },

    ])

    const [imagesB, setImagesB] = useState([
        {
            original: bau1,
        },
        {
            original: bau2,
        },

    ])
    const [imagesMobileB, setImagesMobileB] = useState([
        {
            original: bauM1,
        },
        {
            original: bauM2,
        },

    ])
    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            {
                loc == 'kupang' ?
                    <Box>
                        <ImageGallery fullscreen={true} items={matches ? images : imagesMobile} showFullscreenButton={false} showThumbnails={false} showPlayButton={false} />
                    </Box> :
                    <Box>
                        <ImageGallery fullscreen={true} items={matches ? imagesB : imagesMobileB} showFullscreenButton={false} showThumbnails={false} showPlayButton={false} />
                    </Box>
            }

            <Box marginTop={matches ? "10rem" : "0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" marginTop="2rem" marginBottom="5rem">
                <Box display="flex" justifyContent="center">
                    <Box width={matches ? '950px' : '100%'}>
                        <Box marginY="1rem" >
                            {
                                loc == 'kupang' ?
                                    <Typography variant="h5" style={{ fontWeight: "500", fontSize: "32px", lineHeight: "37px" }}>
                                        Kupang, {langClient == 'EN' ? "East Nusa Tenggara" : "Nusa Tenggara Timur"}
                                    </Typography>
                                    :
                                    <Typography variant="h5" style={{ fontWeight: "500", fontSize: "32px", lineHeight: "37px" }}>
                                        Bau-Bau, {langClient == 'EN' ? "Southeast Sulawesi" : "Sulawesi Tenggara"}
                                    </Typography>
                            }
                        </Box>
                        {
                            loc == 'kupang' ?
                                <Box >
                                    {
                                        langClient == 'EN' ?
                                            <Typography variant="paragraph" style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "21px" }}>
                                                Cave diving in Indonesia began in Kupang with the discovery of two cave systems, the Crystal Cave system and the Oehani Cave system – both of which offer phenomenal opportunities for cave divers. You’ll find crystal clear waters which fill the submerged passages, leading into colossal, cathedral like air chambers.
                                        <br /> <br />
                                        Both cave systems are accessed via sink holes located on Kupang’s highlands and a local guide is strongly recommended along with a backup, support team.
                                        <br /> <br />
                                        The Oehani sink hole is a huge crater which requires some clambering down. Once in the base of the crater the shimmering entry pool awaits.  Oehani offers divers a challenging route of around 500 meters of tunnels and passages with 3 air chambers along the route.
                                        <br /> <br />
                                        Crystal Cave is smaller than Oehani, with a 100-meter long tunnel leading to one single cavernous air chamber. Both routes have completely submerged passages where surfacing is not possible. It is strongly recommended that divers follow all cave diving safety protocols including the use of guide lines and back up air supplies.
                                        <br /> <br />
                                        Crystal Cave is both shorter and less technically challenging than the Oehani cave system, however, for both cave dives, cave diving experience and certification is essential prior to attempting these dives.
                                        <br /> <br />
                                        In both cave systems you’ll find narrow swim through points, halocline chambers, air chambers, pools of glimmering light and geologically fascinating shell encrusted walls, and crystal calcite stalactites. In some places, the tunnels reach up to 14 meters in depth and are an estimated 35 meters below ground level.
                                        <br /> <br />
                                        For cave divers, these two cave systems offer excellent diving in warm water with visibility of up to 50 meters.
                                        <br /> <br />
                                        These are the easiest two systems to access, however, there are more sink holes and cave systems on Kupang which are still being discovered and explored for more diving possibilities.
                                        <br /> <br />
                                        These are the easiest two systems to access, however, there are more sink holes and cave systems on Kupang which are still being discovered and explored for more diving possibilities.

                                    </Typography> :
                                            <Typography variant="paragraph" style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "21px" }}>
                                                Aktivitas cave diving di Indonesia bermula dengan penemuan dua jalur gua di Kupang, yaitu Gua Kristal dan Gua Oehani. Keduanya menawarkan kesempatan luar biasa bagi para cave diver. Kedua gua ini dipenuhi air yang sangat jernih di sepanjang  lorong-lorongnya, mengalir menuju ruang-ruang udara yang megah seperti katedral.
                                     <br /> <br />
                                     Kedua gua ini diakses dari daerah di dataran tinggi Kupang melalui akses lubang yang menurun ke bawah tanah. Sangat disarankan untuk menggunakan pemandu setempat dan tim pendukung yang mumpuni.
                                     <br /> <br />
                                     Lubang masuk ke Oehani berbentuk rongga besar dengan jalur turun ke dasar hingga mencapai jalur masuk yang berair jernih. Rute di gua Oehani sangatlah menantang sepanjang sekitar 500 meter, melewati lorong dan terowongan yang memiliki terdapat tiga ruang udara.
                                     <br /> <br />
                                     Ukuran Gua Kristal lebih kecil dibanding Oehani, dengan terowongan sepanjang 100 meter yang mengarah menuju satu gua bawah air saja. Kedua rutenya melintasi  lorong yang sepenuhnya terendam dan tidak memungkinkan untuk naik ke permukaan.  Para penyelam sangat disarkan untuk mematuhi semua protokol keselamatan cave diving, termasuk penggunaan garis panduan dan tangki udara cadangan.
                                     <br /> <br />
                                     Gua Kristal juga memiliki rute yang lebih pendek dan secara teknis tidak sesulit dibandingkan dengan Gua Oehani. Namun demikian, untuk melakukan aktivitas ini Anda harus memiliki pengalaman dan sertifikasi cave diving sebelum mencoba untuk menyelam di kedua gua ini.
                                     <br /> <br />
                                     Di gua Kristal dan Oehani, banyak dijumpai jalur-jalur sempit yang melintasi sejumlah titik, ruang haloklin, ruang udara, kolam air dengan cahaya berkilauan, dinding gua yang dipenuhi cangkang kerang yang menakjubkan, serta stalaktit dari kristal kalsit. Di sejumlah titik, kedalaman terowongan mencapai 14 meter, atau 35 meter di bawah permukaan tanah.
                                     <br /> <br />
                                     Di dalam kedua gua ini, Anda dapat menikmati pengalaman selam yang menakjubkan, dengan air hangat dan jarak pandang hingga 50 meter.
                                     <br /> <br />
                                     Akses ke 2 gua ini dapat dikatakan sebagai lokasi cave diving yang cukup mudah. Sementara itu, masih banyak lagi jalur sink hole dan gua dalam tahap penjajakan untuk dipastikan kelayakannya sebagai lokasi kegiatan cave diving.

                                 </Typography>
                                    }


                                </Box>
                                :
                                <Box>
                                    {
                                        langClient == 'EN' ?
                                            <Typography variant="paragraph" style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "21px" }}>
                                                Bau Bau in South East Sulawesi is becoming known as Indonesia’s premier cave diving location. There are literally hundreds of caves in the region and many more are still being discovered and explored.
                                        <br /> <br />
                                        Bau-bau is relatively easy to access with direct flights from Makassar and ferries from numerous ports around Indonesia. Bau-bau is the main city on Buton Island and there are cave dive centers who are experts in the local cave systems as well as operators who offer technical diving support, tri-mix and CCR services.
                                        <br /> <br />
                                        Bau-bau sits on a limestone bed through which a labyrinth of underground caves interconnect. There are caves here suitable for beginners as well as for more experienced cave divers.
                                        <br /> <br />
                                        For those who want to go truly off the grid, nearby Muna Island is a short ferry ride from Buton and home to hundreds of caves, many of which are still being mapped and investigated. Muna is extremely remote with no tourist infrastructure but for those who want adventure (and don’t mind camping), there are some truly spectacular caves.
                                        <br /> <br />
                                        Although South East Sulawesi is known best for the coral reefs around the Wakatobi region, this same region is where you will find some of the best caves to dive. The limestone foundations of these ancient volcanic islands are home to dozens of shallow, warm water cave dives. The caves located around Wangi-Wangi and Tomia offer some of the easiest conditions for those who are starting out in cave diving or want to refresh their skills.
                                        <br /> <br />
                                        When cave diving in South East Sulawesi, follow all cave diving and safety protocols. It is strongly recommended that you dive with local cave divers and guides who know and understand the local cave systems.
                                        <br /> <br />
                                        For those who are fortunate enough to dive South East Sulawesi’s cave systems, an exciting adventure into Indonesia’s incredible geology awaits!

                                        </Typography> :
                                            <Typography variant="paragraph" style={{ fontStyle: "normal", fontWeight: "normal", fontSize: "18px", lineHeight: "21px" }}>
                                                Baubau di Sulawesi Selatan kini semakin dikenal sebagai lokasi utama bagi aktivitas cave diving di Indonesia. Ratusan gua telah ditemukan di wilayah ini, dan masih banyak lagi potensi gua yang dapat ditemukan dan dijelajahi. Baubau relatif mudah diakses dengan penerbangan langsung dari Makassar dan kapal feri dari beberapa pelabuhan di Indonesia. Baubau adalah kota terbesar di Pulau Buton.
                                        <br /> <br />
                                        Di pulau ini, banyak operator selam yang memahami dan menguasai jalur gua setempat, dan mereka dapat meberikan dukungan teknis penyelaman, tri-mix dan layanan CCR. Baubau terletak di atas hamparan batu kapur dan dibawah hamparan ini terbadapat lorong-lorong yang mempertemukan satu gua dengan gua lainnya.
                                        <br /> <br />
                                        Karakteristik gua-gua di wilayah ini pun beragam, ada yang cocok bagi bagi pemula dan lebih banyak lagi bagi penyelam yang berpengalaman. Untuk mengunjungi tempat yang cukup terpencil ini, Anda dapat menggunakan kapal feri dari Buton ke Pulau Muna, tempat dimana terdapat ratusan gua yang masih dalam proses eksplorasi dan pemetaan.
                                        <br /> <br />
                                        Lokasi Muna sangat terpencil dan tidak memiliki infrastruktur bagi turis. Namun, jika Anda memiliki jiwa petualang, Anda bisa saja melakukan berkemah sambil menikmati keindahan gua-gua di wilayah ini. Walaupun Sulawesi Tenggara sudah terlebih dahulu terkenal karena terumbu karangnya di wilayah Wakatobi, di wilayah yang sama ini juga Anda dapat menemukan beberapa gua terbaik untuk kegiatan cave diving. Fondasi batu kapur dari pulau-pulau vulkanik purba ini menaungi puluhan gua bawah tanah yang dangkal dan berair hangat.
                                        <br /> <br />
                                        Gua-gua yang terdapat di sekitar Wangiwangi dan Tomia memiliki kondisi yang cukup ramah dan mudah bagi penyelam pemula atau penyelam yang ingin melatih kembali ketrampilannya. Jika Anda melakukan aktivitas cave diving di Sulawesi Tenggara, diharapkan untuk selalu mentaati semua protokol keselamatan terkait cave diving.
                                        <br /> <br />
                                        Pendampingan oleh penyelam lokal yang menguasai dan memahami jalur gua setempat sangat disarankan. Bagi mereka yang beruntung, aktivitas cave diving di Sulawesi Tenggara dan penjelajahan jalur gua-guanya adalah suatu kesempatan petualangan geologi Indonesia yang luar biasa!
                                       </Typography>
                                    }
                                </Box>

                        }

                    </Box>
                </Box>

            </Box>
            <Footer />
        </>
    )
}
