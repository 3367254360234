import { Box, Button, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Paper, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import Pagination from '@material-ui/lab/Pagination';
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setGallery, setNews } from '../../../store/actions/userAction';
import ModalAlert from '../../../Components/ModalAlert'
import server from '../../../config/server';
import {showDay, showDate} from '../../../config/convertdate'

///component


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#E5E5E5",
        color: "#444444",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#fafafa",
        },
    },
}))(TableRow);


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: "4px"
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

/////data dummy


///styles

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        width: "96%",
        minHeight: "10vw",
        padding: "1.5rem",
        margin: '1rem'
    },
    btnPilihan: {
        textTransform: "none",
        backgroundColor: '#EEF7FF',
        boxShadow: 'none',
        paddingRight: "0rem",
        '&:hover': {
            paddingRight: "0rem",
            backgroundColor: '#28B0E6',
            color: 'white',
            boxShadow: 'none',
        },
    }
}))

export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    const {idLocation} = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = useState(1);
    const language = useSelector(state => state.data.language)
    const [openModalAlert, setOpenModalAlert] = useState(false)
    const data = useSelector(state => state.data.news)
    const [delData, setDelData] = useState ({id: '', loc:''})
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setNews())
        
    }, [anchorEl])

    function handleOpenModalAlert(e) {
        e.preventDefault()
        setOpenModalAlert(true)
    }

    function closeModal() {
        setOpenModalAlert(false)
        handleClose()
    }

    const handleClick = (event, id, loc) => {
        setDelData({id:id, loc:loc})
        setAnchorEl(event.currentTarget);
        
    };

    function edit () {
        history.push(`/admin/news/edit/${delData.id}`)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function add() {
        history.push(`/admin/news/add`)
    }

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    return (
        <>
        <div>
                <ModalAlert open={openModalAlert} location={delData.loc} id={delData.id} url={`${server}/news/delete`} handleClose={closeModal} />
            </div>
            <Box width="100%">
                <Box paddingX="1rem">
                    <Typography variant="h6">News</Typography>
                </Box>
                <Box className={classes.root}>
                    <Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={add}>
                    Add News
                    </Button>
                    <Box marginTop="1rem">
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">NO</StyledTableCell>
                                        <StyledTableCell align="left">JUDUL BERITA</StyledTableCell>
                                        <StyledTableCell align="left">KATEGORI</StyledTableCell>
                                        <StyledTableCell align="left">DIBUAT PADA</StyledTableCell>
                                        <StyledTableCell align="left">ACTION</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(data.slice((page - 1) * 10, (page - 1) * 10 + 10)
                                        ).map((item, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center" style={{ width: "2rem" }}>{((page - 1) * 10) + index + 1}</StyledTableCell>
                                            <StyledTableCell align="left" >{item.titleEng}</StyledTableCell>
                                            <StyledTableCell align="left" >{item.kategori}</StyledTableCell>
                                            <StyledTableCell align="left" >{showDay(new Date(item.createdAt))}, {showDate(new Date(item.createdAt))}</StyledTableCell>
                                            <StyledTableCell align="left" >
                                                <Button variant="outlined" className={classes.btnPilihan} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(e)=> handleClick(e,item.id, 'Foto Ini')}>{language == "ind" ? "Pilihan" : "Option"}<ArrowDropDownTwoToneIcon style={{ marginLeft: "1rem" }} /></Button>
                                                <StyledMenu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem>Preview</MenuItem>
                                                    <MenuItem onClick={edit}>Edit</MenuItem>
                                                    <MenuItem onClick={handleOpenModalAlert}>{language == "ind" ? "Hapus" : "Delete"}</MenuItem>
                                                </StyledMenu>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box display="flex" alignItems="center" justifyContent="center" marginTop="3rem">
                            <Pagination page={page} onChange={handleChangePage} count={Math.ceil(data.length / 10)} shape="rounded" color="primary" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

