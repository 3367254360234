import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link, useHistory } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core'

export default function ScrollDialog() {
    const [open, setOpen] = React.useState(false);
    const History = useHistory()

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button onClick={() => handleClickOpen()} style={History.location.pathname.includes('/hyperbaric-chambers')?{textTransform: "none", color: 'black', textDecoration: "underline", boxShadow: "none"}:{textTransform: "none", color: 'white', textDecoration: "underline", boxShadow: "none"}}>Disclaimer</Button>
            <Dialog
                style={{ maxHeight: "80%", marginTop: "5%" }}
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title" >Disclaimer</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <Box>
                            <Typography>
                                This microsite provides general information on the company's names in alphabetical order. The list provided on this content is not exhaustive and may be added or updated when they arise. Users are informed that information on other sites referred to or linked to has not been verified or analyzed by the Ministry of Tourism and Creative Economy of the Republic Indonesia. No express or implicit guarantee or declaration whatsoever is provided concerning the accuracy or completeness of this information as well as the quality of their products and/or services. This information is to be used as a reference only. A link to another website is solely provided for the convenience of the users and does not imply any endorsement nor approval from the Ministry of Tourism of Creative Economy of the Republic Indonesia on any products or services it describes. Access to and use of other websites linked to this microsite is at the user’s own risk and the Ministry of Tourism of Creative Economy of the Republic Indonesia accept no obligation or liability whatsoever in this regard.
                            </Typography>
                        </Box>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}