import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
import divingInd from '../../../Images/divingInd.pdf'
import diving from '../../../Images/diving.pdf'

const useStyles = makeStyles((theme) => ({
  boxFile: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#EEF7FF",
    borderRadius: "4px",
  },
  download: {
    textTransform: "none", width: "10rem", boxShadow: "none"
  },
  downloadNative: {
    textTransform: "none", width: "3rem", fontSize: "11px", boxShadow: "none", padding: "0rem"
  },
  filename: {
    fontWeight: "500",
    color: "#999999",
    textAlign: 'center'
  }
}))

export default function ScrollDialog() {
  const langClient = useSelector(state => state.data.langClient)
  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const matches = useMediaQuery('(min-width:600px)');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Button onClick={() => handleClickOpen()} color="primary" variant="contained" style={{ textTransform: "none", width: "10rem", boxShadow: "none" }}>Detail</Button>
      <Dialog
        style={{ maxHeight: "80%", marginTop: "5%" }}
        open={open}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">DIVING</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Box>
            <Grid container spacing={matches?3:1}>
                <Grid item xs={4} sm={4}>
                  <Box className={classes.boxFile} padding={matches?"2rem":"0.5rem"}>
                    <DescriptionIcon style={matches?{ fontSize: "4rem" }:{ fontSize: "2.5rem" }} />
                    <Box marginY={matches?"1rem":"0.5rem"}>
                      <Typography style={matches?{ fontSize: "14px" }:{ fontSize: "11px" }} className={classes.filename}> {langClient == 'EN'? 'Regulation of the Ministry of Tourism No. 07/2016' : 'Permenpar RI No. 07/2016'}</Typography>
                    </Box>
                    <a href={langClient =='EN'? diving:divingInd} target="_blank" style={{ textDecoration: 'none' }} download> 
                    <Button variant="contained" color="primary" className={matches?classes.download:classes.downloadNative}>Download</Button>
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {
              langClient == 'EN'?
              <Box marginTop="1rem">
            The government of Indonesia has seen the significant growth of dive tourism and its important contribution toward the overall Indonesian tourism. For this reason, the government has issued some regulations that are aimed at supporting dive tourism as well as regulating the quality, safety, and security of diving in Indonesia.
            <br/> <br/>
            Regulation of the Ministry of Tourism No. 07 /2016, on Guidelines for Recreational Diving Activities. This regulation stipulates that every recreational diving activity is carried out in accordance with the Guidelines for Recreational Diving Activities which is presented as an Annex in this regulation.

            </Box>:
            <Box marginTop="1rem">
            Pemerintah Indonesia menyadari  pertumbuhan wisata selam di Indonesia semakin meningkat dan berkontribusi secara signifikan terhadap dunia pariwisata Indonesia secara umum. Oleh karena itu, pemerintah telah mengeluarkan beberapa peraturan yang salah satunya adalah untuk mendukung keberlanjutan wisata selam, sekaligus meregulasi keamanan, keselamatan, dan kualitas wisata selam di Indonesia. 
            <br/> <br/>
            Melalui Peraturan Menteri Pariwisata No 07/2016, tentang Pedoman Penyelenggaraan Wisata Selam Rekreasi, pemerintah telah mengatur bahwa setiap kegiatan wisata selam rekreasi di Indonesia perlu dilaksanakan mengacu pada pedoman aktivitas wisata selam rekreasi yang disusun sebagai Lampiran dari Peraturan ini.
            </Box> 
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}