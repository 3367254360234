import React, { useEffect, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import PinDropIcon from '@material-ui/icons/PinDrop';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
import logo from '../../../Images/hyperbaricOK.png'

export default function BoxList(props) {
    const [hover, setHover] = useState(false)
    const matches = useMediaQuery('(min-width:600px)');
    const [url, setUrl] = useState(props.loc.website)

    useEffect(() => {
        let a = `${props.loc.website}`
        setUrl(a)
        console.log(a)
    }, [])
    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    return (
        <div>
            <Box position="relative">
                <Box position={matches && hover ? "absolute" : ""} zIndex={hover ? "20" : "0"} onPointerEnter={() => [setHover(true)]} onPointerLeave={() => [setHover(false)]} bgcolor="white" boxShadow="0px -1px 2px rgba(117, 117, 117, 0.1), 0px 1px 2px rgba(117, 117, 117, 0.1)" borderRadius="4px">
                    <Box height={matches ? "15vw" : "15rem"} style={{ backgroundImage: `url(${logo})`, backgroundSize: "cover", backgroundPosition: "center" }}>

                    </Box>
                    <Box width={matches ? "17.5vw" : "18rem"} textAlign="left" p="1rem" style={{ fontSize: "13px", lineHeight: "18px", fontWeight: "normal" }}>
                        <Typography noWrap={!hover} style={{ color: "black" }} variant="h6">{props.loc.name}</Typography>
                        <Box marginTop="0.5rem">
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <PinDropIcon style={{ fontSize: '13px' }} />
                                </Box>
                                <Typography style={{ color: "#666666", fontSize: '13px' }} noWrap={!hover}>{props.loc.address}</Typography>
                            </Box>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <CallIcon style={{ fontSize: '13px' }} />
                                </Box>
                                <Typography style={{ color: "#666666", fontSize: '13px' }} noWrap={!hover}>{props.loc.phone}</Typography>
                            </Box>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <LanguageIcon style={{ fontSize: '13px' }} />
                                </Box>
                                <Typography style={{ color: "#666666", fontSize: '13px' }} noWrap={!hover}>
                                    <a href={`http://${props.loc.website}`} style={{ fontSize: '13px', color: '#28B0E6', textDecoration: 'none' }} target="_blank" >{props.loc.website}</a>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}
