import { Box, Button, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Paper, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ModalAlert from '../../../../Components/ModalAlert'
import axios from 'axios'
import server from '../../../../config/server'
import swall from 'sweetalert2'

///component


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#E5E5E5",
        color: "#444444",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#fafafa",
        },
    },
}))(TableRow);


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: "4px"
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        width: "96%",
        minHeight: "10vw",
        padding: "1.5rem",
        margin: '1rem'
    },
    btnPilihan: {
        textTransform: "none",
        backgroundColor: '#EEF7FF',
        boxShadow: 'none',
        marginLeft: "1rem",
        paddingRight: "0rem",
        '&:hover': {
            paddingRight: "0rem",
            backgroundColor: '#28B0E6',
            color: 'white',
            boxShadow: 'none',
        },
    }
}))

export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = useState(1);
    const language = useSelector(state => state.data.language)
    const [openModalAlert, setOpenModalAlert] = useState(false)
    const {island} = useParams()
    const [locs, setLocs] = useState([])
    const [delData, setDelData] = useState ({id: '', loc:''})
    
    useEffect(() => {
        swall.showLoading()
        axios({
            url: `${server}/divecenter/name/island/${island}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                swall.close()
                setLocs(data.data)
               
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }, [anchorEl])

    function handleOpenModalAlert(e) {
        e.preventDefault()
        setOpenModalAlert(true)
    }

    function closeModal() {
        setOpenModalAlert(false)
        handleClose()
    }

    const handleClick = (event, id, loc) => {
        setDelData({id:id, loc:loc})
        setAnchorEl(event.currentTarget);
        
    };

    function edit () {
        history.push(`/admin/dive-center/${island}/edit/${delData.id}`)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function add() {
        history.push(`/admin/dive-center/${island}/add`)
    }

    return (
        <>
            <div>
                <ModalAlert open={openModalAlert} location={delData.loc} id={delData.id} url={`${server}/divecenter/delete`} handleClose={closeModal} />
            </div>
            <Box width="100%">
                <Box paddingX="1rem">
                    <Typography variant="h6">{language == "ind" ? "DAFTAR" : "LIST"} DIVE CENTER</Typography>
                </Box>
                <Box className={classes.root}>
                    <Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={add}>
                    {language == "ind" ? "Tambah" : "Add"} Dive Center
                    </Button>
                    <Box marginTop="1rem">
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">NO</StyledTableCell>
                                        <StyledTableCell align="left">DIVE CENTER NAME & AFILIASI</StyledTableCell>
                                        <StyledTableCell align="left">{language == "ind" ? "ALAMAT" : "ADDRESS"}</StyledTableCell>
                                        <StyledTableCell align="left">{language == "ind" ? "NO. TELEPON" : "PHONE"} & EMAIL</StyledTableCell>
                                        <StyledTableCell align="left">WEBSITE</StyledTableCell>
                                        <StyledTableCell align="center">{language == "ind" ? "AKSI" : "ACTION"}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(locs.slice((page - 1) * 10, (page - 1) * 10 + 10)
                                    ).map((loc, i) => (
                                        <StyledTableRow key={i}>
                                            <StyledTableCell align="center" style={{ width: "2rem" }}>{((page - 1) * 10) + i + 1}</StyledTableCell>
                                            <StyledTableCell align="left" ><Box><Typography>{loc.name}</Typography><Typography style={{color: "#A0A0A0"}}>{loc.afiliasi}</Typography> </Box></StyledTableCell>
                                            <StyledTableCell align="left">
                                                {loc.address}
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {loc.phone}<br />
                                                <Box style={{ color: "#A0A0A0" }}>{loc.email}</Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                {loc.website}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button variant="outlined" className={classes.btnPilihan} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(event)=>handleClick(event, loc.id, loc.nameEng)}>Option<ArrowDropDownTwoToneIcon style={{ marginLeft: "1rem" }} /></Button>
                                                <StyledMenu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem onClick={edit}>Edit</MenuItem>
                                                    <MenuItem onClick={(e) => handleOpenModalAlert(e)}>Delete</MenuItem>
                                                </StyledMenu>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
             
                            </Table>
                        </TableContainer>
                        <Box display="flex" alignItems="center" justifyContent="center" marginTop="3rem">
                            <Pagination page={page} onChange={handleChangePage} count={Math.ceil(locs.length / 10)} shape="rounded" color="primary" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

