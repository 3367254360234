import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import ModalSucces from '../../../../Components/ModalSucces'
import NavBar from '../../../../Components/NavBar'
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import server from '../../../../config/server';
import swall from 'sweetalert2'



const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                height: "200px",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: 20,
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))


export default function Index() {
    const classes = useStyles()
    const [flag, setFlag] = useState(false)
    const [fotoUtama, setFotoUtama] = useState([])
    const [photoSend, setPhotoSend] = useState(null) 
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const [input, setInput] = useState({ captionEng: "", captionInd: "", photographer: "" })
    const history = useHistory()
    
    function onChangeInput(event) {
        let { name, value } = event.target

        setInput({
            ...input,
            [name]: value
        })

    }

     useEffect(() => {
        if (!localStorage.access_token  ) {
            history.push('/admin/login')
        }
    }, [])

    function submit(e) {
        swall.showLoading()
        e.preventDefault()
        const temp = new FormData()
        temp.append('foto', photoSend)
        temp.set('captionEng', input.captionEng)
        temp.set('captionInd', input.captionInd)
        temp.set('photographer', input.photographer)
        Axios({
            url: `${server}/gallery/`,
            method: 'post',
            // data: {captionEng: input.captionEng, captionInd: input.captionInd, photographer: input.photographer, photo:null},
            data: temp,
            headers: {
              access_token: localStorage.access_token
            }
          })
            .then(data => {
                swall.close()
                setInput({ captionEng: "", captionInd: "", photographer: ""})
                setFotoUtama([])
                handleOpenModalSucces()

            })
            .catch(err => {
              console.log(err)
            })
       
    }


    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }

     function addFotoUtama(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoSend(fileObjs[0].file)
        setFotoUtama(temp)
        setFlag(!flag)
    }

    function deleteFotoUtama() {
        let temp = []
        setPhotoSend(null)
        setFotoUtama(temp)
        setFlag(!flag)
    }
    return (
        <>
            <div>
                <ModalSucces open={openModalSucces} to={`/admin/gallery`} open={openModalSucces} location="Gambar" title="Gallery" handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Typography variant="h5" style={{ fontWeight: "500" }}>Input Photo Gallery</Typography>
                <Box className={classes.input} marginTop="2rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>PHOTO GALLERY</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3} >
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                    onAdd={(fileObjs) => addFotoUtama(fileObjs)}
                                    showAlerts={false}
                                    dropzoneText=""
                                    dropzoneClass={classes.dropZone}
                                />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Click Here To Upload Foto</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box display="flex" marginTop="2rem">
                                    {fotoUtama.length > 0 ?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteFotoUtama}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={fotoUtama[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Only 1 Photo</Typography>
                            </Box>
                        </Box>

                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Caption (English)</Box>
                        <Box width={2 / 3}><TextField placeholder="Caption Photo" size="small" name="captionEng" value={input.captionEng} onChange={onChangeInput} fullWidth variant="outlined" />
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>20 words maximum</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Caption (Bahasa)</Box>
                        <Box width={2 / 3}><TextField placeholder="Caption Photo" size="small" name="captionInd" value={input.captionInd} onChange={onChangeInput} fullWidth variant="outlined" />
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>20 words maximum</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photographer</Box>
                        <Box width={2 / 3}><TextField placeholder="Photographer Name" size="small" name="photographer" value={input.photographer} onChange={onChangeInput} fullWidth variant="outlined" /></Box>
                    </Box>
                </Box>

                <Box className={classes.input} style={{ background: "#F9FDFE", borderTop: "0.5px solid #D3F0FC" }}>
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3} display="flex">
                            <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={submit}>Submit</Button></Box>
                            <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={(e)=>[history.push('/admin/gallery')]}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
