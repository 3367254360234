import React, { useState, useRef } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MUIRichTextEditor from 'mui-rte'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import ModalSucces from '../../../../Components/ModalSucces'
import NavBar from '../../../../Components/NavBar'
import axios from 'axios'
import server from '../../../../config/server'
import { useHistory } from 'react-router-dom';
import swall from 'sweetalert2'


const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                height: "200px",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: 20,
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))


export default function Index() {
    const classes = useStyles()
    const [flag, setFlag] = useState(false)
    const [fotoUtama, setFotoUtama] = useState([])
    const [photoSend, setPhotoSend] = useState(null) 
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const [input, setInput] = useState({ name: "", address: "", phone: "", email: "", website: "" })
    const history = useHistory()
    
    function onChangeInput(event) {
        let { name, value } = event.target

        setInput({
            ...input,
            [name]: value
        })

    }

    function submit() {
        swall.showLoading()
        const temp = new FormData()
        temp.append('foto', photoSend)
        temp.set('name', input.name)
        temp.set('address', input.address)
        temp.set('phone', input.phone)
        temp.set('email', input.email)
        temp.set('website', input.website)
        axios({
            url: `${server}/liveaboard/`,
            method: 'post',
            // data: {name: input.name, address:input.address, phone:input.phone, email:input.email, website:input.website, photo:null},
            data: temp,
            headers: {
              access_token: localStorage.access_token
            }
          })
            .then(data => {
                swall.close()
                handleOpenModalSucces()
                setInput({ name: "", address: "", phone: "", email: "", website: "" })
                setFotoUtama([])
    

            })
            .catch(err => {
              console.log(err)
            })
       
    }

    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }

    function addFotoUtama(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoSend(fileObjs[0].file)
        setFotoUtama(temp)
        setFlag(!flag)
    }

    function deleteFotoUtama() {
        let temp = []
        setPhotoSend(null)
        setFotoUtama(temp)
        setFlag(!flag)
    }

    return (
        <>
            <div>
                <ModalSucces to={`/admin/liveaboard`} open={openModalSucces} location={input.name} title="Liveaboard" handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Typography variant="h5" style={{ fontWeight: "500" }}>Input Liveaboard</Typography>
                <Box className={classes.input} marginTop="2rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>LIVEABOARD</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Liveaboard Name</Box>
                        <Box width={2 / 3}><TextField placeholder="Liveaboard Name" name="name" value={input.name} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Address</Box>
                        <Box width={2 / 3}><TextField placeholder="Address" size="small" name="address" value={input.address} onChange={onChangeInput} fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Phone</Box>
                        <Box width={2 / 3}><TextField placeholder="+62 85..." size="small" name="phone" value={input.phone} onChange={onChangeInput} fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Email</Box>
                        <Box width={2 / 3}><TextField placeholder="Email Address" size="small" name="email" value={input.email} onChange={onChangeInput} fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Website</Box>
                        <Box width={2 / 3}><TextField placeholder="Website" size="small" name="website" value={input.website} onChange={onChangeInput} fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3} >
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                    onAdd={(fileObjs) => addFotoUtama(fileObjs)}
                                    showAlerts={false}
                                    dropzoneText=""
                                    dropzoneClass={classes.dropZone}
                                />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Click Here To Upload Foto</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box display="flex" marginTop="2rem">
                                    {fotoUtama.length > 0 ?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteFotoUtama}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={fotoUtama[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Only 1 Photo</Typography>
                            </Box>
                        </Box>

                    </Box>

                </Box>

                <Box className={classes.input} style={{ background: "#F9FDFE", borderTop: "0.5px solid #D3F0FC" }}>
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3} display="flex">
                            <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={submit}>Submit</Button></Box>
                            <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={(e)=>[history.push('/admin/liveaboard')]}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
