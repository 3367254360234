import React, { useEffect, useState, } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MUIRichTextEditor from 'mui-rte'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import ModalSucces from '../../../../Components/ModalSucces'
import NavBar from '../../../../Components/NavBar'
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import server from '../../../../config/server';
import swall from 'sweetalert2'


const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                height: "200px",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: 20,
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))


export default function Index() {
    const classes = useStyles()
    const [flag, setFlag] = useState(false)
    const [fotoUtama, setFotoUtama] = useState(null)
    const [fotoSend, setFotoSend] = useState(null)
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const [input, setInput] = useState({ name: "", address: "", phone: "", website: "" })
    const history = useHistory()
    const {id} = useParams()
    const {location} = useParams()
    const [isDel, setIsDel] = useState(false)
    const [imgDel, setImgDel] = useState(null)

    useEffect(() => {
        swall.showLoading()
        Axios({
            url: `${server}/hyperbaric/detail/${id}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                swall.close()
                setInput({ name: data.data.name, address: data.data.address, phone: data.data.phone, website: data.data.website, photo: null })
                setFotoUtama(data.data.photo)
                // setImgDel(data.data.imgHyperbaric.id)
                

            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    function submit() {
        swall.showLoading()
        const temp = new FormData()
        temp.append('foto', fotoSend)
        if (isDel) {
            temp.set('imgDel', imgDel)
        }
        temp.set('name', input.name)
        temp.set('address', input.address)
        temp.set('phone', input.phone)
        temp.set('email', input.email)
        temp.set('website', input.website)
        temp.set('location', location)
        Axios({
            url: `${server}/hyperbaric/update/${id}`,
            method: 'put',
            // data: {name: input.name, address: input.address, phone: input.phone, website: input.website, photo: null},
            data: temp,
            headers: {
              access_token: localStorage.access_token
            }
          })
            .then(data => {
                swall.close()
              history.push(`/admin/hyperbaric-chambers/${location}`)
            })
            .catch(err => {
              console.log(err)
            })
    }

    function onChangeInput(event) {
        let { name, value } = event.target

        setInput({
            ...input,
            [name]: value
        })

    }

    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }

    function addFotoUtama(fileObjs) {
        deleteFotoUtama()

        setFotoUtama(URL.createObjectURL(fileObjs[0].file))
        setFotoSend(fileObjs[0].file)
        setFlag(!flag)
    }

    function deleteFotoUtama() {
        setIsDel(true)
        setFotoUtama(null)
        setFlag(!flag)
    }

     useEffect(() => {
        if (!localStorage.access_token  ) {
            history.push('/admin/login')
        }
    }, [])

    return (
        <>
            <div>
                <ModalSucces open={openModalSucces} location="Sumatra" handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Typography variant="h5" style={{ fontWeight: "500" }}>Edit Hyperbaric Chambers</Typography>
                <Box className={classes.input} marginTop="2rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>HYPERBARIC CHAMBERS</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Hyperbaric Chambers Name</Box>
                        <Box width={2 / 3}><TextField placeholder="Hyperbaric Chambers Name" name="name" value={input.name} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                        </Box>
                        <Box display="flex" marginTop="1rem">
                            <Box width={1 / 3} className={classes.label}>Address</Box>
                            <Box width={2 / 3}><TextField placeholder="Address" name="address" value={input.address} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                        </Box>
                        <Box display="flex" marginTop="1rem">
                            <Box width={1 / 3} className={classes.label}>Phone</Box>
                            <Box width={2 / 3}><TextField placeholder="Phone" name="phone" value={input.phone} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                        </Box>
                        <Box display="flex" marginTop="1rem">
                            <Box width={1 / 3} className={classes.label}>Website</Box>
                            <Box width={2 / 3}><TextField placeholder="Website" name="website" value={input.website} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                        </Box>
                        <Box display="flex" marginTop="1rem">
                            <Box width={1 / 3} className={classes.label}>Photo</Box>
                            <Box width={2 / 3} >
                                <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                    <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                        onAdd={(fileObjs) => addFotoUtama(fileObjs)}
                                        showAlerts={false}
                                        dropzoneText=""
                                        dropzoneClass={classes.dropZone}
                                    />
                                    </Box>
                                    <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                    <Box marginY="1rem">
                                        <Typography style={{ color: "#999999" }}>Click Here To Upload Foto</Typography>
                                    </Box>
                                    <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                        Upload Photo
                                </Button>
                                    <Box display="flex" marginTop="2rem">
                                        {fotoUtama?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteFotoUtama}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={fotoUtama} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                    </Box>
                                </Box>
                                <Box marginTop="0.5rem">
                                    <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Only 1 Photo</Typography>
                                </Box>
                            </Box>

                        </Box>

                    </Box>

                    <Box className={classes.input} style={{ background: "#F9FDFE", borderTop: "0.5px solid #D3F0FC" }}>
                        <Box display="flex">
                            <Box width={1 / 3} className={classes.label}></Box>
                            <Box width={2 / 3} display="flex">
                                <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={submit}>Submit</Button></Box>
                                <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={(e) =>[history.push(`/admin/hyperbaric-chambers/${location}`)]} color="primary">Cancel</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
        </>
    )
}
