import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';

import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import MUIRichTextEditor from 'mui-rte'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import NavBar from '../../../../Components/NavBar'
import { useSelector } from 'react-redux';
import ModalSucces from '../../../../Components/ModalSucces'
import server from '../../../../config/server'
import axios from 'axios'
import { useHistory } from 'react-router-dom';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw, EditorState } from 'draft-js';
import Swal from 'sweetalert2'

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                // backgroundColor: 'red',
                minHeight: "200px",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: '20px'
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))

export default function Index({ match }) {
    const classes = useStyles()
    const anchorRef = useRef(null);
    const history = useHistory()
    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = useState(true);
    const [flag, setFlag] = useState(false)
    const language = useSelector(state => state.data.language)
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const [nameEng, setNameEng] = useState("")
    const [descriptionEng, setDescriptionEng] = useState(null)
    const [desGeoEng, setDesGeoEng] = useState(null)

    const [nameInd, setNameInd] = useState("")
    const [descriptionInd, setDescriptionInd] = useState(null)
    const [desGeoInd, setDesGeoInd] = useState(null)

    const [photoTile, setPhotoTile] = useState([])
    const [photoTileSend, setPhotoTileSend] = useState('')
    const [photoGen, setPhotoGen] = useState([])
    const [photoGenSend, setPhotoGenSend] = useState('')
    const [photoGeog, setPhotoGeog] = useState([])
    const [photoGeogSend, setPhotoGeogSend] = useState('')
    const [done, setDone] = useState(false)

    // const [photoAllSend, setPhotoAllSend] = useState([photoTileSend, photoGenSend, photoGeogSend])


    const changeDesEng = (state: EditorState) => {
        if (done) {
            setDescriptionEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
        }
    }

    const changeDesInd = (state: EditorState) => {
        if (done) {
            setDescriptionInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
        }
    }

    const changeDesGeoEng = (state: EditorState) => {
        if (done) {
            setDesGeoEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
        }
    }

    const changeDesGeoInd = (state: EditorState) => {
        if (done) {
            setDesGeoInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
        }
    }

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }
    useEffect(() => {
        Swal.showLoading()
        axios({
            url: `${server}/divelocation/detail/${match.params.id}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {
                setNameEng(data.nameEng)
                setDescriptionEng(data.descriptionEng)
                setDesGeoEng(data.descriptionGeographyEng)
                setNameInd(data.nameInd)
                setDescriptionInd(data.descriptionInd)
                setDesGeoInd(data.descriptionGeographyInd)
                setPhotoTile([data.photoTile])
                setPhotoGen([data.photoGen])
                setPhotoGeog([data.photoGeog])
                setDone(true)
            })
            .catch(err => {

            })
    }, [])

    function submit() {
        Swal.showLoading()
        const temp = new FormData()
        let photoAllSend = [photoTileSend, photoGenSend, photoGeogSend]
        photoAllSend.map((item) => {
            temp.append('foto', item)
        })
        temp.set('nameInd', nameInd)
        temp.set('nameEng', nameEng)
        temp.set('descriptionInd', descriptionInd)
        temp.set('descriptionEng', descriptionEng)
        temp.set('descriptionGeographyInd', desGeoInd)
        temp.set('descriptionGeographyEng', desGeoEng)
        axios({
            url: `${server}/divelocation/update/${match.params.id}`,
            method: 'put',
            data: temp,
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                Swal.close()
                handleOpenModalSucces()
                
                console.log('sukses add')
            })
            .catch(err => {
                console.log(err)
            })
    }


    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }


    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopup(false);
    };

    const handleToggle = () => {
        setOpenPopup((prevOpen) => !prevOpen);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function addPhotoTile(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoTileSend(fileObjs[0].file)
        setPhotoTile(temp)
        setFlag(!flag)
    }

    function addPhotoGen(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoGenSend(fileObjs[0].file)
        setPhotoGen(temp)
        setFlag(!flag)
    }

    function addPhotoGeo(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoGeogSend(fileObjs[0].file)
        setPhotoGeog(temp)
        setFlag(!flag)
    }

    function deleteTile() {
        let temp = []
        setPhotoTileSend(null)
        setPhotoTile(temp)
        setFlag(!flag)
    }

    function deleteGen() {
        let temp = []
        setPhotoGenSend(null)
        setPhotoGen(temp)
        setFlag(!flag)
    }

    function deleteGeog() {
        let temp = []
        setPhotoGeogSend(null)
        setPhotoGeog(temp)
        setFlag(!flag)
    }

    return (
        <>
            <div>
                <ModalSucces to='/admin/dive-location' open={openModalSucces} location={nameEng} title="Dive Location" handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Box display="flex" width="70%">
                    <Typography variant="h5" style={{ fontWeight: "500" }}>Edit Location (English)</Typography>
                </Box>
                <Box className={classes.input} marginTop="2rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>GENERAL INFORMATION</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Location/Island</Box>
                        <Box width={2 / 3}><TextField placeholder="Location/Island" value={nameEng} onChange={(e) => [setNameEng(e.target.value)]} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Description</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="20vh">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor defaultValue={descriptionEng} onChange={changeDesEng} label="Description ..." controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.input} borderTop="1px solid #F3F3F3">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>GEOGRAPHY</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Description</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor defaultValue={desGeoEng} onChange={changeDesGeoEng} label="Description" controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" width="70%" marginTop="4rem">
                    <Typography variant="h5" style={{ fontWeight: "500" }}>Ubah Lokasi (Bahasa Indonesia)</Typography>
                </Box>
                <Box className={classes.input} marginTop="1rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>INFORMASI UMUM</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Lokasi/Pulau</Box>
                        <Box width={2 / 3}><TextField placeholder="Lokasi/Pulau" value={nameInd} onChange={(e) => [setNameInd(e.target.value)]} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Deskripsi</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="20vh">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Tulis Deskripsi Di Sini" defaultValue={descriptionInd} onChange={changeDesInd} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.input} borderTop="1px solid #F3F3F3">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>GEOGRAPHY</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Deskripsi</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor onChange={changeDesGeoInd} defaultValue={desGeoInd} label="Tulis Deskripsi Di Sini" controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>

                <Box display="flex" width="70%" marginTop="4rem">
                    <Typography variant="h5" style={{ fontWeight: "500" }}>Tambah Gambar</Typography>
                </Box>
                <Box className={classes.input} marginTop="1rem">

                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>Tile</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3}>
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}>
                                    <DropzoneAreaBase
                                        onAdd={(fileObjs) => addPhotoTile(fileObjs)}
                                        onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                                        onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                                        dropzoneText=""
                                        dropzoneClass={classes.dropZone}
                                    />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Upload Photo Here</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box paddingX="4rem" display="flex" flexWrap="wrap" justifyContent="center" marginTop="2rem">
                                {photoTile.length > 0 ?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteTile}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={photoTile[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Max 1 Photo</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" marginTop='3rem'>
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>INFORMASI UMUM</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3} >
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                    onAdd={(fileObjs) => addPhotoGen(fileObjs)}
                                    showAlerts={false}
                                    dropzoneText=""
                                    dropzoneClass={classes.dropZone}
                                />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Click Here to Upload Foto</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box display="flex" marginTop="1rem">
                                    {photoGen.length > 0 ?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteGen}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={photoGen[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Max 1 Photo</Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3}>
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}>
                                    <DropzoneAreaBase
                                        onAdd={(fileObjs) => addPhotoGeo(fileObjs)}
                                        onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                                        onAlert={(message, variant) => console.log(`${variant}: ${message}`)}
                                        dropzoneText=""
                                        dropzoneClass={classes.dropZone}
                                    />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Upload Photo Here</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box paddingX="4rem" display="flex" flexWrap="wrap" justifyContent="center" marginTop="2rem">
                                {photoGeog.length?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteGeog}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={photoGeog[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Max 1 Photo</Typography>
                            </Box>
                        </Box>
                    </Box>


                </Box>

                <Box className={classes.input} style={{ background: "#ffffff", borderTop: "0.5px solid #D3F0FC" }} marginBottom='5rem'>
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3} display="flex">
                            <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} onClick={submit} color="primary">Submit</Button></Box>
                            <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} onClick={(e) => [history.push('/admin/dive-location')]} color="primary">Cancel</Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </>
    )
}
