import { Typography, Box, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import Background from '../../../Images/DC_Tile01.jpg'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { setDiveLocation } from '../../../store/actions/userAction'
import Disclaimer from '../../../Components/disclaimer'
import Swal from 'sweetalert2'

export default function Index() {
    const history = useHistory()
    const matches = useMediaQuery('(min-width:600px)');
    const location = useSelector(state => state.data.diveLocations)
    const [diveLocation, setDive] = useState([])
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()
    const [flag, setflag] = useState(false)

    useEffect(() => {
        Swal.showLoading()
        if (diveLocation.length == 0) {
            dispatch(setDiveLocation())
        }
    }, [])

    useEffect(() => {
        let items = location
        items.sort(function (a, b) {
            if (langClient == "EN") {
                var nameA = a.nameEng.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameEng.toUpperCase(); // ignore upper and lowercase
            } else {
                var nameA = a.nameInd.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameInd.toUpperCase(); // ignore upper and lowercase
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        console.log(items)
        setDive(items)
        Swal.close()
        setflag(!flag)
    }, [location, langClient])

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box position="relative">
                <Box width="100%" marginTop={matches?'4vw':'-0.1rem'} height={!matches ? "221px" : "35vw"} paddingTop={!matches ? "70px" : "12vw"} style={{ textAlign: "center", color: "white", backgroundImage: `url(${Background})`, backgroundSize: "cover", backgroundPositionY: "-15vw", backgroundRepeat: 'no-repeat' }} display="flex" flexDirection="column" alignItems="center">
                    <Typography variant={matches ? "h3" : "h4"} style={matches ? { fontWeight: "900" } : { fontWeight: "700" }}>{langClient == 'EN' ? "Here at Your Service" : "Sahabat Menyelam Anda"}</Typography>
                    <Box width={matches ? "35rem" : '95%'} marginTop={matches ? '1rem' : '5px'}>
                        <Typography style={matches ? { fontSize: "1rem" } : { fontSize: "14px" }}>
                            {langClient == 'EN' ? 'In each of Indonesia’s main diving destinations you’ll find a selection of dive centers, dive resorts and a selection of dive services from certification courses, advanced courses, boats for day trips and equipment hire.' : 'Temui berbagai pilihan operator selam, penginapan dan nikmati keramahan layanan selam di setiap destinasi Anda.  Kursus bersertifikat bagi pemula atau kursus lanjutan dan penyewaan perahu dan peralatan selam pun tersedia.'}
                        </Typography>
                        <Box zIndex='300'>
                            <Disclaimer />
                        </Box>
                    </Box>
                </Box>

                <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginTop={matches ? "-5vw" : "-3rem"} marginBottom="7rem">
                    <Grid container spacing={3}>
                        {
                            diveLocation.map((loc, i) => {
                                return (
                                    <Grid key={i} item xs={12} sm={4}>
                                        <Box borderRadius="4px" style={{ background: 'white', cursor: "pointer" }} onClick={() => [history.push(`/dive-center/${loc.nameEng}`)]} padding={2}>

                                            <Box borderRadius="4px" style={loc.photoTile ? { backgroundImage: `url(${loc.photoTile})`, backgroundSize: "cover" } : { backgroundSize: "cover" }} display="flex" alignItems="flex-end" height={matches ? "17vw" : "190px"}>
                                                <Box borderRadius="4px" bgcolor="rgba(255, 255, 255, 0.65)" padding="0.5rem 1rem" minWidth="5rem" textAlign="center">
                                                    <Typography variant="h6">{langClient == "EN" ? loc.nameEng : loc.nameInd}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </>
    )
}
