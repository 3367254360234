import { Box, Button, Grid, makeStyles, Paper, TextField, Typography, Popper, Grow, ClickAwayListener, MenuList, MenuItem } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import AppBarClient from '../../Components/AppBarClient'
import Landing_1 from '../../Images/landing_1.jpg'
import Landing_2 from '../../Images/landing_2.jpg'
import Landing_3 from '../../Images/landing_3.jpg'
import Liveonboard from '../../Images/liveonboard-ic.png'
import Livingreef from '../../Images/livingreef-ic.png'
import Regulation from '../../Images/regulation-ic.png'
import Footer from '../../Components/Footer'
import Video from '../../Images/LP_DV01.mp4'
import DrawerMobile from '../../Components/Drawer'
import BoxHover from '../../Components/BoxHover'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux'
import { setDiveLocation, setNews, setVisitName } from '../../store/actions/userAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router-dom'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LocIcon from '../../Images/loc-icon.svg'
import { showDate, showDay, showDateInd, showDayInd } from '../../config/convertdate'

import fd1 from '../../Images/FD_01_kotak.png'
import fd2 from '../../Images/FD_02_kotak.png'
import fd3 from '../../Images/FD_03.jpg'
import kupang1 from '../../Images/CD_kupang1.jpg'
import kupang2 from '../../Images/CD_NTT.jpg'

import logo from '../../Images/dive-logo.png'

const useStyles = makeStyles((theme) => ({
    title: {
        background: "rgba(0, 0, 0, 0.2)",
        borderRadius: "4px"
    },
    boxTitle: {
        backgroundColor: "white",
        borderRadius: "0.5rem",
        marginTop: "1rem",
        width: "100%",
    },
    btnSearch: {
        textTransform: "none", fontSize: "1.25rem",
    },
    boxGrid: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: 'center',
    },
    boxtextFree: {
        display: "flex", alignItems: "flex-end", height: "100%", width: "100%",
        borderRadius: '4px',
        background: "linear-gradient(359.6deg, rgba(0, 0, 0, 0.55) 0.28%, rgba(255, 255, 255, 0) 99.59%)"
    },
    textTitle: {
        color: "white", fontWeight: "900",
        fontSize: "4.5vw",
    },
    textTitleNative: {
        color: "white", fontWeight: "900",
        fontSize: "30px",
    },
    menuItemArea: {
        fontSize: "10px",
        color: "#999999"
    },
    titleUtama: {
        color: '#1F1F90',
        fontSize: '28px',
        fontWeight: 500
    },
    titleUtamaMobile: {
        color: '#1F1F90',
        fontSize: '16px',
        fontWeight: 500
    },
    dateUtama: {
        color: '#444444',
        fontSize: '16px',
    },
    dateUtamaMobile: {
        color: '#444444',
        fontSize: '12px',
    },
    titleNews: {
        color: '#444444',
        fontSize: '16px',
        fontWeight: 500
    },
    titleNewsMobile: {
        color: '#444444',
        fontSize: '13px',
        fontWeight: 500
    },
    dateNews: {
        color: '#999999',
        fontSize: '12px',
    },
    dateNewsMobile: {
        color: '#999999',
        fontSize: '11px',
    }

}))
export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const classes = useStyles()
    const locs = useSelector(state => state.data.diveLocations)
    const [diveLocation, setDive] = useState([])
    const visit = useSelector(state => state.data.visitName)
    const langClient = useSelector(state => state.data.langClient)
    const [freeDiv, setFreeDiv] = useState([{ n: "", loc: "", img: kupang1 }, { n: "", loc: "", img: kupang2 }, { n: "", loc: "", img: fd1 }, { n: "", loc: "", img: fd2 }, { n: "", loc: "", img: fd3 }])
    const [openBoxHover, setOpenBoxHover] = useState([false, false, false])
    const [flag, setflag] = useState(true)
    const dispatch = useDispatch()
    const history = useHistory()
    const [value, setValue] = useState('')
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [options, setOptions] = useState(visit)
    const [location, setLocation] = useState("All Location")
    const [optLocs, setOptLocs] = useState([])
    const newsData = useSelector(state => state.data.news)
    const [newsBiasa, setNewsBiasa] = useState([])
    const [newsUtama, setNewsUtama] = useState([])
    const [toHistory, setToHistory] = useState(['/cavediving/kupang', '/cavediving/kupang', '/freediving', '/freediving', '/freediving'])

    useEffect(() => {
        if (location == 'All Location' && langClient != 'EN') {
            setLocation('Lokasi')
        }

        if (location == 'Lokasi' && langClient == 'EN') {
            setLocation('All Location')
        }

    }, [langClient])



    useEffect(() => {
        let items = locs
        items.sort(function (a, b) {
            if (langClient == "EN") {
                var nameA = a.nameEng.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameEng.toUpperCase(); // ignore upper and lowercase
            } else {
                var nameA = a.nameInd.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameInd.toUpperCase(); // ignore upper and lowercase
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        console.log(items)
        setDive(items)
        setflag(!flag)
    }, [locs, langClient])

    useEffect(() => {
        dispatch(setVisitName())
        dispatch(setDiveLocation())
        dispatch(setNews())
    }, [])

    useEffect(() => {
        if (newsData.length > 0) {
            let tempBiasa = newsData.filter((item) => (item.kategori) == 'biasa')
            let tempUtama = newsData.filter((item) => (item.kategori) == 'utama')

            setNewsBiasa(tempBiasa)
            setNewsUtama(tempUtama)
        }
    }, [newsData])

    useEffect(() => {
        setOptions(visit)
        let result = visit.reduce(function (r, a) {
            r[a.island] = r[a.island] || [];
            r[a.island].push(a);
            return r;
        }, Object.create(null));
        setOptLocs(result)

    }, [visit])


    useEffect(() => {
        let temp = []
        if (location == 'All Location' || location == 'Lokasi') {
            temp = visit
        } else {
            temp = visit.filter((item) => (item.island).toLowerCase() == location.toLowerCase())
        }
        setOptions(temp)
    }, [location])

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (value) {
            history.push(`/dive-location/${value.island}/${value.id}`)
        }

    }, [value])

    function handleHover(e, i, stat) {
        e.preventDefault()
        let temp = openBoxHover
        temp[i] = stat
        setOpenBoxHover(temp)
        setflag(!flag)
    }

    function toNews (id) {
        history.push(`/news/${id}`)
    }

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "5rem" : "0"} width="100vw" display="flex" flexDirection="column" alignItems="center"  >
                <Grid container spacing={matches ? 1 : 0}>
                    <Grid item xs={4}>
                        <Box style={{ backgroundImage: `url(${Landing_1})`, backgroundSize: "cover", backgroundPosition: "center", width: "100%" }} height={matches ? "650px" : "350px"}></Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box width={matches ? "100%" : "100%- 3px"} marginX={matches ? "0px" : "3px"} >
                            <Box style={matches ? { backgroundImage: `url(${Landing_3})`, backgroundSize: "cover", backgroundPosition: "center", backgroundPositionX: '-10rem', width: "100%" } : { backgroundImage: `url(${Landing_3})`, backgroundSize: "cover", backgroundPosition: "center", width: "100%", backgroundPositionX: '26rem' }} height={matches ? "650px" : "350px"}></Box>
                        </Box>
                    </Grid>
                    <Grid item xs={4}><Box style={{ backgroundImage: `url(${Landing_2})`, backgroundSize: "cover", backgroundPosition: "center", width: "100%" }} height={matches ? "650px" : "350px"}></Box>
                    </Grid>
                </Grid>
                <Box position="relative" width={matches ? "55%" : "90%"}>
                    <Box position="absolute" width='100%' top={matches ? "-25vw" : "-12rem"} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        {/* <Box className={classes.title} padding={matches ? "2rem 4rem" : "0.5rem"}>
                            <Typography className={matches ? classes.textTitle : classes.textTitleNative}>{langClient == 'EN' ? "DIVING IN" : "SELAM DI"} INDONESIA</Typography>
                        </Box> */}

                        <Box marginBottom='5vw'>
                            <img src={logo} style={matches ? { width: '10vw' } : { width: '5rem' }} />
                        </Box>

                        <Box className={classes.boxTitle}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={12} sm={8}>
                                    <Box padding="1rem" paddingBottom={matches ? "1rem" : "0rem"}>
                                        {/* <TextField id="outlined-basic" fullWidth label="Where do you want to dive?" variant="outlined" /> */}
                                        <Autocomplete
                                            fullWidth
                                            value={value}
                                            options={options}
                                            getOptionLabel={(option) => langClient == 'EN' ? option.nameEng : option.nameInd}
                                            onChange={(e, newValue) => { setValue(newValue) }}
                                            renderInput={(params) => <TextField {...params} fullWidth label={<><Box display="flex" alignItems="center">< img src={LocIcon} style={{ width: '1.2rem' }} />{langClient == "EN" ? "Where do you want to dive?" : "Di mana anda akan menyelam?"}</Box></>} variant="outlined" />}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Box padding="1rem" paddingTop={matches ? "1rem" : "0rem"}>
                                        <Button variant="contained" color="secondary" ref={anchorRef}
                                            aria-controls={open ? 'menu-list-grow' : undefined}
                                            aria-haspopup="true"
                                            onClick={handleToggle} fullWidth className={classes.btnSearch}>{location}<Box marginLeft='0.5rem' display="flex" justifyContent="center" alignItems="center"><ArrowDropDownIcon size="large" /></Box>
                                        </Button>
                                        <Popper placement="bottom" open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}

                                                    style={{ transformOrigin: placement === 'bottom' ? 'center bottom' : 'center bottom' }}
                                                >
                                                    <Paper fullWidth>
                                                        <ClickAwayListener onClickAway={handleClose}>
                                                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                                                <MenuItem onClick={(e) => { setLocation(langClient == "EN" ? "All Location" : "Lokasi") }}>{langClient == "EN" ? "All Location" : "Lokasi"}</MenuItem>
                                                                {
                                                                    diveLocation && diveLocation.map((loc, i) => {
                                                                        return (
                                                                            <MenuItem style={{ widt: "100%" }} onClick={(e) => { setLocation(loc.nameEng) }}>
                                                                                <div>
                                                                                    <Typography>{langClient == 'EN' ? loc.nameEng : loc.nameInd}</Typography>
                                                                                    <Typography className={classes.menuItemArea}> {loc.Visits.length} Area To Visit</Typography>
                                                                                </div>
                                                                            </MenuItem>)
                                                                    })
                                                                }
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} paddingTop="7rem" flexDirection="column" alignItems="center" style={{ background: "#ffffff", textAlign: "center" }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Box width="100%" borderRadius="4px">
                                <video src={Video} autoPlay muted loop style={{ width: "100%", borderRadius: "4px" }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box textAlign="left" >
                                <Typography variant="h5" style={matches ? { fontWeight: "500" } : { fontWeight: "700" }}>{langClient == 'EN' ? "Welcome to" : "Selamat Datang di"} Indonesia</Typography>
                                <Box marginTop="0.5rem" maxHeight={matches ? "21vw" : "300px"} style={{ overflowY: "scroll" }}>
                                    {langClient == 'EN' ?
                                        <Typography>With more than 17,000 islands, the Indonesian archipelago is the largest in the world. This phenomenal county lays at the very heart of the Coral Triangle – the most marine diverse region on the planet. Indonesia offers just about every type of diving known to man along with wide ranging dive site topographies, reefs formations, conditions and marine life – there are no two places in the archipelago which will deliver the same experiences, and there is no other country on earth which can rival Indonesia’s coral reefs and the abundance and diversity of marine life which inhabits them.
                                    <br /><br />
                                    Exploring Indonesia underwater is something which is accessible to everyone. From those learning to dive or snorkel for the first time to seasoned professionals and expert freedivers. Indonesian reefs are truly a display of Mother Nature’s finest hour and they have to be seen to be believed. We encourage anyone who has the time and means to visit this phenomenal archipelago to do so – with so many coral reefs around the world under threat from rising sea temperatures and climate change, Indonesian reefs remain thriving and productive.

                                    <br /><br />
                                    From some of the smallest and most unique species through to apex predators and the largest fish in the ocean – the whale shark, Indonesia has it all. Discover pristine reefs through to historical wrecks and bubbling submerged volcanoes. Underwater Indonesia is one of the richest tapestries on the planet – and it’s yours to explore.
                                    <br /><br />
                                    Welcome to our Wonderful Indonesia…
                                    </Typography>
                                        :
                                        <Typography>Indonesia adalah kepulauan terbesar di dunia, terdiri lebih dari 17.000 pulau. Negara yang menakjubkan ini terletak di jantung segitiga terumbu karang, kawasan dengan keanekaragaman hayati terbesar di bumi. Indonesia memiliki hampir semua jenis penyelaman berkat keragaman topografi lokasi selam, formasi karang, kondisi dan kehidupan laut. Tidak ada dua tempat di Indonesia yang memberikan pengalaman serupa dan tidak ada negara lain di dunia ini yang dapat menyaingi terumbu karang Indonesia serta kekayaan dan keragaman hayati laut yang menghuninya.
                                    <br /><br />
                                    Penjelajahan bawah laut Indonesia dapat diakses dan dinikmati oleh setiap penyelam dari berbagai tingkatan, mulai penyelam pemula ataupun mereka yang melakukan snorkeling untuk pertama kalinya, hingga penyelam profesional berpengalaman dan freediver yang handal.  Terumbu karang di Indonesia merupakan karya terbaik alam. Untuk membuktikannya, Anda harus mengunjunginya. Kami mengundang siapapun yang memiliki waktu dan sarana untuk mengunjungi negara kepulauan yang luar biasa ini. Sementara banyak terumbu karang di bagian dunia lainnya terancam akibat kenaikan suhu air laut dan perubahan iklim, terumbu karang di Indonesia mampu terus berkembang dan produktif.
                                    <br /><br />
                                    Alam bawah laut Indonesia sangatlah berlimpah, mulai dari spesies terkecil dan terunik hingga predator puncak dan ikan terbesar di lautan, hiu paus. Di sini juga Anda dapat menjumpa iterumbu karang yang masih asli, reruntuhan kapal sisa jejak sejarah dan gunung berapi bawah laut yang masih aktif. Alam bawah laut Indonesia merupakan salah satu lokasi dengan ekosistem laut terkaya di bumi dan kini giliran Anda untuk menjelajahinya.

                                    <br /><br />
                                    Selamat datang di Wonderful Indonesia...</Typography>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box marginY="136px" width="100%">
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.boxGrid} padding={matches ? '16px' : "16px 0px"} width={matches ? '80%' : "100%"}>
                                    <img src={Liveonboard} />
                                    <Box marginTop="1rem">
                                        <Typography variant="h6">LIVEABOARD</Typography>
                                        <Typography>
                                            {langClient == "EN" ? "Explore the archipelago onboard a traditional phinisi liveaboard and dive some of Indonesia’s most iconic sites." : "Jelajahi kepulauan dengan kapal tradisional phinisi dan jelajahi lokasi-lokasi selam paling ikonik di Indonesia."}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.boxGrid} padding={matches ? '16px' : "16px 0px"} width={matches ? '80%' : "100%"}>
                                    <img src={Livingreef} />
                                    <Box marginTop="1rem">
                                        <Typography variant="h6">{langClient == "EN" ? "LIVING REEFS" : "TERUMBU KARANG"}</Typography>
                                        <Typography>
                                            {langClient == "EN" ? "Located in the heart of the coral triangle, Indonesia is home to some of the most productive and diverse coral reefs on earth." : "Indonesia terletak di jantung segitiga terumbu karang dan merupakan rumah bagi sejumlah terumbu karang paling produktif dan beranekaragam di bumi."}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box className={classes.boxGrid} padding={matches ? '16px' : "16px 0px"} width={matches ? '80%' : "100%"}>
                                    <img src={Regulation} />
                                    <Box marginTop="1rem">
                                        <Typography variant="h6">{langClient == "EN" ? "REGULATIONS" : "REGULASI"}</Typography>
                                        <Typography>
                                            {langClient == "EN" ? "Be prepared.  Check out requirements and regulations that apply when diving and traveling across the Indonesian Archipelago." : "Bersiaplah. Periksa persyaratan dan peraturan yang berlaku untuk menyelam dan menjelajahi kepulauan Indonesia."}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box style={{ background: "#F6F9FE" }} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} paddingBottom="2rem">
                    <Box marginY="1rem">
                        <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == "EN" ? "DIVE LOCATION" : "LOKASI SELAM"}</Typography>
                    </Box>
                    {matches ?
                        <Grid container spacing={3} >
                            {
                                diveLocation && diveLocation.map((loc, i) => {
                                    return (
                                        <Grid key={i} item sm={4} marginBottom="1.5rem" onClick={() => [history.push(`/dive-location/${loc.id}`)]} >

                                            <Box height={matches ? "17vw" : "190px"} style={loc.photoTile ? { backgroundImage: `url(${loc.photoTile})`, backgroundSize: "cover", backgroundPosition: "center" } : { backgroundSize: "cover", backgroundPosition: "center" }}></Box>

                                            <Box marginTop="0.5rem">
                                                <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == "EN" ? loc.nameEng : loc.nameInd}</Typography>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid> :
                        <Box display="flex" alignItems="center" maxWidth="100%" overflow="scroll">
                            {
                                diveLocation && diveLocation.map((loc, i) => {
                                    return (
                                        <Box key={i} marginRight="1rem" onClick={() => [history.push(`/dive-location/${loc.id}`)]}>
                                            <img src={loc.photoTile} style={{ width: "320px", height: '220px' }} />
                                            <Box marginTop="0.3rem">
                                                <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == "EN" ? loc.nameEng : loc.nameInd}</Typography>
                                            </Box>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    }
                </Box>
                <Box style={{ background: "white" }} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} paddingTop="3rem" paddingBottom="3rem">
                    <Box marginY="1rem">
                        <Typography variant="h5" style={{ fontWeight: "500" }}>FREEDIVING & CAVE DIVING</Typography>
                    </Box>
                    {
                        matches ?
                            <Grid container spacing={3}>
                                <Grid item sm={8}>
                                    <Box position="relative" onPointerEnter={(e) => handleHover(e, 0, true)} onPointerLeave={(e) => handleHover(e, 0, false)} >
                                        <Box style={{ backgroundImage: `url(${freeDiv[0].img})`, backgroundSize: "cover", backgroundPosition: 'center' }} borderRadius='8px' height="20rem" marginBottom="1rem">
                                            <Box className={classes.boxtextFree}>
                                                <Box padding="2rem">
                                                    <Typography variant="h4" style={{ color: "white", fontWeight: "900" }}>{freeDiv[0].n}</Typography>
                                                    <Typography variant="h6" style={{ color: "white", fontWeight: "500" }}>{freeDiv[0].loc}</Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                        {openBoxHover[0] ? <BoxHover name={freeDiv[0].n} toHistory='/cavediving/kupang' location={freeDiv[0].loc} /> : null}
                                    </Box>
                                </Grid>
                                {
                                    freeDiv.map((loc, i) => {
                                        return (
                                            <>
                                                {
                                                    i > 0 ?
                                                        <Grid item sm={4}>
                                                            <Box position="relative" onPointerEnter={(e) => handleHover(e, i, true)} onPointerLeave={(e) => handleHover(e, i, false)}>
                                                                <Box key={i} style={{ backgroundImage: `url(${loc.img})`, backgroundSize: "cover", }} height="20rem" marginBottom="1rem" borderRadius='4px'>
                                                                    <Box className={classes.boxtextFree}>
                                                                        <Box padding="2rem">
                                                                            <Typography variant="h4" style={{ color: "white", fontWeight: "900" }}>{loc.n}</Typography>
                                                                            <Typography variant="h6" style={{ color: "white", fontWeight: "500" }}>{loc.loc}</Typography>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                {openBoxHover[i] ? <BoxHover name={loc.n} toHistory={toHistory[i]} location={loc.loc} /> : null}
                                                            </Box>
                                                        </Grid> :
                                                        null
                                                }
                                            </>

                                        )
                                    })
                                }
                            </Grid> :
                            <Box display="flex" alignItems="center" maxWidth="100%" overflow="scroll">
                                {
                                    freeDiv.map((loc, i) => {
                                        return (
                                            <Box key={i} marginRight="1rem">
                                                <Box position="relative" onClick={() => [history.push(`${toHistory[i]}`)]}>
                                                    <Box key={i} style={{ backgroundImage: `url(${loc.img})`, backgroundSize: "cover" }} height="220px" width="320px" marginBottom="1rem" borderRadius="4px">
                                                        <Box className={classes.boxtextFree}>
                                                            <Box padding="1rem">
                                                                <Typography variant="h4" style={{ color: "white", fontWeight: "900" }}>{loc.n}</Typography>
                                                                <Typography variant="h6" style={{ color: "white", fontWeight: "500" }}>{loc.loc}</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Box>

                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                    }

                </Box>
                <Box style={{ background: "white" }} width={matches ? "86%" : "100%"} paddingX={matches ? "7%" : '0'} paddingBottom="3rem">
                    <Box marginTop='3rem' padding='24px' style={{ background: '#EFF3F8' }} borderRadius='4px'>
                        <Box marginY="1rem">
                            <Typography variant="h5" style={{ fontWeight: "500" }}>NEWS</Typography>
                        </Box>
                        <Box>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    {
                                        newsUtama.length > 0 ?
                                            <Box style={{cursor:'pointer'}} onClick={()=>toNews(newsUtama[newsUtama.length - 1].id)}>
                                                <Box height={matches ? '28vw' : '240px'} borderRadius='4px' style={{ backgroundImage: `url(${newsUtama[newsUtama.length - 1].photo})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></Box>
                                                <Box>
                                                    <Typography className={matches ? classes.titleUtama : classes.titleUtamaMobile}>{langClient == "EN" ? newsUtama[newsUtama.length - 1].titleEng : newsUtama[newsUtama.length - 1].titleInd} </Typography>
                                                    <Typography className={matches ? classes.dateUtama : classes.dateUtamaMobile}>{showDay(new Date(newsUtama[newsUtama.length - 1].createdAt))}, {showDate(new Date(newsUtama[newsUtama.length - 1].createdAt))}</Typography>
                                                </Box>
                                            </Box> : null
                                    }

                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box paddingRight='10px' maxHeight={matches ? '37vw' : '400px'} style={{ overflowY: 'scroll' }}>
                                        <Grid container spacing={1}>
                                            {
                                                newsBiasa.map((item, i) => {
                                                    return (
                                                        <Grid key={i} item xs={6} sm={6}>
                                                            <Box style={{cursor:'pointer'}} onClick={() => toNews(item.id)}>
                                                                <Box borderRadius='4px' height={matches ? '14vw' : '120px'} style={{ backgroundImage: `url(${item.photo})`,  backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></Box>
                                                                <Box>
                                                                    <Typography className={matches ? classes.titleNews : classes.titleNewsMobile}>{langClient == "EN" ? item.titleEng : item.titleInd}</Typography>

                                                                    {
                                                                        langClient == 'EN' ?
                                                                            <Typography className={matches ? classes.dateNews : classes.dateNewsMobile}>
                                                                                {showDay(new Date(item.createdAt))}, {showDate(new Date(item.createdAt))}
                                                                            </Typography> :
                                                                            <Typography className={matches ? classes.dateNews : classes.dateNewsMobile}>
                                                                                {showDayInd(new Date(item.createdAt))}, {showDateInd(new Date(item.createdAt))}
                                                                            </Typography>
                                                                    }

                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
                <Footer />
            </Box>
        </>
    )
}
