import { Box, Button, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Paper, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import ModalAlert from '../../../Components/ModalAlert'
import server from '../../../config/server';
import { setDiveLocationName } from '../../../store/actions/userAction';

///component


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#E5E5E5",
        color: "#444444",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#fafafa",
        },
    },
}))(TableRow);


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: "4px"
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

/////data dummy


// const locs = [
//     "Sumatra", "Java", "Bali", "West Nusa Tenggara", "East Nusa Tenggara", "Borneo", "Sulawesi", "Moluccas", "Papua"
// ]

///styles

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        width: "96%",
        minHeight: "10vw",
        padding: "1.5rem",
        margin: '1rem'
    },
    btnPilihan: {
        textTransform: "none",
        backgroundColor: '#EEF7FF',
        boxShadow: 'none',
        marginLeft: "1rem",
        paddingRight: "0rem",
        '&:hover': {
            paddingRight: "0rem",
            backgroundColor: '#28B0E6',
            color: 'white',
            boxShadow: 'none',
        },
    }
}))

export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    const locs = useSelector(state => state.data.diveLocationsName)
    const load = useSelector(state => state.data.loadDiveLocationsName)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const language = useSelector(state => state.data.language)
    const [openModalAlert, setOpenModalAlert] = useState(false)
    const [delData, setDelData] = useState ({id: '', loc:''})
    const dispatch = useDispatch()

    useEffect(() => {
        if(load) {
            Swal.showLoading()
        }else{
            Swal.close()
        }
    }, [load])

    useEffect(() => {
        dispatch(setDiveLocationName())
    },[anchorEl])

    function handleOpenModalAlert(e) {
        e.preventDefault()
        setOpenModalAlert(true)
        
    }

    function closeModal() {
        setOpenModalAlert(false)
        handleClose()
    }

    const handleClick = (event, id, loc) => {
        setDelData({id:id, loc:loc})
        setAnchorEl(event.currentTarget);
        
    };

    function edit () {
        history.push(`/admin/dive-location/edit/${delData.id}`)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    function viewLocation(e, name) {
        e.preventDefault()
        history.push(`/admin/dive-location/area/${name}`)
    }

    function addLocation(e) {
        e.preventDefault()
        history.push(`/admin/dive-location/add`)
    }

    return (
        <>
            <div>
                <ModalAlert open={openModalAlert} location={delData.loc} id={delData.id} url={`${server}/divelocation/delete`} handleClose={closeModal} />
            </div>
            <Box width="100%">
                <Box paddingX="1rem">
                    <Typography variant="h6">{language == "ind" ? "LOKASI DIVE" : "DIVE LOCATION"}</Typography>
                </Box>
                <Box className={classes.root}>
                    <Button variant="contained" style={{ textTransform: "none", paddingRight: "2rem", paddingLeft: "2rem", boxShadow: "none" }} color="primary" onClick={addLocation}>
                        Add Location
                    </Button>
                    <Box marginTop="1rem" >
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">NO</StyledTableCell>
                                        <StyledTableCell align="left">{language == "ind" ? "LOKASI/PULAU" : "LOCATION/ISLAND"}</StyledTableCell>
                                        {/* <StyledTableCell align="left">GENERAL INFORMATION</StyledTableCell> */}
                                        <StyledTableCell align="left">{language == "ind" ? "AKSI" : "ACTION"}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locs && locs.map((loc, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center" style={{ width: "2rem" }}>{index + 1}</StyledTableCell>
                                            <StyledTableCell align="left" >{loc.nameEng}</StyledTableCell>
                                            <StyledTableCell align="left" style={{ width: "15rem" }}>
                                                <Button variant="contained" style={{ textTransform: "none", boxShadow: "none", }} color="primary" onClick={(e) => viewLocation(e, loc.nameEng)}>Area To Visit</Button>
                                                <Button variant="outlined" className={classes.btnPilihan} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={(event)=>handleClick(event, loc.id, loc.nameEng)}>{language == "ind" ? "Pilihan" : "Option"}<ArrowDropDownTwoToneIcon style={{ marginLeft: "1rem" }} /></Button>
                                                <StyledMenu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem onClick={edit}>Edit</MenuItem>
                                                    <MenuItem onClick={(e) =>handleOpenModalAlert(e)}>Delete</MenuItem>
                                                </StyledMenu>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

