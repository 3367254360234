import React, { useState, useRef, useEffect } from 'react'
import './styles.scss'
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Drawer, AppBar, Toolbar, CssBaseline, Popper, Grow, Paper, ClickAwayListener, Button, Box, Menu, MenuItem } from '@material-ui/core';
import MenuList from '@material-ui/core/MenuList';
import Logo from '../../Images/logo-ok.svg'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MyLocationRoundedIcon from '@material-ui/icons/MyLocationRounded';
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
import { Switch, Route, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../store/actions/userAction';

///icon-SVG
import GalleryAct from '../../Images/gallery_act.svg'
import GalleryNon from '../../Images/gallery_none.svg'
import BoatAct from '../../Images/boat-act.png'
import BoatNon from '../../Images/boat-non.png'
import MaskAct from '../../Images/mask-act.png'
import MaskNon from '../../Images/mask-none.png'
import HyperAct from '../../Images/hyper-act.png'
import HyperNon from '../../Images/hyper-non.png'
import NewsAct from '../../Images/news-act.svg'
import NewsNon from '../../Images/news-non.svg'


///pages
import DiveLocation from './DiveLocation'
import AreaVisit from './DiveLocation/AreaVisit'
import DiveOperator from './DiveOperator'
import ViewLocation from './DiveOperator/ViewLocation'
import LiveOnBoard from './LiveOnBoard'
import Freediving from './FreeDiving'
import ViewFreediving from './FreeDiving/View'
import Hyperbaric from './Hyperbaric'
import ViewHyperbaric from './Hyperbaric/View'
import Gallery from './Gallery'
import News from './News'


const drawerWidth = 253;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    appBar: {
                height: 62,
                backgroundColor: '#F2F3F9',
                borderBottom: '1px solid #E1E1E1',
                boxShadow: 'none',
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
            appBarShift: {
                marginLeft: drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            menuButton: {
                marginRight: 36,
            },
            hide: {
                display: 'none',
            },
            drawer: {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
            },
            drawerOpen: {
                borderRight: 'none',
                width: drawerWidth,
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
            toolbar: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                ...theme.mixins.toolbar,
            },
            content: {
                padding: theme.spacing(3),
                paddingTop: 15,
                flexGrow: 1,
                boxSizing: 'content-box',
                height: '100%',
                backgroundColor: '#F2F3F9'
            },
            popupTop: {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '10px',
                lineHeight: '12px',
                color: '#666666',
                height: 34
            },
            paperCustom: {
                padding: 0,
                marginTop: 5,
                boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
            },
            notifications: {
                color: '#999999',
                fontSize: 25
            },
            arrowProfile: {
                color: '#CCCCCC',
                fontSize: 25
            },
            popperProfile: {
                padding: 0
            },
            divider: {
                height: 0.5,
                backgroundColor: '#DDDDDD'
            },
            iconBtn: {
                marginRight: "1rem",
                fontSize: "1.25rem"
            },
            btnLessIcon: {
                color: '#E23F81'
            },
            iconImg: {
                width: "1.25rem",
                marginRight: "1rem"

            },
            btnLang: {
                borderRadius: "1rem", textTransform: "none", boxShadow: "none",
                fontSize: "0.75rem",
                padding: "0 1rem",
                marginRight: "0.5rem"
            }
        }));

const MenuButton = withStyles((theme) => ({
    root: {
        width: '100%',
        height: '50px',
        backgroundColor: 'none',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#666666",
        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#28B0E6',
            boxShadow: 'none',
        },
    },
}))(Button);

const MenuButtonActive = withStyles((theme) => ({
    root: {
        width: '100%',
        borderRight: "4px solid #28B0E6",
        height: '50px',
        backgroundColor: '#EEFAFF',
        borderRadius: '4px',
        boxShadow: 'none',
        textTransform: 'none',
        transition: '0.5s',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingLeft: '19px',
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "15px",
        lineHeight: "18px",
        color: "#28B0E6",
        '&:hover': {
            backgroundColor: '#EEFAFF',
            color: '#28B0E6',
            boxShadow: 'none',
        },
    },
}))(Button);

export default function Index() {
    const dispatch = useDispatch()
    const classes = useStyles();
    const History = useHistory();
    const anchorRef = useRef(null);
    const [openPopup, setOpenPopup] = useState(false);
    const [open, setOpen] = useState(true);
    const [title, setTitle] = useState('')
    const [anchorEl, setAnchorEl] = React.useState(null);
    const language = useSelector(state => state.data.language)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (localStorage.access_token) {
            // History.push('/admin/dive-location')
        } else{
            History.push('/admin/login')
        }
    }, [])

    const handleCloseLanguage = () => {
        setAnchorEl(null);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopup(false);
    };

    const handleToggle = () => {
        setOpenPopup((prevOpen) => !prevOpen);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function setLang(e, lang) {
        e.preventDefault()
        dispatch(setLanguage(lang))
    }

    function logout() {
        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        localStorage.clear()
        History.push('/admin/login')
    }
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar,
                    [classes.appBarShift],
                )}
            >
                <Toolbar>

                    <span className="dashboard-head-txt" >
                        {title}
                    </span>
                    <div className="container-appbar-right-side" >
                        {/* <Box marginRight="1rem" display="flex">
                            <Button variant={language == "ind"?"contained":"outlined"} color={language == "ind"? "primary":"none"} className={classes.btnLang} onClick={(e) => setLang(e, "ind")} >
                                Bahasa Indonesia
                            </Button>
                            <Button variant={language == "eng"?"contained":"outlined"} color={language == "eng"? "primary":"none"}className={classes.btnLang} onClick={(e) => setLang(e, "eng")} >
                                English
                            </Button>
                        </Box> */}
                        <div className="profile-navbar-btn"
                            ref={anchorRef}
                            aria-controls={open ? 'menu-list-grow' : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}
                        >
                            <Box borderRadius="50%" width="1.25rem" height="1.25rem" display="flex" justifyContent="center" alignItems="center" style={{ background: "#28B0E6", color: "white" }}>S</Box>
                            <span className="profile-txt-name" > Super Admin </span>
                            {
                                !openPopup ?
                                    <ExpandMoreIcon className={classes.arrowProfile} />
                                    :
                                    <ExpandLessIcon className={classes.arrowProfile} />
                            }
                            <Popper open={openPopup} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center top' }}
                                    >
                                        <Paper className={classes.paperCustom} >
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={openPopup} id="menu-list-grow" className={classes.popperProfile} onKeyDown={handleListKeyDown}>
                                                    <MenuItem onClick={logout} className="popup-list" >
                                                        <span className="text-menu-item-popup" > Keluar </span>
                                                        <i className="fa fa-sign-out unlock-custom" />
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer,
                    [classes.drawerOpen]
                )}
                classes={{
                    paper: clsx(
                        [classes.drawerOpen]
                    ),
                }}
            >
                <div className="container-img-sidebar" >
                    <img src={Logo} className="logo-sidebar" alt="logo" />
                </div>
                <div className="button-sidebar-wrapper">
                    {
                        History.location.pathname.includes('/admin/dive-location') ?
                            <MenuButtonActive onClick={e => History.push('/admin/dive-location')}>
                                <MyLocationRoundedIcon  className={classes.iconBtn} />
                                Dive Location
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/dive-location')} >
                                <MyLocationRoundedIcon className={classes.iconBtn} />
                                Dive Location
                            </MenuButton>
                    }
                    {
                        History.location.pathname.includes('/admin/dive-center') ?
                            <MenuButtonActive onClick={e => History.push('/admin/dive-center')}>
                                <StorefrontRoundedIcon className={classes.iconBtn} />
                                Dive Center
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/dive-center')} >
                                <StorefrontRoundedIcon className={classes.iconBtn} />
                                Dive Center
                            </MenuButton>
                    }
                    {
                        History.location.pathname.includes('/admin/liveaboard') ?
                            <MenuButtonActive onClick={e => History.push('/admin/liveaboard')}>
                                <img src={BoatAct} className={classes.iconImg} />
                                Liveaboard
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/liveaboard')} >
                                <img src={BoatNon} className={classes.iconImg} />
                                Liveaboard
                            </MenuButton>
                    }
                    {
                        History.location.pathname.includes('/admin/hyperbaric-chambers') ?
                            <MenuButtonActive onClick={e => History.push('/admin/hyperbaric-chambers')}>
                                <img src={HyperAct} className={classes.iconImg} />
                                Hyperbaric Chambers
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/hyperbaric-chambers')} >
                                <img src={HyperNon} className={classes.iconImg} />
                                Hyperbaric Chambers
                            </MenuButton>
                    }
                    {
                        History.location.pathname.includes('/admin/gallery') ?
                            <MenuButtonActive onClick={e => History.push('/admin/gallery')}>
                                <img src={GalleryAct} className={classes.iconImg} />
                                Photo Gallery
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/gallery')} >
                                <img src={GalleryNon} className={classes.iconImg} />
                                Photo Gallery
                            </MenuButton>
                    }
                    {
                        History.location.pathname.includes('/admin/news') ?
                            <MenuButtonActive onClick={e => History.push('/admin/news')}>
                                <img src={NewsAct} className={classes.iconImg} />
                                News
                            </MenuButtonActive>
                            :
                            <MenuButton onClick={e => History.push('/admin/news')} >
                                <img src={NewsNon} className={classes.iconImg} />
                                News
                            </MenuButton>
                    }
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route path="/admin/hyperbaric-chambers/:location" component={ViewHyperbaric} />
                    <Route path="/admin/hyperbaric-chambers" component={Hyperbaric} />
                    <Route path="/admin/freediving/:idLocation" component={ViewFreediving} />
                    <Route path="/admin/freediving/" component={Freediving} />
                    <Route path="/admin/liveaboard/" component={LiveOnBoard} />
                    <Route path="/admin/dive-location/area/:island" component={AreaVisit} />
                    <Route path="/admin/dive-location/" component={DiveLocation} />
                    <Route path="/admin/dive-center/:island" component={ViewLocation} />
                    <Route path="/admin/dive-center/" component={DiveOperator} />
                    <Route path="/admin/gallery/" component={Gallery} />
                    <Route path="/admin/news/" component={News} />
                </Switch>
            </main>
        </div>
    );
}