import { Box, Typography } from '@material-ui/core'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';

export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    return (
        <>
            <Box style={{ background: "#6D519D", color: "#EAEAEA" }} width="86%" padding="3rem 7%" textAlign={matches?"left":"center"}>
                <Typography style={!matches?{fontSize:"16px"}:{fontSize:"18px"}}>{langClient == "EN" ?"Ministry of Tourism and Creative Economy of the Republic of Indonesia": "Kementerian Pariwisata dan Ekonomi Kreatif Republik Indonesia"}</Typography>
                <Box marginTop='0.75rem' width={matches?"50vw": "100%"} ><Typography style={!matches?{fontSize:"12px"}:{fontSize:"14px"}}>{langClient == "EN" ?"This is the official website of the Ministry of Tourism and Creative Economy of the Republic of Indonesia. The contents listed on this website are intended for informational purposes rather than commercial. Any displayed sale is meant as a token of partnership and will always redirect you to our partners' sites.":"Situs ini merupakan situs resmi Kementerian Pariwisata dan Ekonomi Kreatif Republik Indonesia. Semua isi yang tercantum di dalam situs ini bertujuan untuk memberikan informasi dan bukan sebagai tujuan komersial. Penjualan yang ditampilkan merupakan tanda kemitraan yang akan menghubungkan Anda kepada Mitra Kami."}</Typography>
                </Box>
            </Box>
        </>
    )
}
