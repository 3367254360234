import { Box, Typography } from '@material-ui/core';
import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery';
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useSelector } from 'react-redux';
import lr1 from '../../../Images/LR_01.png'
import lr2 from '../../../Images/LR_02.png'
import lr1L from '../../../Images/LR_01_L.jpg'
import lr2L from '../../../Images/LR_02_L.jpg'
import lr3 from '../../../Images/LR_03.png'
import lr3L from '../../../Images/LR_03_L.png'
import lr4 from '../../../Images/LR_04.png'
import lr4L from '../../../Images/LR_04_L.png'

import './style.scss'

export default function Index() {

    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const [images, setImages] = useState([
        {
            original: lr1L,
            thumbnail: lr1,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr2L,
            thumbnail: lr2,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr3L,
            thumbnail: lr3,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr4L,
            thumbnail: lr4,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
    ])

    const [imagesMobile, setImagesMobile] = useState([
        {
            original: lr1,
            thumbnail: lr1,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr2,
            thumbnail: lr2,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr3,
            thumbnail: lr3,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
        {
            original: lr4,
            thumbnail: lr4,
            originalClass: 'originalClass',
            description: 'To sustain land on earth, we must protect life in the ocean. No blue, no green.'
        },
    ])

    ///IND

    const [imagesInd, setImagesInd] = useState([
        {
            original: lr1L,
            thumbnail: lr1,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr2L,
            thumbnail: lr2,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr3L,
            thumbnail: lr3,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr4L,
            thumbnail: lr4,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
    ])

    const [imagesMobileInd, setImagesMobileInd] = useState([
        {
            original: lr1,
            thumbnail: lr1,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr2,
            thumbnail: lr2,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr3,
            thumbnail: lr3,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
        {
            original: lr4,
            thumbnail: lr4,
            originalClass: 'originalClass',
            description: 'Untuk menjaga kelangsungan kehidupan di daratan, kita harus melindungi lautan. Tanpa biru, tak kan ada hijau.'
        },
    ])

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "5rem" : "0"}>
                {
                    langClient == 'EN' ?
                        <ImageGallery fullscreen={true} items={matches ? images : imagesMobile} showFullscreenButton={false} showPlayButton={false} /> :
                        <ImageGallery fullscreen={true} items={matches ? imagesInd : imagesMobileInd} showFullscreenButton={false} showPlayButton={false} />
                }
            </Box>

            <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginBottom="5rem" textAlign={matches ? "left" : "left"}>
                <Box display="flex" justifyContent="center">
                    <Box width={matches ? '1050px' : '100%'}>
                        <Box marginTop='2rem' style={matches ? { textAlign: "center" } : { textAlign: "left" }}>
                            {/* <Typography style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}>Living Reefs {langClient == 'EN' ? "in" : "di"} Indonesia</Typography> */}
                            <Typography className="titlePage" style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}> {langClient == 'EN' ? "The Epicenter of World's Coral Triangle" : "Jantung Segitiga Terumbu Karang Dunia"}</Typography>
                        </Box>
                        {
                            langClient == 'EN' ?
                                <Box marginTop='1rem'>
                                    <Typography style={{ fontSize: '1.1rem' }}>
                                        Indonesia is located at the heart of the coral triangle which is home to 76% of the world's coral species and over 2,200 species of reef fish.
                        <br /><br />
                        This region has the highest coral and marine biodiversity in the world and you will see it in evidence wherever you dive in Indonesia’s exceptional waters.
                        <br /><br />
                        These statistics do not just take care of themselves; Indonesia has been proactively ensuring the longevity of its coral reefs for decades. The earliest Indonesian Marine Protected Areas such as the Bunaken Marine Park and Komodo Marine Protected Area are as thriving today as when they first formed. The Government has rolled out its Marine protected areas to include Nusa Penida, Raja Ampat and other areas across the archipelago. When diving in an MPA there is often a tagging system in place which requires those entering the park to purchase a Marine Park pass. Prices vary according to the region and passes can be daily, per trip or per calendar year – check with your chosen operator for up to date prices.
                        <br /><br />
                        The funds raised through MPA tag initiatives are used to fund marine patrols to ensure that Marine Park regulations such as no fishing, no anchoring etc. are being adhered to, to support local communities and to fund cleanup and coral reef conservations projects.
                        <br /><br />
                        When diving in an Indonesian MPA be sure to familiarize yourself with the codes of conduct which apply. These may include (but are not limited to: not wearing gloves, not touching, chasing or harassing marine life, staying a certain distance away from certain species, regulations relating to contact with the reef and to using underwater strobes). Please consider that regulations are in place for the sole purpose of ensuring the health and longevity of these special areas for future generations to enjoy as you do today.
                        </Typography>
                                </Box> :
                                <Box marginTop='1rem'>
                                    <Typography style={{ fontSize: '1.1rem' }}>
                                        Indonesia terletak di jantung segitiga terumbu karang yang dihuni sekitar 76% spesies karang dunia yang telah teridentifikasi dan merupakan habitat bagi 2.200 spesies ikan karang.
                        <br /><br />
                        Wilayah ini memiliki keanekaragaman hayati karang dan laut terbesar di dunia. Keindahannya dapat disaksikan pada saat Anda menyelam di mana pun di perairan Indonesia yang luar biasa ini.
                        <br /><br />
                        Namun kita semua sadar, kelimpahan alam ini perlu dijaga dengan baik. Dalam kurun beberapa dekade terskhir, Indonesia secara pro-aktif telah melakukan berbagai upaya untuk menjaga kelestarian terumbu karangnya. Penetapan Bunaken dan Komodo sebagai Daerah Perlindungan Laut (DPL) di awal penyelenggaraan program, sudah menunjukkan hasil yang sangat baik dan berkembang pesat hingga saat ini. Selanjutnya, pemerintah Indonesia memasukkan Nusa Penida, Raja Ampat, dan beberapa daerah lain sebagai DPL baru. Untuk memasuki wilayah DPL, para pengunjung diwajibkan untuk membeli tiket masuk kawasan. Harganya bervariasi tergantung daerah masing-masing. Masa berlaku tiket pun beragam, seperti harian, per kunjungan maupun per tahun kalender. Informasi yang lengkap bisa didapatkan dari operator selam yang Anda pilih.
                        <br /><br />
                        Dana yang terkumpul dari tiket masuk kemudian digunakan untuk  membiayai patroli laut dalam penegakan peraturan, seperti larangan memancing dan larangan melempar sauh. Selain itu, dana yang terkumpul juga digunakan untuk untuk mendukung komunitas lokal, mendanai proyek pembersihan laut dan konservasi terumbu karang.
                        <br /><br />
                        Jika Anda ingin menyelam di DPL Indonesia, pastikan Anda memahami dan menjalankan kode etik yang berlaku, diantaranya (tapi tidak terbatas pada), tidak memakai sarung tangan, tidak menyentuh, mengejar atau mengganggu biota laut, menjaga jarak dengan spesies tertentu, dan juga memperhatikan aturan terkait kontak dengan karang serta penggunaan lampu bawah air. Perlu dipahami bersama, peraturan ini semata-mata bertujuan untuk menjamin kelestarian dan keberlangsungan hidup kawasan istimewa ini, sehingga generasi mendatang dapat menikmati pengalaman yang menakjubkan seperti yang Anda dapatkan saat ini.
                        </Typography>
                                </Box>
                        }

                    </Box>
                </Box>
            </Box>
            <Box marginTop='5rem'>
                <Footer />
            </Box>
        </>
    )
}
