import server from '../../config/server'
const axios = require('axios')

export function setLanguage(lang) {
    return {
        type: "CHANGELANGUAGE",
        payload: lang
    }
}

export function setLangClient(lang) {
    return {
        type: "CHANGELANGCLIENT",
        payload: lang
    }
}

export function setDiveLocation() {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_LOADDIVELOCATION",
            payload: true
        })
        axios({
            url: `${server}/divelocation`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {

                dispatch({
                    type: "SET_DIVELOCATION",
                    payload: data
                })
                dispatch({
                    type: "SET_LOADDIVELOCATION",
                    payload: false
                })
            })
            .catch(err => {
                console.log(err);
            })
    }
}

export function setDiveLocationName() {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_LOADDIVELOCATION_NAME",
            payload: true
        })
        axios({
            url: `${server}/divelocation/name`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {
                dispatch({
                    type: "SET_LOADDIVELOCATION_NAME",
                    payload: false
                })
                dispatch({
                    type: "SET_DIVELOCATION_NAME",
                    payload: data
                })
            })
            .catch(err => {
                console.log(err);
            })
    }
}


export function setVisit() {
    return (dispatch, getState) => {
        axios({
            url: `http://localhost:3000/visit`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                console.log(data, '===dat')
                dispatch({
                    type: "SET_VISIT",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}

export function setDiveCenter() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/divecenter`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                console.log(data, '===dat')
                dispatch({
                    type: "SET_DIVECENTER",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}


export function setLiveaboard() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/liveaboard/offset/0/1000`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                console.log(data, '===dat')
                dispatch({
                    type: "SET_LIVEABOARD",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}



export function setLiveaboardName() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/liveaboard/name`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                dispatch({
                    type: "SET_LIVEABOARDNAME",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}

export function setVisitName() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/visit/name`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                dispatch({
                    type: "SET_VISITNAME",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}



export function setHyperbaric() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/hyperbaric`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                console.log(data, '===dat')
                dispatch({
                    type: "SET_HYPERBARIC",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}


export function setGallery() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/gallery/offset/0/2000`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                console.log(data, '===dat')
                dispatch({
                    type: "SET_GALLERY",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}


export function setNews() {
    return (dispatch, getState) => {
        axios({
            url: `${server}/news`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                dispatch({
                    type: "SET_NEWS",
                    payload: data.data
                })
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }
}