import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider, FormControl, Select, MenuItem, InputLabel, Input, Checkbox, ListItemText } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MUIRichTextEditor from 'mui-rte'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import ModalSucces from '../../../../Components/ModalSucces'
import NavBar from '../../../../Components/NavBar'
import server from '../../../../config/server'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import swall from 'sweetalert2'

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                height: "200px",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: 20,
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))

const optionAfi = ['PADI', 'SSI', 'DIWA', 'PSS', 'NAUI', 'RAID', 'ADS', 'ADCI', 'AIDA']

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const options = { sum: ['Weh Island', 'Riau Islands', 'Bangka Belitung', 'North Sumatra'], sul: ['North Sulawesi', 'Central Sulawesi', 'South Sulawesi', 'South East Sulawesi'] }

export default function Index() {
    const classes = useStyles()
    const [flag, setFlag] = useState(false)
    const [fotoUtama, setFotoUtama] = useState([])
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const [input, setInput] = useState({ name: "", location: "", afiliasi: [], address: "", phone: "", email: "", website: "" })
    const [afiliasi, setAfliliasi] = useState('')
    const history = useHistory()
    const { island } = useParams()
    const [photoSend, setPhotoSend] = useState(null) 

    useEffect(() => {
        let temp = input.afiliasi.join(', ')
        setAfliliasi(temp)
    }, [input.afiliasi])

    function onChangeInput(event) {
        let { name, value } = event.target

        setInput({
            ...input,
            [name]: value
        })

    }

    function submit() {
        swall.showLoading()
        const temp = new FormData()
        temp.append('foto', photoSend)
        temp.set('islandEng', island)
        temp.set('islandInd', island)
        temp.set('location', input.location)
        temp.set('name', input.name)
        temp.set('afiliasi', afiliasi)
        temp.set('address', input.address)
        temp.set('phone', input.phone)
        temp.set('email', input.email)
        temp.set('website', input.website)
        axios({
            url: `${server}/divecenter`,
            method: 'post',
            // data: { islandEng: island, islandInd: island, location: input.location, name: input.name, afiliasi: afiliasi, address: input.address, phone: input.phone, email: input.email, website: input.website, photo: null },
            data: temp,
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                swall.close()
                handleOpenModalSucces()
                setInput({ name: "", location: "", afiliasi: [], address: "", phone: "", email: "", website: "" })
                setFotoUtama([])
     
            })
            .catch(err => {
                console.log(err)
            })
    }

    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }

    function addFotoUtama(fileObjs) {
        let temp = []
        temp.push(URL.createObjectURL(fileObjs[0].file))
        setPhotoSend(fileObjs[0].file)
        setFotoUtama(temp)
        setFlag(!flag)
    }

    function deleteFotoUtama() {
        let temp = []
        setPhotoSend(null)
        setFotoUtama(temp)
        setFlag(!flag)
    }

     useEffect(() => {
        if (!localStorage.access_token  ) {
            history.push('/admin/login')
        }
    }, [])

    return (
        <>
            <div>
                <ModalSucces to={`/admin/dive-center/${island}`} open={openModalSucces} location={input.name} title="Dive Center" handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Typography variant="h5" style={{ fontWeight: "500" }}>Input Dive Center</Typography>
                <Box className={classes.input} marginTop="2rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>DIVE CENTER</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Dive Center Name</Box>
                        <Box width={2 / 3}><TextField placeholder="Dive Center Name" name="name" value={input.name} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    { island == 'Sumatra' || island == 'Sulawesi'?
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>location</Box>
                        <Box width={2 / 3}>
                            <FormControl variant="outlined" fullWidth size="small">
                            <InputLabel>Location</InputLabel>
                                <Select
                                    name="location"
                                    label="location"
                                    value={input.location}
                                    onChange={onChangeInput}
                                >
                                    {
                                        (island == 'Sumatra'?options.sum:options.sul).map((item, i) => { return <MenuItem key={i} value={item}>{item}</MenuItem> })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Box> :null
                    }
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Afiliasi</Box>
                        <Box width={2 / 3}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel>Afiliasi</InputLabel>
                                <Select
                                    name='afiliasi'
                                    multiple
                                    value={input.afiliasi}
                                    onChange={onChangeInput}
                                    label="afiliasi"
                                    renderValue={(selected) => selected.join(', ')}
                                    MenuProps={MenuProps}
                                >
                                    {optionAfi.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            <Checkbox color="primary" checked={input.afiliasi.indexOf(name) > -1} />
                                            <ListItemText primary={name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Address</Box>
                        <Box width={2 / 3}><TextField placeholder="Address" name="address" value={input.address} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Phone</Box>
                        <Box width={2 / 3}><TextField placeholder="+62 85..." name="phone" value={input.phone} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Email</Box>
                        <Box width={2 / 3}><TextField placeholder="Email Address" name="email" value={input.email} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Website</Box>
                        <Box width={2 / 3}><TextField placeholder="Website" name="website" value={input.website} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Photo</Box>
                        <Box width={2 / 3} >
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                    onAdd={(fileObjs) => addFotoUtama(fileObjs)}
                                    showAlerts={false}
                                    dropzoneText=""
                                    dropzoneClass={classes.dropZone}
                                />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>Click Here To Upload Foto</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload Photo
                                </Button>
                                <Box display="flex" marginTop="2rem">
                                    {fotoUtama.length > 0 ?
                                        <Box width="8rem" height="8rem" marginBottom="1rem" position="relative">
                                            <Box className={classes.boxDelete}><IconButton onClick={deleteFotoUtama}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                            <img src={fotoUtama[0]} alt="images" className="image-upload-content"
                                                style={{
                                                    width: "100%",
                                                    borderRadius: "4px"
                                                }} />
                                        </Box> : null
                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>Only 1 Photo</Typography>
                            </Box>
                        </Box>

                    </Box>

                </Box>

                <Box className={classes.input} style={{ background: "#F9FDFE", borderTop: "0.5px solid #D3F0FC" }}>
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3} display="flex">
                            <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={submit}>Submit</Button></Box>
                            <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} onClick={(e) => [history.push(`/admin/dive-center/${island}`)]} color="primary">Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
