import { Typography, Box, Grid, Button, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useDispatch, useSelector } from 'react-redux'
import { setDiveLocationName, setHyperbaric } from '../../../store/actions/userAction'
import BoxList from './BoxList'
import Axios from 'axios'
import server from '../../../config/server'
import Swall from 'sweetalert2'
import Disclaimer from '../../../Components/disclaimer'


export default function Index() {
    const [hyperbaric, setHyperbaricData] = useState([])
    const [dataLocs, setData] = useState(hyperbaric)
    const locations = useSelector(state => state.data.diveLocationsName)
    const [location, setLocation] = useState('')
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()
    const [loadLocs, setLoadLocs] = useState(1)
    const [load, setLoad] = useState(1)

    useEffect(() => {
        Swall.showLoading()
        Axios({
            url: `${server}/hyperbaric/offset/0/8`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                Swall.close()
                setHyperbaricData(data.data)
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }, [])

    function loadmore() {
        let offset = 8 * load
        let oldData = hyperbaric
        let loadCount = load
        let offsetLocs = 8 * loadLocs
        let oldDataLocs = dataLocs
        let loadCountLocs = loadLocs

        Swall.showLoading()
        if (location) {
            Axios({
                url: `${server}/hyperbaric/location/${location}/${offsetLocs}/8`,
                method: 'get',
                headers: {
                    access_token: localStorage.access_token
                }
            })
                .then(data => {
                    let newData = oldDataLocs.concat(data.data)
                    setData(newData)
                    loadCountLocs++
                    setLoadLocs(loadCountLocs)
                    Swall.close()
                })
                .catch(err => {
                    console.log(err, '=====errro')
                })
        } else {
            Axios({
                url: `${server}/hyperbaric/offset/${offset}/8`,
                method: 'get',
                headers: {
                    access_token: localStorage.access_token
                }
            })
                .then(data => {
                    let newData = oldData.concat(data.data)
                    setHyperbaricData(newData)
                    loadCount++
                    setLoad(loadCount)
                    Swall.close()
                })
                .catch(err => {
                    console.log(err, '=====errro')
                })
        }

    }

    useEffect(() => {
        if (locations.length == 0) {
            dispatch(setDiveLocationName())
        }
    }, [])

    function changeLocation(e) {
        setLocation(e.target.value)

    }

    useEffect(() => {
        Swall.showLoading()
        Axios({
            url: `${server}/hyperbaric/location/${location}/0/8`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                Swall.close()
                setData(data.data)
                setLoadLocs(1)
            })
            .catch(err => {
                console.log(err, '=====errro')
            })

    }, [location])

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "10rem" : "0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" textAlign="center" marginBottom="5rem">
                <Box marginTop="3rem" display="flex" flexDirection="column" alignItems="center">
                    <Typography className="titlePage" style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}>{langClient == 'EN' ? "Hyperbaric Chambers in" : "Fasilitas Hiperbarik di"} Indonesia</Typography>
                    <Box width={matches ? '55%' : '100%'} marginTop='1rem'>
                        <Typography style={!matches ? { fontSize: "14px" } : {}}>
                            {
                                langClient == 'EN' ?
                                'Indonesia covers a vast geographical expanse and hyperbaric chambers, for handling dive related emergencies, can be found in almost all diving regions.':
                                'Fasilitas hiperbarik banyak tersedia di Indonesia.  Dalam keadaan dimana penyelam membutuhkan bantuan, fasilitas ini dapat digunakan di hampir semua wilayah penyelaman di Indonesia.'
                            }
                        </Typography>
                    </Box>
                    <Disclaimer />
                </Box>
                <Box marginTop="5rem">
                    <Box display="flex" marginBottom="1rem">
                        <Box width={matches ? "15rem" : "12rem"} marginRight="1rem">
                            <FormControl variant="outlined" size="small" fullWidth>
                                <InputLabel><Box display="flex" alignItems="center"><LocationOnIcon style={{ fontSize: "1.1rem" }} />{langClient == 'EN' ? "Select Location" : "Pilih Lokasi"}</Box></InputLabel>
                                <Select
                                    label={<><Box display="flex" alignItems="center"><LocationOnIcon style={{ fontSize: "1.1rem" }} />{langClient == 'EN' ? "Select Location" : "Pilih Lokasi"}</Box></>}
                                    value={location}
                                    onChange={changeLocation}
                                >
                                    <MenuItem value=""><Box width="100%" textAlign="center" style={{ color: "#28B0E6" }}>Reset Filter</Box></MenuItem>
                                    {
                                        locations && locations.map((loc, i) => {
                                            return (<MenuItem key={i} value={loc.nameEng}>{loc.nameEng}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box display='flex' alignItems="center">
                            {langClient == 'EN' ?
                                <Typography>{location ? `Location in ${location}` : "All Location"}</Typography> :
                                <Typography>{location ? `Lokasi di ${location}` : "Semua Lokasi"}</Typography>
                            }

                        </Box>
                    </Box>
                    <Grid container spacing={3}>
                        {
                            (location ? dataLocs : hyperbaric).map((loc, i) => {
                                return (
                                    <Grid key={i} item xs={12} sm={3}>
                                        <Box display="flex" justifyContent={matches ? "" : "center"}>
                                            <BoxList loc={loc} />
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <Box marginTop='100px'>
                        <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={loadmore}>Load More</Button>
                    </Box>
                </Box>
            </Box>
            <Box marginTop='146px'>
                <Footer />
            </Box>
        </>
    )
}
