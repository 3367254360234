import React, { useState, useRef, useEffect } from 'react';
import { Box, makeStyles, Typography, TextField, IconButton, Button, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import MUIRichTextEditor from 'mui-rte'
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Upload from '../../../../Images/upload.png'
import ModalAlert from '../../../../Components/ModalAlert'
import ModalSucces from '../../../../Components/ModalSucces'
import NavBar from '../../../../Components/NavBar'
import { useSelector } from 'react-redux';
import server from '../../../../config/server'
import axios from 'axios'
import { useHistory, useParams } from 'react-router-dom';
import swall from 'sweetalert2'
import { convertToRaw, EditorState } from 'draft-js';


const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                padding: "20px",
                minHeight: "7rem",
            },
            toolbar: {
                borderBottom: "0.5px solid #EDEDED"
            },
            placeHolder: {
                padding: 20,
            },
        },

    }
})

const useStyles = makeStyles((theme) => ({
    appBar: {
        borderBottom: "0.5px solid #DDDDDD",
        height: "62px",
        display: "flex",
        justifyContent: "flex-end",
        paddingRight: "36px",
        alignItems: "center"
    },
    input: {
        background: "white",
        padding: "1rem 5%",
        width: "60%"
    },
    uploadFoto: {
        display: 'none',
    },
    label: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.5rem",
        marginRight: "2rem"
    },
    boxDelete: {
        background: "white",
        position: "absolute",
        width: "1.8rem",
        height: "1.8rem",
        top: "-1rem",
        left: "7rem",
        display: "flex",
        justifyContent: "center",
        borderRadius: "50%",
        zIndex: "120"
    },
}))


export default function Index() {
    const classes = useStyles()
    const { locationId } = useParams()
    const [flag, setFlag] = useState(false)
    const [photos, setPhotos] = useState([])
    const [photosSend, setPhotosSend] = useState([])
    const [openModalSucces, setOpenModalSucces] = useState(false)
    const language = useSelector(state => state.data.language)
    const [input, setInput] = useState({ nameInd: "", nameEng: "", cityInd: "", cityEng: "", photo: null })
    const history = useHistory()
    const { island } = useParams()
    const [descriptionEng, setDescriptionEng] = useState(null)
    const [descriptionInd, setDescriptionInd] = useState(null)
    const [bySeaEng, setBySeaEng] = useState(null)
    const [bySeaInd, setBySeaInd] = useState(null)
    const [byLandEng, setByLandEng] = useState(null)
    const [byLandInd, setByLandInd] = useState(null)
    const [byAirEng, setByAirEng] = useState(null)
    const [byAirInd, setByAirInd] = useState(null)

    const changeDesEng = (state: EditorState) => {
        setDescriptionEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeDesInd = (state: EditorState) => {
        setDescriptionInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeSeaEng = (state: EditorState) => {
        setBySeaEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeSeaInd = (state: EditorState) => {
        setBySeaInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeLandEng = (state: EditorState) => {
        setByLandEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeLandInd = (state: EditorState) => {
        setByLandInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeAirEng = (state: EditorState) => {
        setByAirEng(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    const changeAirInd = (state: EditorState) => {
        setByAirInd(JSON.stringify(convertToRaw(state.getCurrentContent())))
    }

    function onChangeInput(event) {
        let { name, value } = event.target


        setInput({
            ...input,
            [name]: value
        })

    }

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    function addFiles(fileObjs) {
        let temp = photos
        let send = []
        fileObjs.map((item) => {
            temp.push(URL.createObjectURL(item.file))
            send.push(item.file)
        })
        setPhotosSend(send)
        setPhotos(temp)
        setFlag(!flag)
    }


    function deletePhoto(index) {
        let temp = photos
        let send = photosSend

        temp.splice(index, 1)
        send.splice(index, 1)
        setPhotosSend(send)
        setPhotos(temp)
        setFlag(!flag)
    }

    function submit() {
        swall.showLoading()
        const temp = new FormData()
        photosSend.map((item) => {
            temp.append('foto', item)
        })
        temp.set('island', island)
        temp.set('nameInd', input.nameInd)
        temp.set('nameEng', input.nameEng)
        temp.set('cityInd', input.cityInd)
        temp.set('cityEng', input.cityEng)
        temp.set('descriptionInd', descriptionInd)
        temp.set('descriptionEng', descriptionEng)
        temp.set('landInd', byLandInd)
        temp.set('landEng', byLandEng)
        temp.set('seaInd', bySeaInd)
        temp.set('seaEng', bySeaEng)
        temp.set('airInd', byAirInd)
        temp.set('airEng', byAirEng)
        axios({
            url: `${server}/visit/`,
            method: 'post',
            // data: {island: island, nameInd: input.nameInd, nameEng: input.nameEng, cityInd: input.cityInd, cityEng: input.cityEng, descriptionInd: descriptionInd, descriptionEng: descriptionEng, photo: null, landInd: byLandInd, landEng: byLandEng, seaInd: bySeaInd, seaEng: bySeaEng, airInd: byAirInd, airEng: byAirEng},
            data: temp,
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                swall.close()
                handleOpenModalSucces()
                setInput({ nameInd: "", nameEng: "", cityInd: "", cityEng: "", descriptionInd: "", descriptionEng: "", photo: null, landInd: "", landEng: "", seaInd: "", seaEng: "", airInd: "", airEng: "" })
                console.log('sukses add')
            })
            .catch(err => {
                console.log(err)
            })
    }

    function handleOpenModalSucces() {
        setOpenModalSucces(true)
    }

    function closeModal() {
        setOpenModalSucces(false)
    }
    console.log(editorState, 'lllllllllllllllllll')

    useEffect(() => {
        if (!localStorage.access_token) {
            history.push('/admin/login')
        }
    }, [])
    return (
        <>
            <div>
                <ModalSucces to={`/admin/dive-location/area/${island}`} open={openModalSucces} title="Area To Visit" location={input.nameEng} handleClose={closeModal} />
            </div>
            <NavBar />
            <Box display="flex" flexDirection="column" alignItems="center" marginTop="1rem">
                <Box display="flex" width="70%">
                    <Typography variant="h5" style={{ fontWeight: "500" }}>Input Area To Visit (English)</Typography>
                </Box>
                <Box className={classes.input} marginTop="1rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>AREA TO VISIT</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>{language == "ind" ? "Nama Area" : "Area Name"}</Box>
                        <Box width={2 / 3}><TextField placeholder="Area Name" name="nameEng" value={input.nameEng} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>City</Box>
                        <Box width={2 / 3}><TextField placeholder="City" name="cityEng" value={input.cityEng} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>{language == "ind" ? "Deskripsi" : "Description"}</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="20vh">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Description ..." onChange={changeDesEng} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>{language == "ind" ? "Foto" : "Photo"}</Box>
                        <Box width={2 / 3} >
                            <Box display="flex" position="relative" flexDirection="column" justifyContent="center" alignItems="center" border="1px solid #DDDDDD" minHeight="30vh">
                                <Box position="absolute" width="100%" height="100%" zIndex="99" style={{ opacity: "0" }}><DropzoneAreaBase
                                    onAdd={(fileObjs) => addFiles(fileObjs)}
                                    onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                                    showAlerts={false}
                                    dropzoneText=""
                                    dropzoneClass={classes.dropZone}
                                />
                                </Box>
                                <Box marginTop="1rem"><img src={Upload} style={{ width: "5rem" }} /></Box>
                                <Box marginY="1rem">
                                    <Typography style={{ color: "#999999" }}>{language == "ind" ? "Upload Foto Disini" : "Upload Photo Here"}</Typography>
                                </Box>
                                <Button variant="outlined" style={{ textTransform: "none", padding: "0 2rem" }} color="primary" component="span">
                                    Upload {language == "ind" ? "Foto" : "Photo"}
                                </Button>
                                <Box paddingX="4rem" display="flex" flexWrap="wrap" justifyContent="center" marginTop="2rem">
                                    {
                                        photos.map((photo, index) => {
                                            return (
                                                <Box key={index} width="8rem" height="8rem" marginRight="1rem" marginBottom="2rem" position="relative">
                                                    <Box className={classes.boxDelete}><IconButton onClick={() => deletePhoto(index)}><CancelSharpIcon style={{ fontSize: "2rem", color: "#E23F81", cursor: "pointer" }} /></IconButton></Box>
                                                    <img src={photo} alt="images" className="image-upload-content"
                                                        style={{
                                                            width: "100%",
                                                            borderRadius: "4px"
                                                        }} />
                                                </Box>
                                            )
                                        })

                                    }
                                </Box>
                            </Box>
                            <Box marginTop="0.5rem">
                                <Typography style={{ fontSize: "0.75rem", color: "#666666" }}>{language == "ind" ? "Maksimal 6 Foto" : "Max 6 Photos"}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.input} borderTop="1px solid #F3F3F3">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>HOW TO GET THERE</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>By Land</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="By Land" onChange={changeLandEng} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>By Sea</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="By Sea" onChange={changeSeaEng} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>By Air</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="By Air" onChange={changeAirEng} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>
                <Box width="70%" marginTop="4rem">
                    <Typography variant="h5" style={{ fontWeight: "500" }}>Input Area To Visit (Bahasa Indonesia)</Typography>
                </Box>
                <Box className={classes.input} marginTop="1rem">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>AREA TO VISIT</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Nama Area</Box>
                        <Box width={2 / 3}><TextField placeholder="Tulis Nama Area" name="nameInd" value={input.nameInd} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Kota/Kabupaten</Box>
                        <Box width={2 / 3}><TextField placeholder="Tulis Nama Kota/Kabupaten" name="cityInd" value={input.cityInd} onChange={onChangeInput} size="small" fullWidth variant="outlined" /></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Deskripsi</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="20vh">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Tulis Deskripsi Di Sini" onChange={changeDesInd} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>

                </Box>
                <Box className={classes.input} borderTop="1px solid #F3F3F3">
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3}><Typography color="primary" varian="h6" style={{ fontWeight: "500" }}>HOW TO GET THERE</Typography></Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Akses Jalur Darat</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Tuliskan Langkah Menuju Area Disini" onChange={changeLandInd} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Akses Jalur Laut</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Tuliskan Langkah Menuju Area Disini" onChange={changeSeaInd} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                    <Box display="flex" marginTop="1rem">
                        <Box width={1 / 3} className={classes.label}>Akses Jalur Udara</Box>
                        <Box width={2 / 3} border="1px solid #CECECE" borderRadius="0.25rem" minHeight="8rem">
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor label="Tuliskan Langkah Menuju Area Disini" onChange={changeAirInd} controls={["title", "bold", "italic", "underline", "undo", "redo", "numberList", "bulletList", "strikethrough",]} toolbarButtonSize="small" />
                            </MuiThemeProvider>
                        </Box>
                    </Box>
                </Box>
                <Box className={classes.input} style={{ background: "#F9FDFE", borderTop: "0.5px solid #D3F0FC" }}>
                    <Box display="flex">
                        <Box width={1 / 3} className={classes.label}></Box>
                        <Box width={2 / 3} display="flex">
                            <Box marginRight="1rem"><Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={submit}>Submit</Button></Box>
                            <Button variant="outlined" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={(e) => [history.push(`/admin/dive-location/area/${island}`)]}>Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
