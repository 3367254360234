import { Box, Button, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Paper, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import Pagination from '@material-ui/lab/Pagination';
import React, {useState} from 'react'
import { useHistory, useParams } from 'react-router-dom';

///component


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#E5E5E5",
        color: "#444444",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#fafafa",
        },
    },
}))(TableRow);


const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        borderRadius: "4px"
    }
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

/////data dummy


const locs = [
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },
    { nama: "Lumba Lumba Diving Center", alamat: "Sabang Gapang Beach, Weh Island, Aceh", telepon: "085485858885", email: "lumbalumba@gmail.com", website: "www.lumbalumba.com" },

]

///styles

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        width: "96%",
        minHeight: "10vw",
        padding: "1.5rem",
        margin: '1rem'
    },
    btnPilihan: {
        textTransform: "none",
        backgroundColor: '#EEF7FF',
        boxShadow: 'none',
        marginLeft: "1rem",
        paddingRight: "0rem",
        '&:hover': {
            paddingRight: "0rem",
            backgroundColor: '#28B0E6',
            color: 'white',
            boxShadow: 'none',
        },
    }
}))

export default function Index() {
    const classes = useStyles()
    const history = useHistory()
    const {idLocation} = useParams()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [page, setPage] = useState(1);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function add() {
        history.push (`/admin/freediving/${idLocation}/add`)
    }

    return (
        <>
            <Box width="100%">
                <Box paddingX="1rem">
                    <Typography variant="h6">Daftar Freediving</Typography>
                </Box>
                <Box className={classes.root}>
                    <Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={add}>
                        Tambah Freediving
                    </Button>
                    <Box marginTop="1rem">
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">NO</StyledTableCell>
                                        <StyledTableCell align="left">NAMA FREEDIVING</StyledTableCell>
                                        <StyledTableCell align="left">AREA</StyledTableCell>
                                        <StyledTableCell align="center">AKSI</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(locs.slice((page - 1) * 10, (page - 1) * 10 + 10)
                                        ).map((loc, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center" style={{ width: "2rem" }}>{((page - 1) * 10) + index + 1}</StyledTableCell>
                                            <StyledTableCell align="left" >Pulau Weh</StyledTableCell>
                                            <StyledTableCell align="left">
                                                Sumatra
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button variant="outlined" className={classes.btnPilihan} color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>Pilihan<ArrowDropDownTwoToneIcon style={{ marginLeft: "1rem" }} /></Button>
                                                <StyledMenu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleClose}
                                                >
                                                    <MenuItem >Edit</MenuItem>
                                                    <MenuItem >Hapus</MenuItem>
                                                </StyledMenu>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                        <Box display="flex" alignItems="center" justifyContent="center" marginTop="3rem">
                            <Pagination page={page} onChange={handleChangePage} count={Math.ceil(locs.length / 10)} shape="rounded" color="primary" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

