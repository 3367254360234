import { Box, Typography, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../../Components/AppBarClient'
import Footer from '../../../../Components/Footer'
import Background from '../../../../Images/divloc.png'
import BoxHover from '../../../../Components/BoxHover'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../../Components/Drawer'
import Axios from 'axios'
import server from '../../../../config/server'
import { useHistory, useParams } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {

            },
            editor: {
                color: "#444444"
            }
        }
    }
})


const options = { sum: ['sum1', 'sum2'], sul: ['sul1', 'sul2'] }

export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const [location, setLocation] = useState(null)
    const [areas, setAreas] = useState([])
    const [openBoxHover, setOpenBoxHover] = useState([false, false, false])
    const [flag, setflag] = useState(true)
    const { island } = useParams()
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()
    const history = useHistory()

    function handleHover(e, i, stat) {
        e.preventDefault()
        let temp = openBoxHover
        temp[i] = stat
        setOpenBoxHover(temp)
        setflag(!flag)
    }

    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/divelocation/detail/${island}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {
                setLocation(data)
                Swal.close()
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    useEffect(() => {
        if (location) {
            Swal.showLoading()
            Axios({
                url: `${server}/visit/island/${location.nameEng}`,
                method: 'get',
                headers: {
                    access_token: localStorage.access_token
                }
            })
                .then(({ data }) => {
                    Swal.close()
                    setAreas(data)

                    // console.log(data, 'daaaaaaaaaaaa')
                })
                .catch(err => {
                    console.log(err);
                })

        }
    }, [location])



    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }

    console.log(location)
    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "5rem" : "0"}>
                {
                    location &&
                    <Box width="100%" style={{ textAlign: "center", color: "white", backgroundImage: `url(${location.photoTile})`, backgroundSize: "cover", backgroundRepeat: 'no-repeat' }} height={!matches ? "321px" : "48vw"} display="flex" justifyContent="center" alignItems="center">
                        {/* {location &&
                    <Box width="100%" height={matches ? "30vw" : '321px'} style={location.ImageDiveLocationSingles.length != 0 ? { backgroundImage: `url(data:${location.ImageDiveLocationSingles[0].type};base64,${toBase64(location.ImageDiveLocationSingles[0].data.data)})`, backgroundSize: "cover", backgroundPosition: "center" } : { backgroundColor: "white" }} display="flex" justifyContent="center" alignItems="center"> */}
                        {
                            location && <Typography variant="h3">{langClient == "EN" ? location.nameEng : location.nameInd}</Typography>
                        }
                        {/* </Box>
                } */}

                    </Box>
                }
                {
                    location &&
                    <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginY="2rem">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                {/* {location ? <Box borderRadius="4px" style={location.ImageDiveLocationSingles.length != 0 ? { backgroundImage: `url(data:${location.ImageDiveLocationSingles[0].type};base64,${toBase64(location.ImageDiveLocationSingles[0].data.data)})`, backgroundSize: "cover" } : { backgroundSize: "cover" }} display="flex" alignItems="flex-end" height={matches ? "24vw" : "190px"}></Box> : null} */}
                                {location ? <Box borderRadius="4px" style={location.photoGen ? { backgroundImage: `url(${location.photoGen})`, backgroundSize: "cover" } : { backgroundSize: "cover" }} display="flex" alignItems="flex-end" height={matches ? "24vw" : "190px"}></Box> : null}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == 'EN'? 'General Information': 'Informasi Umum'} </Typography>
                                <Box overflow="scroll" maxHeight={matches ? "22vw" : "300px"}>
                                    <MuiThemeProvider theme={defaultTheme}>
                                        <MUIRichTextEditor
                                            defaultValue={langClient == "EN" ? location.descriptionEng : location.descriptionInd}
                                            readOnly={true}
                                            toolbar={false}
                                        />
                                    </MuiThemeProvider>
                                </Box>
                            </Grid>

                        </Grid>
                        <Box marginY="2rem">
                            <Grid container spacing={3} direction={matches ? "row" : "column-reverse"}>
                                <Grid item xs={12} sm={6}>
                                    {matches ? <Box marginBottom="1rem"><Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == 'EN'?'Geography':'Geografi'}</Typography></Box> : null}
                                    <Box overflow="scroll" maxHeight={matches ? "22vw" : "300px"} marginTop="1rem">
                                        <MuiThemeProvider theme={defaultTheme}>
                                            <MUIRichTextEditor
                                                defaultValue={langClient == "EN" ? location.descriptionGeographyEng : location.descriptionGeographyInd}
                                                readOnly={true}
                                                toolbar={false}
                                            />
                                        </MuiThemeProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {!matches ? <Box marginBottom="1rem"><Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == 'EN'?'Geography':'Geografi'}</Typography></Box> : null}
                                    <Box>
                                        {/* {location ? <Box borderRadius="4px" style={location.ImagesDiveLocationMultis.length != 0 ? { backgroundImage: `url(data:${location.ImagesDiveLocationMultis[0].type};base64,${toBase64(location.ImagesDiveLocationMultis[0].data.data)})`, backgroundSize: "cover" } : { backgroundSize: "cover" }} display="flex" alignItems="flex-end" height={matches ? "24vw" : "190px"}></Box> : null} */}
                                        {location ? <Box borderRadius="4px" style={location.photoGeography ? { backgroundImage: `url(${location.photoGeography})`, backgroundSize: "cover" } : { backgroundSize: "cover" }} display="flex" alignItems="flex-end" height={matches ? "24vw" : "190px"}></Box> : null}

                                    </Box>
                                </Grid>


                            </Grid>

                        </Box>
                        <Box marginY="2rem">
                            <Box marginBottom="1rem">
                                <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == 'EN'? 'Area To Visit' : 'Area untuk Dikunjungi'}</Typography>
                            </Box>
                            {
                                matches ?
                                    <Grid container spacing={3}>
                                        {
                                            areas.length != 0 ? areas.map((area, i) => {
                                                return (

                                                    <Grid item sm={4}>
                                                        <Box position="relative" onPointerEnter={(e) => handleHover(e, i, true)} onPointerLeave={(e) => handleHover(e, i, false)}>
                                                            <Box position="relative" key={i} style={{ backgroundImage: `url(${area.photo})`, backgroundSize: "cover", backgroundPosition: "center" }} height="18rem" marginBottom="1rem">
                                                                <Box style={{
                                                                    display: "flex", alignItems: "flex-end", height: "100%", width: "100%",
                                                                    background: "linear-gradient(359.6deg, rgba(0, 0, 0, 0.55) 0.28%, rgba(255, 255, 255, 0) 99.59%)"
                                                                }}>
                                                                    <Box padding="1rem">
                                                                        <Typography style={{ color: "white", fontWeight: "900", fontSize: "28px" }}>{langClient == "EN" ? area.nameEng : area.nameInd}</Typography>
                                                                        <Typography style={{ color: "white", fontWeight: "500", fontSize: "18px" }}>{langClient == "EN" ? area.cityEng : area.cityInd}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            {openBoxHover[i] ? <BoxHover name={langClient == "EN" ? area.nameEng : area.nameInd} location={langClient == "EN" ? area.cityEng : area.cityInd} toHistory={`/dive-location/${area.island}/${area.id}`} open={openBoxHover} /> : null}
                                                        </Box>
                                                    </Grid>
                                                )
                                            }) : null
                                        }
                                    </Grid> :
                                    <Box display="flex" alignItems="center" maxWidth="100%" overflow="scroll">
                                        {
                                            areas.map((area, i) => {
                                                return (
                                                    <Box key={i} marginRight="1rem" >
                                                        <Box position="relative" onClick={() => [history.push(`/dive-location/${area.island}/${area.id}`)]}>
                                                            <Box position="relative" key={i} style={{ backgroundImage: `url(${area.photo})`, backgroundSize: "cover", backgroundPosition: "center" }} height="220px" marginBottom="1rem">
                                                                <Box style={{
                                                                    display: "flex", alignItems: "flex-end", height: "100%", width: "320px",
                                                                    background: "linear-gradient(359.6deg, rgba(0, 0, 0, 0.55) 0.28%, rgba(255, 255, 255, 0) 99.59%)"
                                                                }}>
                                                                    <Box padding="1rem">
                                                                        <Typography style={{ color: "white", fontWeight: "900", fontSize: "20px" }}>{langClient == "EN" ? area.nameEng : area.nameInd}</Typography>
                                                                        <Typography style={{ color: "white", fontWeight: "500", fontSize: "15px" }}>{langClient == "EN" ? area.cityEng : area.cityInd}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                           
                                                        </Box>
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Box>
                            }
                        </Box>
                    </Box>
                }
            </Box>
            <Box marginTop='10rem'>
                <Footer />
            </Box>
        </>
    )
}
