import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import { useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from '../../Images/logo-ok.svg'

import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setLangClient } from '../../store/actions/userAction';
import { useHistory, Link } from 'react-router-dom'

const useStyles = makeStyles({
  list: {
    width: 200,
  },
  linkAct: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "#28B0E6",
    textDecoration: "none",
  },
  linkNon: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: "black",
    textDecoration: "none",
  },
  fullList: {
    width: 'auto',
  },
  lang: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: "35px",
    height: "35px",
    color: "white"
  },
  textlang: {
    borderRadius: "50%", padding: "0px", width: "25px", color: "white", height: "25px", border: "2px solid white", display: "flex", alignItems: "center", justifyContent: "center",
    fontSize: "14px",
    fontWeight: "500"

  },
});

export default function SwipeableTemporaryDrawer() {
  const history = useHistory()
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const langClient = useSelector(state => state.data.langClient)
  const dispatch = useDispatch()

  function setLang(e, lang) {
    e.preventDefault()
    dispatch(setLangClient(lang))
  }

  const toggleDrawer = (stat) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(stat)
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list)}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center" alignItems="center" paddingY="0.5rem">
        <img src={Logo} style={{ height: "80%" }} />
      </Box>
      <Divider />
      <Box display="flex" flexDirection="column" padding="0.75rem">
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/" className={history.location.pathname == '/' ? classes.linkAct : classes.linkNon}>HOME</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/dive-location" className={history.location.pathname.includes('dive-location') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "DIVE LOCATION" : "LOKASI SELAM"}</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/dive-center" className={history.location.pathname.includes('dive-center') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "DIVE CENTER" : "OPERATOR SELAM"}</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/liveaboard" className={history.location.pathname.includes('liveaboard') ? classes.linkAct : classes.linkNon}>LIVEABOARD</Link>
        </Box><Box marginBottom="0.75rem">
          <Link underline="none" to="/freediving" className={history.location.pathname.includes('freediving') ? classes.linkAct : classes.linkNon}>FREEDIVING</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/cavediving" className={history.location.pathname.includes('cavediving') ? classes.linkAct : classes.linkNon}>CAVE DIVING</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/livingreefs" className={history.location.pathname.includes('livingreefs') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "LIVING REEFS" : "TERUMBU KARANG"}</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/hyperbaric-chambers" className={history.location.pathname.includes('hyperbaric-chambers') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "HYPERBARIC CHAMBERS" : "FASILITAS HIPERBARIK"}</Link>
        </Box>
        <Box marginBottom="0.75rem">
          <Link underline="none" to="/gallery" className={history.location.pathname.includes('gallery') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "PHOTO GALLERY" : "GALERI"}</Link>
        </Box>
        <Link underline="none" to="/regulations" className={history.location.pathname.includes('regulations') ? classes.linkAct : classes.linkNon}>{langClient == "EN" ? "REGULATIONS" : "REGULASI"}</Link>
      </Box>
    </div>
  );

  return (
    <div>
      <Box display='flex' height='fit-content' justifyContent="space-between" bgcolor="white">
        <Box width={1 / 3} display="flex" alignItems="center">
          <Button onClick={toggleDrawer(true)}><MenuIcon style={{ fontSize: "2rem" }} /></Button>
        </Box>

        <Box width={1 / 3} display="flex" justifyContent="center" alignItems="center" paddingY='0.5rem'>
          <img src={Logo} style={{ height: "80%" }} />
        </Box>
        <Box width={1 / 3} justifyContent="flex-end" display='flex' alignItems="center" marginRight="0.5rem">
          <Box style={{ cursor: "pointer" }} variant="contained" bgcolor={langClient == "EN" ? "#28B0E6" : "#CECECE"} className={classes.lang}
            onClick={(e) => setLang(e, "EN")} marginRight="0.5rem">
            <Box variant="contained" bgcolor={langClient == "EN" ? "#28B0E6" : "#CECECE"} className={classes.textlang}>
              EN
                        </Box>
          </Box>
          <Box style={{ cursor: "pointer" }} variant="contained" bgcolor={langClient == "IN" ? "#28B0E6" : "#CECECE"} className={classes.lang} onClick={(e) => setLang(e, "IN")} >
            <Box variant="contained" bgcolor={langClient == "IN" ? "#28B0E6" : "#CECECE"} className={classes.textlang} >
              IN
                        </Box>
          </Box>
        </Box>
      </Box>



      <SwipeableDrawer
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {list("left")}
      </SwipeableDrawer>

    </div>
  );
}