import { Typography, Box, Grid, makeStyles, Button } from '@material-ui/core'
import React, { useState } from 'react'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import Immigration from '../../../Images/immigration.svg'
import Shield from '../../../Images/shield.svg'
import Dive from '../../../Images/dive_i.svg'
import ModalImmigration from '../../../Components/RegulationsModal/Immigration'
import ModalCHSE from '../../../Components/RegulationsModal/CHSE'
import ModalDiving from '../../../Components/RegulationsModal/Diving'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    boxRegulation: {
        background: "#DEDEDE",
        borderRadius: "8px",
        padding: " 1.5rem",
    },
    icon: {
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        top: "-3rem",
        borderRadius: "10px",
        zIndex: "120"
    }
}))

export default function Index() {
    const classes = useStyles()
    const langClient = useSelector(state => state.data.langClient)
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <>
           {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches? "7rem":"0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" bgcolor="#F2F3F9" textAlign="center" marginBottom="5rem">

                <Box display="flex" flexDirection="column" alignItems="center">
                    <Typography className="titlePage" style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}>{langClient =='EN'? "Regulations":"Regulasi"}</Typography>
                    <Box width={matches ? '50%' : '100%'} marginTop='1rem'>
                        <Typography style={!matches ? { fontSize: "14px" } : {}}>
                        {
                            langClient == 'EN'? 
                            'Below you will find regulations to assist you when visiting Indonesia. We respectfully request that all visitors adhere to all regulations contained in the documents below.' :
                            'Berikut ini peraturan-peraturan yang perlu diperhatikan ketika Anda mengunjungi Indonesia. Para wisatawan diharapkan agar dapat mematuhi semua peraturan yang telah ditetapkan.'
                        }
                        </Typography>
                    </Box>
                </Box>

                <Box marginTop="5rem" textAlign="center">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.boxRegulation}>
                                <Box width="100%" height={matches?"15rem":"130px"} position="relative">
                                    <Box className={classes.icon} height={matches?"17rem":"166px"} bgcolor="#50BCE7">
                                        <img src={Immigration} />
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography variant={matches?"h3":"h4"} style={matches?{ color: "#50BCE7", fontWeight: "700" }:{ color: "#50BCE7", fontWeight: "500" }}>{langClient =='EN'? "IMMIGRATION":"IMIGRASI"}</Typography>
                                    <Box marginTop='1rem'>
                                        <ModalImmigration/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.boxRegulation}>
                                <Box width="100%" height={matches?"15rem":"130px"} position="relative">
                                    <Box className={classes.icon} height={matches?"17rem":"166px"} bgcolor="#D5347C">
                                        <img src={Shield} />
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography variant={matches?"h4":"h4"} style={matches?{ color: "#50BCE7", fontWeight: "700", fontSize: '36.5px'}:{ color: "#50BCE7", fontWeight: "500",  }}>{langClient == 'EN'? 'Guidance for New Protocol' : 'Panduan Pelaksanaan Protokol Baru'}</Typography>
                                    <Box marginTop='1rem'>
                                        <ModalCHSE/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Box className={classes.boxRegulation}>
                                <Box width="100%" height={matches?"15rem":"130px"} position="relative">
                                    <Box className={classes.icon} height={matches?"17rem":"166px"} bgcolor="#6F519D">
                                        <img src={Dive} />
                                    </Box>
                                </Box>
                                <Box >
                                    <Typography variant={matches?"h4":"h4"} style={matches?{ color: "#50BCE7", fontWeight: "700" }:{ color: "#50BCE7", fontWeight: "500" }}>{langClient =='EN'? "Recreational Dive Tourism Guidelines":"Pedoman Wisata Selam Rekreasi"}</Typography>
                                    <Box marginTop='1rem'>
                                        <ModalDiving/>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box marginTop='10rem'>
                <Footer />
            </Box>
        </>
    )
}
