import React, { useState, useRef } from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Popper, Box, ClickAwayListener, MenuItem, MenuList, Grow, Paper, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../../store/actions/userAction';
import {useHistory} from 'react-router-dom'

export default function Index() {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(true);
    const [openPopup, setOpenPopup] = useState(false);
    const language = useSelector(state => state.data.language)
    const dispatch = useDispatch()
    const history = useHistory()

    function setLang(e, lang) {
        e.preventDefault()
        dispatch(setLanguage(lang))
    }

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpenPopup(false);
    };

    const handleToggle = () => {
        console.log('ass')
        setOpenPopup((prevOpen) => !prevOpen);
    };

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    function logout() {
        console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
        localStorage.clear()
        history.push('/admin/login')
    }

    return (
        <>
            <Box style={{
                borderBottom: "0.5px solid #DDDDDD",
                height: "62px",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "36px",
                alignItems: "center"
            }}>
                
                <div className="container-appbar-right-side" >
                {/* <Box marginRight="1rem" display="flex">
                    <Button variant={language == "ind" ? "contained" : "outlined"} color={language == "ind" ? "primary" : "none"} style={{
                        borderRadius: "1rem", textTransform: "none", boxShadow: "none",
                        fontSize: "0.75rem",
                        padding: "0 1rem",
                        marginRight: "0.5rem"
                    }} onClick={(e) => setLang(e, "ind")} >
                        Bahasa Indonesia
                            </Button>
                    <Button variant={language == "eng" ? "contained" : "outlined"} color={language == "eng" ? "primary" : "none"} style={{
                        borderRadius: "1rem", textTransform: "none", boxShadow: "none",
                        fontSize: "0.75rem",
                        padding: "0 1rem",
                        marginRight: "0.5rem"
                    }} onClick={(e) => setLang(e, "eng")} >
                        English
                            </Button>
                </Box> */}
                    <div className="profile-navbar-btn"
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <Box borderRadius="50%" width="1.25rem" height="1.25rem" display="flex" justifyContent="center" alignItems="center" style={{ background: "#28B0E6", color: "white" }}>S</Box>
                        <span className="profile-txt-name" > Super Admin </span>
                        {
                            !openPopup ?
                                <ExpandMoreIcon style={{ color: '#CCCCCC', fontSize: 25 }} />
                                :
                                <ExpandLessIcon style={{ color: '#CCCCCC', fontSize: 25 }} />
                        }
                        <Popper open={openPopup} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center top' }}
                                >
                                    <Paper style={{
                                        padding: 0,
                                        marginTop: 5,
                                        boxShadow: "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)"
                                    }} >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList autoFocusItem={openPopup} id="menu-list-grow" style={{ padding: 0 }} onKeyDown={handleListKeyDown}>
                                                <MenuItem onClick={logout} className="popup-list" >
                                                    <span className="text-menu-item-popup" > Keluar </span>
                                                    <i className="fa fa-sign-out unlock-custom" />
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                </div>
            </Box>
        </>
    )
}
