import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ImageGallery from 'react-image-gallery';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';

export default function Index(props) {
    const [open, setOpen] = React.useState(props.open);
    // const [images, setImages] = useState([
    //     {
    //         original: 'https://picsum.photos/id/1015/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1015/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1019/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1019/250/150/',
    //     },
    // ])

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    console.log(props.images, '-----------')

    return (
        <div>
            <Box></Box>
            <Dialog
                open={open}
                onClose={props.handleClose} 
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle>Photo Gallery</DialogTitle>
                <DialogContent>
                    <Box>
                    <ImageGallery startIndex={props.start} fullscreen={true} items={props.images} showFullscreenButton={false} showThumbnails={false} showPlayButton={false}/>
                    </Box>
                
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}