import { Typography, Box } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useSelector } from 'react-redux';
import fd2 from '../../../Images/FD_02_kotak.png'
import { useParams } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import MUIRichTextEditor from 'mui-rte'
import Swal from 'sweetalert2';
import Axios from 'axios';
import server from '../../../config/server';


const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                color: "#444444"
            }
        }
    }
})

export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const { id } = useParams()
    const [data, setData] = useState({})

    
    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/news/detail/${id}`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(({ data }) => {
                Swal.close()
                setData(data)
                // setAreas(data)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}

            <Box marginTop='4vw'>
                <img src={data.photo} style={{ width: '100%' }} />
            </Box>
            <Box marginTop={matches ? "10rem" : "0"} width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" marginTop="2rem" marginBottom="5rem">
                <Box display="flex" justifyContent="center">
                    <Box width={matches ? '950px' : '100%'}>
                        <Box marginY="1rem" >

                            <Typography variant="h5" style={{ fontWeight: "500", fontSize: "32px", lineHeight: "37px" }}>
                                {langClient == 'EN' ? data.titleEng:data.titleInd}
                            </Typography>

                        </Box>

                        <Box >
                            <MuiThemeProvider theme={defaultTheme}>
                                <MUIRichTextEditor
                                    defaultValue={langClient == "EN" ? data.contentEng : data.contentInd}
                                    readOnly={true}
                                    toolbar={false}
                                />
                            </MuiThemeProvider>
                        </Box>


                    </Box>
                </Box>

            </Box>
            <Footer />
        </>
    )
}
