import React, { useState } from 'react'
import { Box, Typography, useMediaQuery } from '@material-ui/core'
import PinDropIcon from '@material-ui/icons/PinDrop';
import CallIcon from '@material-ui/icons/Call';
import LanguageIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import server from '../../../../config/server';
import logo from '../../../../Images/defult-photo.png'

export default function BoxList(props) {
    const matches = useMediaQuery('(min-width:600px)');
    const [hover, setHover] = useState(false)

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }


    console.log(props.loc.website)
    return (
        <div>
            <Box position="relative">
                <Box position={matches && hover ? "absolute" : ""} zIndex={hover ? "20" : "0"} onPointerEnter={() => [setHover(true)]} onPointerLeave={() => [setHover(false)]} bgcolor="white" boxShadow="0px -1px 2px rgba(117, 117, 117, 0.1), 0px 1px 2px rgba(117, 117, 117, 0.1)" borderRadius="4px">

                   
                    <Box borderRadius='4px' height={matches ? "15vw" : "15rem"} style={{ backgroundImage: `url(${logo})`, backgroundSize: "cover", backgroundPosition: "center" }}></Box>
                    <Box width={matches ? "17.5vw" : "18rem"} textAlign="left" p="1rem">
                        <Typography noWrap={!hover} style={{ color: "black" }} variant="h6">{props.loc.name}</Typography>
                        <Typography noWrap={!hover} style={{ fontWeight: "500" }}>{props.loc.afiliasi}</Typography>
                        <Box width="80%" marginTop="0.5rem" style={{color: '#666666'}}>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <PinDropIcon style={{fontSize: '13px'}}/>
                                </Box>
                                <Typography style={{ fontSize: '13px' }} noWrap={!hover}>{props.loc.address}</Typography>
                            </Box>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <CallIcon style={{fontSize: '13px'}} />
                                </Box>
                                <Typography style={{ fontSize: '13px' }} noWrap={!hover}>{props.loc.phone}</Typography>
                            </Box>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <EmailIcon style={{fontSize: '13px'}} />
                                </Box>
                                <Typography style={{ fontSize: '13px' }} noWrap={!hover}>{props.loc.email}</Typography>
                            </Box>
                            <Box display="flex">
                                <Box marginRight="1rem">
                                    <LanguageIcon style={{fontSize: '13px'}} />
                                </Box>
                                <Typography noWrap={!hover}>
                                    <a href={`http://${props.loc.website}`} style={{ fontSize: '13px', color: '#28B0E6', textDecoration: 'none' }} rel="noreferrer"  target="_blank">{props.loc.website}</a>
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
        </div>
    )
}
