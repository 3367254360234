import { Typography, Box, Grid, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { setDiveLocation } from '../../../store/actions/userAction'
import { useDispatch, useSelector } from 'react-redux'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import Swal from 'sweetalert2'
import content from './content'
import Map from '../../../Components/Map'

const defaultTheme = createMuiTheme()

Object.assign(defaultTheme, {
    overrides: {
        MUIRichTextEditor: {
            root: {
            },
            editor: {
                color: "#444444"
            }
        }
    }
})

const useStyles = makeStyles((theme) => ({
    box: {
        background: 'white',
        cursor: "pointer",
        borderRadius: "4px"
    }
}))

export default function Index() {
    const classes = useStyles()
    const matches = useMediaQuery('(min-width:600px)');
    const history = useHistory()
    const location = useSelector(state => state.data.diveLocations)
    const [diveLocation, setDive] = useState([])
    const langClient = useSelector(state => state.data.langClient)
    const dispatch = useDispatch()
    const [flag, setflag] = useState(false)

    useEffect(() => {
        Swal.showLoading()
        if (location.length == 0) {
            dispatch(setDiveLocation())
        }
    }, [])

    function toBase64(arr) {
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
    }


    useEffect(() => {
        let items = location
        items.sort(function (a, b) {
            if (langClient == "EN") {
                var nameA = a.nameEng.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameEng.toUpperCase(); // ignore upper and lowercase
            } else {
                var nameA = a.nameInd.toUpperCase(); // ignore upper and lowercase
                var nameB = b.nameInd.toUpperCase(); // ignore upper and lowercase
            }

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
        console.log(items)
        setDive(items)
        Swal.close()
        setflag(!flag)
    }, [location, langClient])

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "4.9rem" : "0"}>
                <Box height={matches?'500px':'300'}>
                    <Map/>
                </Box>
                <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} >
                </Box>

                <Box marginTop={matches?"2rem":'1rem'} style={{ textAlign: "center" }} display="flex" flexDirection="column" alignItems="center">
                    {/* <Typography variant={matches ? "h3" : "h4"} style={matches ? { fontWeight: "900" } : { fontWeight: "700" }}>{langClient == 'EN' ? "Dive Locations in" : "Lokasi Selam di"} Indonesia</Typography> */}
                    <Typography className="titlePage" variant={matches ? "h3" : "h4"} style={matches ? { fontWeight: "900", color: '#000080' } : { fontWeight: "700" }}>{langClient == 'EN' ? "Pick Your Favorite One! Or Two?" : "Pilih Lokasi Selam Favorit Anda"}</Typography>
                    <Box width={matches ? "37rem" : '90%'} marginTop='1rem'>
                        <Typography style={matches ? { fontSize: "1rem" } : { fontSize: "14px" }}>
                            {
                                langClient == 'EN' ? content.EN.top : content.IND.top
                            }
                        </Typography>
                    </Box>
                </Box>
                <Box marginTop="3rem" width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginBottom="7rem">
                    <Grid container spacing={3}>
                        {
                            diveLocation && diveLocation.map((loc, i) => {
                                console.log(loc, '==lo')
                                return (
                                    <Grid key={i} item xs={12} sm={4}>
                                        <Box className={classes.box} onClick={() => [history.push(`/dive-location/${loc.id}`)]} padding={2}>
                                            <Box height={matches ? "17vw" : "190px"} style={loc.photoTile ? { backgroundImage: `url(${loc.photoTile})`, backgroundSize: "cover", backgroundPosition: "center" } : { backgroundSize: "cover", backgroundPosition: "center" }}></Box>
                                            <Box marginTop="0.5rem">
                                                <Typography variant="h5" style={{ fontWeight: "500" }}>{langClient == "EN" ? loc.nameEng : loc.nameInd}</Typography>
                                            </Box>
                                            <Box marginTop="0.5rem">
                                                <Typography style={{ fontWeight: "500", fontSize: '14px'}}>
                                                    {loc.titleEn}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                </Box>
            </Box>
            <Box marginTop='5rem'>
                <Footer />
            </Box>
        </>
    )
}
