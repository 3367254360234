import { Typography, Box, Grid, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useDispatch, useSelector } from 'react-redux'
import { setLiveaboard } from '../../../store/actions/userAction'
import BoxList from './BoxList'
import Axios from 'axios';
import server from '../../../config/server';
import Swal from 'sweetalert2';
import Background from '../../../Images/liveaboardBG.jpg'
import Disclaimer from '../../../Components/disclaimer'

export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    // const liveaboard = useSelector(state => state.data.liveaboard)
    const [liveaboard, setLiveaboardData] = useState([])
    const langClient = useSelector(state => state.data.langClient)
    const [load, setLoad] = useState(1)
    const dispatch = useDispatch()

    useEffect(() => {
        Swal.showLoading()
        Axios({
            url: `${server}/liveaboard/offset/0/12`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                Swal.close()
                setLiveaboardData(data.data)
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }, [])

    function loadmore() {
        let offset = 12 * load
        let oldData = liveaboard
        let loadCount = load
        Swal.showLoading()
        Axios({
            url: `${server}/liveaboard/offset/${offset}/12`,
            method: 'get',
            headers: {
                access_token: localStorage.access_token
            }
        })
            .then(data => {
                let newData = oldData.concat(data.data)
                setLiveaboardData(newData)
                loadCount++
                setLoad(loadCount)
                Swal.close()
            })
            .catch(err => {
                console.log(err, '=====errro')
            })
    }

    console.log(liveaboard, '============')
    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box position="relative" >

                <Box width="100%" height={!matches ? "400px" : "800px"} paddingTop={!matches ? "10px" : "7rem"} style={{ textAlign: "center", color: "white", backgroundImage: `url(${Background})`, backgroundSize: "cover", backgroundPosition: 'center' }} display="flex" flexDirection="column" alignItems="center">

                    <Box width={matches ? "40rem" : '95%'} marginTop={matches ? '5vw' : '85px'}>
                        {
                            langClient == 'EN'?<Typography  variant={matches ? "h3" : "h4"} style={matches ? { fontWeight: "900" } : { fontWeight: "700" }}>Sail Around <br/> Our Spectacular  Archipelago</Typography>:
                            <Typography  variant={matches ? "h3" : "h4"} style={matches ? { fontWeight: "900" } : { fontWeight: "700" }}>Jelajahi <br/> Keindahan Perairan Nusantara</Typography>
                        }

                        <Box marginTop='1rem'><Typography style={matches ? { fontSize: "1rem" } : { fontSize: "14px" }}>
                            {langClient == 'EN' ? 'Indonesia’s archipelagic geography makes for phenomenal liveaboard opportunities, and some dive sites are not accessible by any other means. The majority of liveaboards are still built in the style of traditional Indonesian Phinisi schooners to this day.' : 'Perairan Indonesia menawarkan keseruan  liveaboard yang luar biasa.  Dan, sejumlah lokasi selam di Indonesia hanya dapat dicapai dengan cara yang satu ini. Nikmati perjalanan bahari menuju lokasi selam favorit Anda dengan kapal yang sebagian besar masih mengambil bentuk kapal layar tradisional Pinisi.'}
                        </Typography>
                        </Box>
                    </Box>
                    <Disclaimer />
                </Box>

                <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginTop={matches ? "-5vw" : "-2rem"} marginBottom="7rem">
                    <Box style={{ color: "#666666" }}>
                        <Grid container spacing={3}>
                            {
                                liveaboard && liveaboard.map((loc, i) => {
                                    return (
                                        <Grid key={i} item xs={12} sm={3}>
                                            <Box display="flex" justifyContent={matches ? "" : "center"}>
                                                <BoxList loc={loc} />
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <Box marginTop='140px' justifyContent='center' display='flex'>
                            <Button variant="outlined" color="primary" style={{ textTransform: "none" }} onClick={loadmore}>Load More</Button>
                        </Box>
                    </Box>

                </Box>


            </Box>
            <Box marginTop='146px'>
                <Footer />
            </Box>

        </>
    )
}
