import { Box, Button, Typography, Grid } from '@material-ui/core';
import React, { useState } from 'react'
import ImageGallery from 'react-image-gallery';
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useSelector } from 'react-redux';
import fd1 from '../../../Images/FD_01_kotak.png'
import fd2 from '../../../Images/FD_02_kotak.png'
import fd1L from '../../../Images/FD_01.png'
import fd2L from '../../../Images/FD_02.png'
import fd3L from '../../../Images/FD_03_L.png'
import fd3 from '../../../Images/FD_03.jpg'
import { useEffect } from 'react';


const descLang = { en: 'Rich coral reefs and incredible marine life make Indonesia a freedivers paradise', ind: 'Keindahan terumbu karang serta kehidupan laut yang berlimpah di Indonesia adalah surga bagi para freedivers.' }
export default function Index() {
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
    const [flag, setFlag] = useState(false)

    const [images, setImages] = useState([
        {
            original: fd1L,
            thumbnail: fd1,
            description: descLang.en
        },
        {
            original: fd2L,
            thumbnail: fd2,
            description: descLang.en
        },
        {
            original: fd3L,
            thumbnail: fd3,
            description: descLang.en
        },

    ])

    const [imagesMobile, setImagesMobile] = useState([
        {
            original: fd1,
            thumbnail: fd1,
            description: descLang.en
        },
        {
            original: fd2,
            thumbnail: fd2,
            description: descLang.en
        },
        {
            original: fd3,
            thumbnail: fd3,
            description: descLang.en
        },

    ])

    //IND

    const [imagesInd, setImagesInd] = useState([
        {
            original: fd1L,
            thumbnail: fd1,
            description: descLang.ind
        },
        {
            original: fd2L,
            thumbnail: fd2,
            description: descLang.ind
        },
        {
            original: fd3L,
            thumbnail: fd3,
            description: descLang.ind
        },

    ])

    const [imagesMobileInd, setImagesMobileInd] = useState([
        {
            original: fd1,
            thumbnail: fd1,
            description: descLang.ind
        },
        {
            original: fd2,
            thumbnail: fd2,
            description: descLang.ind
        },
        {
            original: fd3,
            thumbnail: fd3,
            description: descLang.ind
        },

    ])

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches ? "5rem" : "0"}>
                {
                    langClient == 'EN' ?
                        <ImageGallery fullscreen={true} items={matches ? images : imagesMobile} showFullscreenButton={false} showPlayButton={false} /> :
                        <ImageGallery fullscreen={true} items={matches ? imagesInd : imagesMobileInd} showFullscreenButton={false} showPlayButton={false} />
                }

            </Box>
            <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} marginBottom="5rem" textAlign={matches ? "left" : "left"}>
                <Box display="flex" justifyContent="center">
                    <Box width={matches ? '1050px' : '100%'}>
                        <Box display="flex" justifyContent="center">
                            <Box>
                                <Typography className="titlePage" style={matches ? { fontWeight: "900", fontSize: "48px" } : { fontWeight: "700", fontSize: "24px" }}> The Best Way to Observe Fish is to Become a Fish<br /></Typography>
                                <Box width={'100%'} display='flex' justifyContent='flex-end'>
                                    <span className="titlePage" style={matches ? { fontWeight: "700", fontSize: "30px", textAlign: 'right' } : { fontWeight: "500", fontSize: "16px" }}>- <i>Jacques Yves Cousteau</i></span>
                                </Box>
                            </Box>

                        </Box>
                        {
                            langClient == 'EN' ?
                                <Box marginTop="2rem">
                                    <Typography style={{ fontSize: '1.1rem' }}>
                                        Freediving is the ultimate challenge of mind and body, and whether you can hold your breath for seconds or minutes, you’ll experience a sense of weightlessness, serenity and self-awareness that is hard to find on solid ground. In the words of Jacques-Yves Cousteau, “man only has to sink beneath the surface and he is free’.
                                        <br /><br />
                                        Take a look under the waves in Indonesia and you will be immediately greeted by an underwater world which is bursting with colour and teeming with life.
                                        <br /><br />
                                        Watch the living reef in action and it will keep you captivated for as long as you remain beneath the surface. Witness the power, strength and magic of the ocean in the heart of the Coral Triangle. Welcome to Indonesia….
                                        <br /><br />
                                        Indonesia’s phenomenal diversity of reef formations and marine life make it an excellent destination for freedivers who want to explore some of the most vibrant and productive marine ecosystems on earth. If you are not already a freediver there are plenty of freediving schools in Indonesia where you can take certification courses for beginners, and more advanced programs for those who already have some experience.
                                        <br /><br />
                                        Freediving has not been around in Indonesia for as long as scuba diving but it has grown fast in terms of numbers and popularity. Freediving schools are now common in many areas and many dive centers also run freediving courses and trips.
                                        <br /><br />
                                        When freediving in Indonesia it is strongly recommended that you follow all recommended safety protocols and make your dives with a freediving school or organization. Local free divers have a wealth of knowledge and experience in the waters, currents, tides and dive sites in their area. They have an appreciation of the unique hazards that relate to their region and will ensure that you visit the best sites.
                                        <br /><br />
                                        Most importantly, respect the marine environment, stay safe and have fun!
                                    </Typography>
                                </Box> :
                                <Box marginTop="2rem">
                                    <Typography style={{ fontSize: '1.1rem' }}>
                                        Freediving merupakan kegiatan selam yang menghadirkan tantangan bagi tubuh dan pikiran. Entah Anda bisa menahan nafas beberapa detik atau menit, Anda akan tetap merasakan sensasi tanpa bobot, ketenangan dan kesadaran diri yang sulit ditemukan jika berpijak di daratan. Mengutip Jacques-Yves Cousteau, “manusia hanya perlu tenggelam di bawah permukaan dan dia bebas”.
                                        Selami dunia bawah air di Indonesia dan Anda akan segera disambut oleh dunia yang meriah penuh warna dan semarak dengan kehidupan.
                                        <br /><br />
                                        Kehidupan terumbu karang yang memukau akan membuat Anda terpikat saat Anda berada di bawah permukaan. Saksikan daya, kekuatan dan keajaiban lautan di jantung Segitiga Terumbu Karang. Selamat datang di Indonesia....
                                        <br /><br />
                                        Keberadaan freediving di Indonesia memang belum terlalu lama jika dibandingkan dengan scuba diving yang sudah terlebih dahulu popular dan berkembang pesat. Namun, belakangan ini, kursus freediving sudah lebih mudah dijumpai di berbagai daerah dan kini operator selam pun sudah banyak yang menawarkan paket perjalanan freediving.
                                        <br /><br />
                                        Jika Anda melakukan freediving di Indonesia, sangat disarankan untuk mematuhi protokol keselamatan, dan senantiasa melakukan aktivitas ini melalui sekolah atau kelompok freediving yang sudah ada. Freedivers yang berasal dari lokasi setempat memiliki pengetahuan dan pengalaman luas tentang perairan, arus, pasang surut dan lokasi selam di daerahnya masing-masing. Mereka mampu mengenali dan mengantisipasi dengan baik kondisi penyelaman di daerahnya masing-masing dan dapat memastikan Anda mengunjungi  tempat-tempat terbaik.
                                        <br /><br />
                                        Yang terpenting, hormati lingkungan laut, pastikan Anda telah melakukan protokol keselamatan dengan benar, dan bersenang-senanglah!
                                    </Typography>
                                </Box>
                        }

                    </Box>
                </Box>

            </Box>
            <Box marginTop='5rem'>
                <Footer />
            </Box>
        </>
    )
}
