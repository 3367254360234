import { Box, Button, Typography } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ModalGallery from '../ModalGallery'

export default function Index(props) {
    const history = useHistory()

    console.log(props)
    return (
        <>
            {/* <ModalGallery open={openModal}/> */}
            <Box width="100%" height="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                style={{ position: "absolute", top: "0", background: "linear-gradient(359.6deg, rgba(249, 60, 167, 0.9) 0.28%, rgba(109, 81, 157, 0.9) 99.59%)" }}>
                <Box padding="1rem">
                    <Typography style={{ color: "white", fontSize: '28px', fontWeight: "900", textAlign: "center" }} >{props.name}</Typography>
                    <Typography style={{ color: "white", fontSize: '18px', fontWeight: "500", textAlign: "center" }} >{props.location}</Typography>
                </Box>
                <Box marginTop="1rem">
                    <Button variant="contained" size="small" onClick={!props.gallery?(() => [history.push(props.toHistory)]):(()=>props.changeOpenModal(props.i))} style={{ padding: "0.25rem 2rem", color: "#F93CA7", background: "white", textTransform: "none", borderRadius: "2rem" }}> {props.gallery ? "Click to Zoom" : "Explore Now"}</Button>
                </Box>
            </Box>
        </>
    )
}
