import React, { useState } from 'react'
import { Box, Typography, withStyles, makeStyles, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Paper, } from '@material-ui/core'
import { rows } from './names'
import AppBarClient from '../../../../Components/AppBarClient'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../../Components/Drawer'
import Footer from '../../../../Components/Footer'


const StyledTableCell = withStyles((theme) => ({
    root: {
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    head: {
        backgroundColor: "#F3F3F3",
        color: "#444444",
        fontWeight: 500,
        // padding: "0.5em 1.5em",
    },
    body: {
        // padding: "0.5em 1.5em",
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#FAFAFA",
        },
    },
}))(TableRow);


const useStyles = makeStyles({
    table: {
        minWidth: 700,
        boxShadow: "none"
    },
    tableContainer: {
        boxShadow: "none",
        // border: "0.5px solid #DDDDDD",
        borderRadius: "4px"
    },
});

const arr = [1, 2, 3, 4, 56, 7, 8, 9, 1, 1, 2, 2,]

const alfabet = {
    a: ["A", "B", "C", "D", "E", "F", "G", "H"],
    b: ["I", "J", "K", "L", "M", "N", "O", "P"],
    c: ["Q", "R", "S", "T", "U", "V", "Z"]
}

export default function PIN(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box width={matches ? "86%" : "94%"} paddingX={matches ? "7%" : '3%'} bgcolor="#F2F3F9" textAlign="center" marginTop="7rem" marginBottom="5rem">
            <Typography variant="h4" style={{ fontWeight: "500" }}>169 Countries</Typography>
                <Box paddingTop="2em">
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {
                                        alfabet.a.map((item, i) => <StyledTableCell key={i} align="left">{item}</StyledTableCell>)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    arr.map((item, i) => {
                                        return (
                                            <StyledTableRow >
                                                <StyledTableCell align="left">{rows.A[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.B[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.C[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.D[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.E[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.F[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.G[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.H[i]}</StyledTableCell>
                                            </StyledTableRow>
                                        )})
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box paddingTop="2em">
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {
                                        alfabet.b.map((item, i) => <StyledTableCell key={i} align="left">{item}</StyledTableCell>)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    arr.map((item, i) => {
                                        return (
                                            <StyledTableRow >
                                                <StyledTableCell align="left">{rows.I[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.J[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.K[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.L[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.M[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.N[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.O[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.P[i]}</StyledTableCell>
                                            </StyledTableRow>
                                        )})
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                <Box paddingTop="2em">
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    {
                                        alfabet.c.map((item, i) => <StyledTableCell key={i} align="left">{item}</StyledTableCell>)
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    arr.map((item, i) => {
                                        return (
                                            <StyledTableRow >
                                                <StyledTableCell align="left">{rows.Q[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.R[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.S[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.T[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.U[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.V[i]}</StyledTableCell>
                                                <StyledTableCell align="left">{rows.Z[i]}</StyledTableCell>
                                            </StyledTableRow>
                                        )})
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
            <Box marginTop='20rem'>
                <Footer />
            </Box>
        </>
    )
}