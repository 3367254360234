import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import { makeStyles, Box, Typography, Button } from '@material-ui/core';
import axios from 'axios'
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles({
    buttonOK: {
        padding: "0.5em",
        textTransform: "none",
        marginRight: "1rem"
    },
    boxCircle: {
        borderRadius: "50%",
        height: "9rem",
        width: "9rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "#EEFAFF"
    },
    buttonBatal: {
        textTransform: 'none',
        padding: "0.5em",
        marginLeft: "1rem"
    }
})


export default function Index(props) {
    const classes = useStyles()
    const [open, setOpen] = useState(props.open);
    const history = useHistory()

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    function deleteId () {
        axios({
            url: `${props.url}/${props.id}`,
            method: 'delete',
            headers: {
              access_token: localStorage.access_token
            }
          })
            .then(data => {
                props.handleClose()
            })
            .catch(err => {
              console.log(err)
            })
        
            
    }

    return (
        <>
            <Box>
                
            </Box>
            <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" maxWidth="sm" open={open}>
                <Box align="center" marginTop="2rem">
                    <Box className={classes.boxCircle}><i className="fa fa-info-circle" aria-hidden="true" style={{ color: "#28B0E6", fontSize: "8rem" }}/></Box>
                </Box>
                <Box paddingX="3rem" paddingTop="1rem"><Typography variant="h5" style={{fontWeight: "500"}} align="center"> Yakin Hapus {props.location} Dari Daftar? </Typography></Box>
                <Box display="flex" justifyContent="center" marginY="2em">
                    <Box width={1 / 4}><Button variant="contained" fullWidth onClick={deleteId} color="primary" className={classes.buttonOK}>Ya, Hapus</Button></Box>
                    <Box width={1 / 4}><Button variant="outlined" fullWidth color="primary" onClick={props.handleClose} className={classes.buttonBatal} >Batal</Button></Box>
                </Box>
            </Dialog>
        </>
    )
}
