const initialState = {
    language: "eng",
    langClient: "EN",
    diveLocations: [],
    loadDiveLocations: false,
    loadDiveLocationsName: false,
    loadVisit: false,
    loadDiveCenter: false,
    loadLiveaboard: false,
    loadHyperbaric: false,
    loadGallery: false,
    diveLocationsName: [],
    visit: [],
    visitName: [],
    diveCenter: [],
    liveaboard: [],
    liveaboardName: [],
    hyperbaric: [],
    gallery: [],
    news: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case "CHANGELANGUAGE":
            return { ...state, language: action.payload }
        case "CHANGELANGCLIENT":
            return { ...state, langClient: action.payload }
        case "SET_DIVELOCATION":
            return { ...state, diveLocations: action.payload }
        case "SET_DIVECENTER":
            return { ...state, diveCenter: action.payload }
        case "SET_LIVEABOARD":
            return { ...state, liveaboard: action.payload }
        case "SET_LIVEABOARDNAME":
            return { ...state, liveaboardName: action.payload }
        case "SET_HYPERBARIC":
            return { ...state, hyperbaric: action.payload }
        case "SET_GALLERY":
            return { ...state, gallery: action.payload }
        case "SET_NEWS":
            return { ...state, news: action.payload }
        case "SET_VISIT":
            return { ...state, visit: action.payload }
        case "SET_VISITNAME":
            return { ...state, visitName: action.payload }
        case "SET_DIVELOCATION_NAME":
            return { ...state, diveLocationsName: action.payload }
        case "SET_LOADDIVELOCATION":
            return { ...state, loadDiveLocations: action.payload }
        case "SET_LOADDIVECENTER":
            return { ...state, loadDiveCenter: action.payload }
        case "SET_LOADLIVEABOARD":
            return { ...state, loadLiveaboard: action.payload }
        case "SET_LOADHYPERBARIC":
            return { ...state, loadHyperbaric: action.payload }
        case "SET_LOADGALLERY":
            return { ...state, loadGallery: action.payload }
        case "SET_LOADVISIT":
            return { ...state, loadVisit: action.payload }
        case "SET_LOADDIVELOCATION_NAME":
            return { ...state, loadDiveLocationsName: action.payload }
        default:
            return state

    }
}