const content = {
    EN: {
        top: 'The Indonesian Archipelago comprises more than 17,500 islands, thousands of kilometers of coast line and over 1,500 dive spots just waiting to be explored. Each province showcases distinct regional characteristics and unique highlights.',
        sumatra: 'Sweeping currents bring hordes of fish and action to Pulau Weh’s dive sites. Riau offers a more relaxing pace but just as impressive.',
        java: 'Explore the Thousand Islands, dive the mighty Krakatoa volcano and go off the grid in Karimunjawa. Java is full of surprises underwater.',
        bali: 'Vibrant reefs, resident manta rays, seasonal mola, a WWII wreck, muck diving and drifts – Bali is full of choices and has something for everyone!',
        ntb: 'Lombok’s Gili Islands offer palm-fringed white sand beaches and a care free vibe. They are the perfect location to kick back, relax and dive!',
        ntt: 'Welcome to the land of the dragons and some of Indonesia’s most iconic dive sites. East Nusa Tenggara is a must for anyone seeking adventure!',
        borneo: 'Step away from the crowds and back into nature in Borneo. Resident manta rays and remote reefs make this an idyllic off-grid destination.',
        sulawesi: 'From world-class muck through to walls and atolls, it’s hard not to be impressed by the incredible diversity of corals and unique marine life on show.',
        moluccas: 'The Moluccas are home to a spectrum of dive sites from black sand muck with critters through to bountiful coral reefs teeming with life.',
        papua: 'From lush, tropical rain forests on land to pristine coral reefs underwater, the diversity of nature is nowhere more apparent than in Papua.'
    },
    IND: {
        top: 'Kepulauan Indonesia yang terdiri dari 17.000 lebih pulau, ribuan kilometer garis pantai dan lebih dari 1.500 titik selam senantiasa menanti untuk dijelajahi. Setiap daerah menampilkan karakteristik daerah yang khas dengan keunikannya masing-masing.',
        sumatra: 'Sweeping currents bring hordes of fish and action to Pulau Weh’s dive sites. Riau offers a more relaxing pace but just as impressive.',
        java: 'Explore the Thousand Islands, dive the mighty Krakatoa volcano and go off the grid in Karimunjawa. Java is full of surprises underwater.',
        bali: 'Vibrant reefs, resident manta rays, seasonal mola, a WWII wreck, muck diving and drifts – Bali is full of choices and has something for everyone!',
        ntb: 'Lombok’s Gili Islands offer palm-fringed white sand beaches and a care free vibe. They are the perfect location to kick back, relax and dive!',
        ntt: 'Welcome to the land of the dragons and some of Indonesia’s most iconic dive sites. East Nusa Tenggara is a must for anyone seeking adventure!',
        borneo: 'Step away from the crowds and back into nature in Borneo. Resident manta rays and remote reefs make this an idyllic off-grid destination.',
        sulawesi: 'From world-class muck through to walls and atolls, it’s hard not to be impressed by the incredible diversity of corals and unique marine life on show.',
        moluccas: 'The Moluccas are home to a spectrum of dive sites from black sand muck with critters through to bountiful coral reefs teeming with life.',
        papua: 'From lush, tropical rain forests on land to pristine coral reefs underwater, the diversity of nature is nowhere more apparent than in Papua.'
    }
}

export default content