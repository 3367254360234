import React, { useState } from "react";
import './styles.scss'
import Logo from '../../../Images/logo.svg'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import server from '../../../config/server'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  iconUser: {
    color: '#999999',
    margin: '0 auto',
    fontSize: 28
  },
  divider: {
    height: '75%',
    margin: 'auto 0',
    backgroundColor: '#999999',
    width: 0.5
  },
  checkbox: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#EAEAEA'
  },
  buttonLogin: {
    height: '44px',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#FFFFFF',
    marginTop: "2rem",
    boxShadow: 'none',
  }
}));

const CheckboxCustom = withStyles({
  root: {
    color: '#999999',
    '&$checked': {
      color: '#E23F81'
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default function LoginPage() {
  const classes = useStyles();
  const History = useHistory();
  const [remember, setRemember] = useState(null)
  const [secret, setSecret] = useState(true)
  const [input, setInput] = useState({ username: "", password: "" })

  function onChangeInput(event) {
    let { name, value } = event.target

    setInput({
      ...input,
      [name]: value
    })

  }

  const handleChange = (event) => {
    remember ? setRemember(null) : setRemember(event.target.value)
  };

  const handleLogin = () => {
    const SwalCustom = Swal.mixin({
      customClass: {
        cancelButton: 'btn-swal-wrong'
      },
      buttonsStyling: false
    })

    axios.post(`${server}/user/login`, {
      username: input.username,
      password: input.password
    })
    .then(function (response) {
      localStorage.setItem('access_token', response.data.access_token)
      History.push('/admin/dive-location')
      
    })
    .catch(function (error) {
      console.log(error);
      SwalCustom.fire({
        html: `
          <div class="modal-forget-wrapper" >
            <div class="modal-forget-wrapper-2" >
              <i class="fas fa-times fa-times-custom"></i>
            </div>
            <span class="modal-forget-wrapper-txt-header" >
              Email/Password Salah
            </span>
            <span class="modal-forget-wrapper-txt" >
              Silahkan coba lagi untuk memasukan email/password yang benar
            </span>
          </div>
        `,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Coba Lagi',
        showConfirmButton: false,
        showCloseButton: true,
        reverseButton: true
      }).then((result) => {
        if (result.value) {
        } else {
        }
      })
    });

    
  }

  return (
    <div className="container-login-pages" >
      <div className="contain-login-page" >
        <span className="txt-login-page" >
          Welcome to Diving in Indonesia Website
        </span>
        <span className="txt-login-page-small" >
          Ministry of Tourism, Republic of Indonesia
        </span>
        <img src={Logo} alt="logo" className="logo-wi-login" />
        <div className="container-input-login" >
          <div className="input-styles" >
            <div className="input-icons" >
              <AccountCircleIcon className={classes.iconUser} />
            </div>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <input type="text" className="input-login-txt" name="username" value={input.username} onChange={onChangeInput} placeholder="Email" />
          </div>
          <div className="input-styles" style={{ marginTop: 30 }}>
            <div className="input-icons" >
              <VpnKeyIcon className={classes.iconUser} />
            </div>
            <Divider orientation="vertical" flexItem className={classes.divider} />
            <input type={secret ? 'password' : 'text'} name="password" value={input.password} onChange={onChangeInput} className="input-login-txt" placeholder="Password" />
            <div className="eye-secret" >
              {
                secret ?
                  <i class="fas fa-eye eye-custom " onClick={() => setSecret(false)} />
                  :
                  <i class="fas fa-eye-slash eye-custom-secret" onClick={() => setSecret(true)} />
              }
            </div>
          </div>
          <Button variant="contained" color="primary" className={classes.buttonLogin} onClick={() => handleLogin()}>
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}
