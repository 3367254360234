import { Typography, Box, Grid } from '@material-ui/core'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import AppBarClient from '../../../Components/AppBarClient'
import Footer from '../../../Components/Footer'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DrawerMobile from '../../../Components/Drawer'
import { useSelector } from 'react-redux'
import kupang from '../../../Images/CD_NTT.jpg'
import logo from '../../../Images/CD_Baubau001.jpg'

export default function Index() {
    const history = useHistory()
    const matches = useMediaQuery('(min-width:600px)');
    const langClient = useSelector(state => state.data.langClient)
   
    return (
        <>
            {matches ? <AppBarClient /> : <DrawerMobile />}
            <Box marginTop={matches? "10rem":"0"} width={matches?"86%":"94%"} paddingX={matches?"7%":'3%'} bgcolor="#F2F3F9" textAlign="center" marginBottom="5rem">
                <Box marginTop="3rem" display="flex" flexDirection="column" alignItems="center">
                   
                    <Typography className="titlePage" style={matches?{ fontWeight: "900", fontSize: "48px" }: { fontWeight: "700", fontSize: "24px" } }>{langClient =='EN'? "Take the Challenge to the Next Level!":"Jelajahi Lebih Dalam Lagi!"}</Typography>
                    <Box width={matches?'50%':'100%'} marginTop='1rem'>
                        <Typography style={!matches?{ fontSize: "14px" }:{ fontSize: "16px" } }>{langClient == 'EN'? 'Hidden beneath Indonesia’s volcanic landscapes lay pristine limestone labyrinths and breathtaking chambers adorned with fossils. Take a dive through the ancient caves of Southeast Sulawesi and Nusa Tenggara and discover Indonesia’s incredible subterranean geology.' : 'Tersembunyi di bawah lanskap vulkanik Indonesia, terhampar labirin batu gamping murni dan ruang-ruang menakjubkan berhiaskan fosil kerang. Selami gua-gua purba di Sulawesi Selatan dan Nusa Tenggara dan temukan pengalaman alam bawah tanah Indonesia yang luar biasa.' } </Typography>
                    </Box>
                </Box>
                <Box marginTop="3rem">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Box onClick={() => [history.push(`/cavediving/baubau`)]} style={{cursor:"pointer"}}>
                                {/* <img src="https://i.ibb.co/ByTDDFh/cave-baubau.png" width="100%" /> */}
                                <Box height={matches ? "27vw" : "250px"} style={{ backgroundImage: `url(${logo})`, backgroundSize: "cover", backgroundPosition: "center" }}></Box>
                                
                                <Box textAlign="left" marginTop="1rem">
                                    <Typography variant="h6">Baubau, {langClient =='EN'? "Southeast Sulawesi":"Sulawesi Tenggara"} </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box onClick={() => [history.push(`/cavediving/kupang`)]} style={{cursor:"pointer"}}>
                                {/* <img src={kupang} width="100%" /> */}
                                <Box height={matches ? "27vw" : "250px"} style={{ backgroundImage: `url(${kupang})`, backgroundSize: "cover", backgroundPosition: "center" }}></Box>
                                <Box textAlign="left" marginTop="1rem">
                                    <Typography variant="h6">Kupang, {langClient =='EN'? "East Nusa Tenggara":"Nusa Tenggara Timur"} </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
           
                <Footer />
           
        </>
    )
}
