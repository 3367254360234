export const showDate = (dateParams) => {
    let date = new Date(dateParams).getDate()
    let monthNumber = new Date(dateParams).getMonth()
    let month = ''

    let year = new Date(dateParams).getFullYear()
    switch (monthNumber) {
        case 0:
            month = 'January'
            break;
        case 1:
            month = 'February'
            break;
        case 2:
            month = 'March'
            break;
        case 3:
            month = 'April'
            break;
        case 4:
            month = 'May'
            break;
        case 5:
            month = 'June'
            break;
        case 6:
            month = 'July'
            break;
        case 7:
            month = 'August'
            break;
        case 8:
            month = 'September'
            break;
        case 9:
            month = 'October'
            break;
        case 10:
            month = 'November'
            break;
        case 11:
            month = 'December'
            break;
        default:
            month = ''
            break;
    }
    return date + ' ' + month + ' ' + year
}

export const showDay = (date) => {
    let hari = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', "Friday", 'Saturday']
    return hari[new Date(date).getDay()]
}

export const showDateInd = (dateParams) => {
    let date = new Date(dateParams).getDate()
    let monthNumber = new Date(dateParams).getMonth()
    let month = ''

    let year = new Date(dateParams).getFullYear()
    switch (monthNumber) {
        case 0:
            month = 'Januari'
            break;
        case 1:
            month = 'Februari'
            break;
        case 2:
            month = 'Maret'
            break;
        case 3:
            month = 'April'
            break;
        case 4:
            month = 'Mei'
            break;
        case 5:
            month = 'Juni'
            break;
        case 6:
            month = 'Juli'
            break;
        case 7:
            month = 'Agustus'
            break;
        case 8:
            month = 'September'
            break;
        case 9:
            month = 'Oktober'
            break;
        case 10:
            month = 'November'
            break;
        case 11:
            month = 'Desember'
            break;
        default:
            month = ''
            break;
    }
    return date + ' ' + month + ' ' + year
}

export const showDayInd = (date) => {
    let hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
    return hari[new Date(date).getDay()]
}

