import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import { Link } from 'react-router-dom'
import './style.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery';

const locs = [
    { posA: 0.9, posB: 104.45, to: 'dive-location/Sumatra/21', title: 'Riau Islands' },
    { posA: 5.82, posB: 95.28, to: 'dive-location/Sumatra/20', title: 'Weh Island' },
    { posA: -8.283832198, posB: 115.586830986, to: 'dive-location/Bali/1', title: 'Tulamben & Amed' },
    { posA: -8.5253145654, posB: 115.503731318, to: 'dive-location/Bali/2', title: 'Padangbai' },
    { posA: -8.7333304, posB: 115.5333312, to: 'dive-location/Bali/3', title: 'Nusa Lembongan, Nusa Penida & Ceningan' },
    { posA: -8.57, posB: 115.55, to: 'dive-location/Bali/4', title: 'Gili Tepekong, Mimpang & Biaha' },
    { posA: -8.08333, posB: 114.517, to: 'dive-location/Bali/5', title: 'Pemuteran & Menjangan Island' },
    { posA: -6.102, posB: 105.423, to: 'dive-location/Java/23', title: 'Krakatoa & Ujung Kulon' },
    { posA: -6.0, posB: 106.859, to: 'dive-location/Java/22', title: 'Thousand Islands' },
    { posA: -5.81917, posB: 110.459, to: 'dive-location/Java/24', title: 'Karimun Java' },
    { posA: -8.527716, posB: 119.4833198, to: 'dive-location/East%20Nusa%20Tenggara/6', title: 'Komodo' },
    { posA: -10.1718, posB: 123.6075, to: 'dive-location/East%20Nusa%20Tenggara/7', title: 'Alor & Kupang' },
    { posA: 0.5, posB: 117.5, to: 'dive-location/Kalimantan/25', title: 'East Kalimantan' },
    { posA: 1.68, posB: 124.6, to: 'dive-location/Sulawesi/8', title: 'Bunaken Marine Park' },
    { posA: 1.48218, posB: 124.84892, to: 'dive-location/Sulawesi/9', title: 'Manado Bay' },
    { posA: 1.433333, posB: 125.233333, to: 'dive-location/Sulawesi/11', title: 'Lembeh Strait' },
    { posA: 1.77417, posB: 125.054, to: 'dive-location/Sulawesi/12', title: 'Bangka Island & Outlying Islands' },
    { posA: -5.31934, posB: 123.5948, to: 'dive-location/Sulawesi/13', title: 'Wakatobi' },
    { posA: -0.416665, posB: 121.8666632, to: 'dive-location/Sulawesi/14', title: 'The Togean Islands' },
    { posA: -6.0, posB: 120.499998, to: 'dive-location/Sulawesi/15', title: 'Selayar Island & Bira' },
    { posA: -3.69543, posB: 128.18141, to: 'dive-location/The%20Maluku%20Islands/16', title: 'Ambon Bay' },
    { posA: 0.47158, posB: 127.94775, to: 'dive-location/The%20Maluku%20Islands/17', title: 'Weda Bay' },
    { posA: -4.583331, posB: 129.916663, to: 'dive-location/The%20Maluku%20Islands/18', title: 'The Banda Islands & The Banda Sea' },
    { posA: 2.3166654, posB: 128.5333312, to: 'dive-location/The%20Maluku%20Islands/19', title: 'Morotai' },
    { posA: -8.565, posB: 116.351, to: 'dive-location/West%20Nusa%20Tenggara/30', title: 'Lombok' },
    { posA: -8.3499986, posB: 116.0333332, to: 'dive-location/West%20Nusa%20Tenggara/31', title: 'The Gili Islands (Trawangan, Meno & Air)' },
    { posA: -0.2333324, posB: 130.5166646, to: 'dive-location/West%20Papua/26', title: 'Raja Ampat' },
    { posA: -0.1999992, posB: 130.83333, to: 'dive-location/West%20Papua/27', title: 'Dampier Strait (Raja Ampat)' },
    { posA: -1.890163106, posB: 130.083499666, to: 'dive-location/West%20Papua/28', title: 'Misool (Raja Ampat)' },
    { posA: -2.5, posB: 135.3, to: 'dive-location/West%20Papua/29', title: 'Cendrawasih Bay' },
]


export default function Map() {
    const matches = useMediaQuery('(min-width:600px)');
    const [zoom, setZoom] = useState(5)
    const [flag, setFlag] = useState(true)

    useEffect(() => {
        if (matches) {
            setZoom(5)
        } else {
            setZoom(3)
        }
        setFlag(!flag)
    }, [matches])

    return (
        <div>

            <MapContainer center={[-2.5489, 118.014]} zoom={zoom} scrollWheelZoom={false} tap={false}>
           
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution='Powered by <a href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>'
                // url="https://maps.geoapify.com/v1/styles/positron-blue/style.json?apiKey=1cdaa47f14b049d9a68bafdf6b0ed7d0"
                />
                {
                    locs.map((loc, i) => {
                        return (
                            <Marker position={[loc.posA, loc.posB]}>
                                <Popup>
                                    <Link to={loc.to} className='link'>{loc.title}</Link>
                                </Popup>
                            </Marker>
                        )
                    })
                }
            </MapContainer>


        </div>
    )
}
