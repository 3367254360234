import { Box, Button, makeStyles, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, withStyles, Paper, Menu, MenuItem } from '@material-ui/core'
import ArrowDropDownTwoToneIcon from '@material-ui/icons/ArrowDropDownTwoTone';
import React from 'react'
import { useHistory } from 'react-router-dom';

///component


const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#E5E5E5",
        color: "#444444",
        fontWeight: 500,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: "#fafafa",
        },
    },
}))(TableRow);


/////data dummy


const locs = [
    "Sumatra", "Jawa", "Bali", "West Nusa Tenggara", "East Nusa Tenggara", "Borneo", "Sulawesi", "Mollucas", "Papua"
]

///styles

const useStyles = makeStyles((theme) => ({
    root: {
        background: "white",
        width: "96%",
        minHeight: "10vw",
        padding: "1.5rem",
        margin: '1rem'
    },
    btnPilihan: {
        textTransform: "none",
        backgroundColor: '#EEF7FF',
        boxShadow: 'none',
        marginLeft: "1rem",
        paddingRight: "0rem",
        '&:hover': {
            paddingRight: "0rem",
            backgroundColor: '#28B0E6',
            color: 'white',
            boxShadow: 'none',
        },
    }
}))

export default function Index() {
    const classes = useStyles()
    const history = useHistory()

    function view (e, idLocation) {
        history.push (`/admin/freediving/${idLocation}`)
    }

    return (
        <>
            <Box width="100%">
                <Box paddingX="1rem">
                    <Typography variant="h6">FREEDIVING LOCATION</Typography>
                </Box>
                <Box className={classes.root}>
                    <Box>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">NO</StyledTableCell>
                                        <StyledTableCell align="left">LOCATION/ISLAND</StyledTableCell>
                                        <StyledTableCell align="center">ACTION</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locs.map((loc, index) => (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center" style={{ width:"2rem" }}>{index + 1}</StyledTableCell>
                                            <StyledTableCell align="left" style={{ width: "80%" }}>{loc}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <Button variant="contained" style={{ textTransform: "none", boxShadow: "none" }} color="primary" onClick={(e) => view(e, index)}>View</Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

