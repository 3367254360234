import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core'
import { useSelector } from 'react-redux';

export default function ScrollDialog() {
    const [open, setOpen] = React.useState(false);
    const langClient = useSelector(state => state.data.langClient)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <div>
            <Button onClick={() => handleClickOpen()} color="primary" variant="contained" style={{ textTransform: "none", width: "10rem", boxShadow: "none" }}>Detail</Button>
            <Dialog
                style={{ maxHeight: "80%", marginTop: "5%" }}
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">IMMIGRATION</DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {
                            langClient == 'EN' ?
                                <Box>
                                    <Typography><b>Passport</b></Typography>
                                    <Typography>Passports must be valid for more than 6 (six) months since the date of entry.</Typography><br />
                                    <Typography><b>1. Visa Free (up to 30 days)</b></Typography>
                                    <Typography>Nationals of <span><Link style={{ color: "#28B0E6" }} to="/regulations/countries">169 countries *)</Link></span> are eligible for a visa-free visit of up to 30 days, with condition as follows:</Typography>

                                    <ul>
                                        <li>It is not extendable, you must leave after 30 days is up.</li>
                                        <li>It is not granted for the journalist/media to do coverage in Indonesia.</li>
                                        <li>No working or study is allowed</li>
                                    </ul>
                                    <Typography><b>2. Visa for a visit of up to 2 months</b></Typography>
                                    <Typography>If you wish to stay for 2 months, there are 2 ways to obtain the visa, either:</Typography>
                                    <ul>
                                        <li>Obtaining a visa in your home or residing country prior to arrival by applying to the nearest Indonesian    embassy/consulate.</li>
                                        <li>Requesting a visa on arrival/VOA (valid for 30 days) which costs US$35. This type of visa is extendable for a further 30 day. But, you must leave the country after 2 months.</li>

                                    </ul>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                    <Typography><b> 3. Visa for a visit of more than 2 up to 8 months</b></Typography>
                                    <Typography> If you wish to stay more than 2 months up to 8 months, you must obtain prior to arrival for 2 months and can be extended every month for a maximum of 6 months (8 months in total).</Typography>
                                    <Typography> There are two types of 211 Social Visas:</Typography>
                                    <ul>
                                        <li>The 211/30 visa is usually given for conventions or meetings that are short term.</li>
                                        <li>The 211/60 is given for Social/Cultural purpose. This visa which gives you 60 days on arrival, and is renewable every month.</li>
                                    </ul>
                                    <Typography><b> 4. Visa Extensions</b></Typography>
                                    <Typography> You do not need to be in a port of entry for get a visa extension. There are many Immigration offices all over Indonesia. A list of offices where visa extensions can be found at www.indonesiapassport.com/immigration_office.htm</Typography><br />
                                    <Typography><b>  5. Visa for Crew</b></Typography>
                                    <Typography>  Short Visit Visa index: 111 is a visa/entry permit for crews of foreign vessels to be operating for a certain period of time in the Indonesian territorial waters. Holders of visit visa index 111 may apply for DAHSUSKIM directly upon arrival to Indonesia without the need to change the index.</Typography>
                                    <Typography>   Holders of visit visa index 111, upon arrival, will be picked by staffs delivering letters of the Director General of Immigration who will take them to the vessel and sign on at the nearest immigration office at the port.</Typography>
                                    <Typography>   At the departure, they will also be taken by staffs delivering letters of the immigration office to be signed on at the immigration office of the airport. The taking process is called escorting in order to apply WASKAT (Tight Surveillance).</Typography>
                                    <Typography>  Visa applicant must have partner in Indonesia that is a company having complete business permits in the business line of Shipping such as SIUPAL, issued by the Directorate General of Sea Transportation The role of partner Company as such shall further to become the sponsor and shall provide all the necessary documents for visa application process.</Typography><br />
                                    <Typography><b>  6. Visa for Journalist/Media</b></Typography>
                                    <Typography>  A Journalist Visa is a visa issued to foreign citizens who intend to visit Indonesia for journalist activities, media coverage, or commercial/non-commercial filming/video shooting activities. This visa has to be obtained prior to arrival by applying to the nearest Indonesian embassy/consulate.</Typography>
                                </Box> :
                                <Box>
                                    <Typography><b>Paspor</b></Typography>
                                    <Typography>Masa berlaku paspor minimal 6 (enam) bulan terhitung dari tanggal masuk.</Typography><br />
                                    <Typography><b>Visa</b></Typography>
                                    <Typography><b>1. Bebas Visa Kunjungan (berlaku hingga 30 hari)</b></Typography>
                                    <Typography>Warganegara dari <span><Link style={{ color: "#28B0E6" }} to="/regulations/countries">169 negara *)</Link></span> ini  berhak mendapatkan bebas visa kunjungan untuk masa tinggal hingga hingga 30 hari, dengan ketentuan sebagai berikut:</Typography>

                                    <ul>
                                        <li>tidak dapat diperpanjang, anda harus segera keluar Indonesia setelah 30 hari.</li>
                                        <li>tidak berlaku bagi jurnalis/media untuk melakukan liputan di Indonesia.</li>
                                        <li>tidak diizinan untuk bekerja atau belajar</li>
                                    </ul>
                                    <Typography><b>2. tidak diizinan untuk bekerja atau belajar</b></Typography>
                                    <Typography>Untuk rencana masa tinggal selama 2 bulan, terdapat 2 cara untuk mendapatkan visa tersebut, yaitu dengan cara:</Typography>
                                    <ul>
                                        <li>mengajukan visa ke kedutaan/konsulat Indonesia terdekat di negara asal sebelum menuju ke Indonesia.</li>
                                        <li>meminta Visa Saat Kedatangan/VOA yang berlaku untuk 30 hari dengan biaya US $35. Jenis visa ini dapat diperpanjang untuk 30 hari berikutnya. Setelah 2 bulan masa tinggal habis, wisatawan harus meninggalkan Indonesia.</li>

                                    </ul>
                                    <Typography></Typography>
                                    <Typography></Typography>
                                    <Typography><b> 3. Visa Kunjungan untuk masa tinggal 2 hingga 8 bulan</b></Typography>
                                    <Typography> Untuk rencana masa tinggal di Indonesia selama 2 hingga 8 bulan, wisatawan asing harus terlebih dahulu mendapatkan visa kunjungan yang berlaku selama 2 bulan dari kedutaan/konsulat Indonesia di negara asal. Kemudian, visa tersebut dapat diperpanjang lagi hingga 6 (enam) kali dengan masa berlaku masing-masing 1 bulan setiap kali perpanjangan. Dengan demikian, masa tinggal total dapat mencapai  8 bulan.</Typography>
                                    <Typography>Ada dua tipe Visa Sosial 211:</Typography>
                                    <ul>
                                        <li>Visa 211/30 yang biasanya diterbitkan untuk keperluan konvensi atau pertemuan yang biasanya berlangsung singkat.</li>
                                        <li>Visa 211/60 yang diterbitkn untuk kepentingan sosial/ budaya. Visa ini berlaku 60 hari dari tanggal kedatangan dan bisa diperpanjang setiap bulan maksimal 6 kali perpanjangan.</li>
                                    </ul>
                                    <Typography><b> 4. Perpanjangan Visa</b></Typography>
                                    <Typography> Perpanjangan visa tidak perlu dilakukan di pelabuhan saat pertama kali memasuki wilayah Indonesia. Perpanjangan dapat dilakukan di berbagai kantor imigrasi yang tersebar di seluruh Indonesia. Daftar seluruh kantor imigrasi untuk melakukan perpanjangan visa dapat anda lihat di www.indonesiapassport.com/immigration_office.htm</Typography><br />
                                    <Typography><b>  5. Visa untuk Awak Kapal</b></Typography>
                                    <Typography>  Visa Kunjungan Singkat indeks: 111 adalah visa/izin masuk bagi awak kapal asing untuk bekerja selama jangka waktu tertentu di perairan Indonesia. Pemegang Visa Kunjungan Singkat indeks 111 dapat mengajukan ke DAHSUSKIM langsung pada saat kedatangan di Indonesia tanpa perlu mengubah indeks.</Typography>
                                    <Typography>   Pada saat kedatangan, pemegang visa kunjungan indeks 111, akan dijemput oleh staf yang membawa surat Dirjen Imigrasi ke kapal dan mengesahkannya di kantor imigrasi terdekat dengan pelabuhan.</Typography>
                                    <Typography>   Pada saat keberangkatan, mereka juga akan diantar oleh staf yang membawa surat kantor imigrasi untuk ditandatangani di kantor imigrasi bandara. Proses pengantaran tersebut disebut pengawalan untuk menerapkan WASKAT (Pengawasan Ketat).</Typography>
                                    <Typography>  Pemohon visa harus memiliki mitra di Indonesia yaitu perusahaan yang memiliki izin usaha lengkap di industri bisnis pelayaran seperti SIUPAL, yang dikeluarkan oleh Direktorat Jenderal Perhubungan Laut. Perusahaan rekanan tersebut selanjutnya akan berperan sebagai sponsor dan harus menyediakan semua dokumen yang diperlukan untuk proses permohonan visa.</Typography><br />
                                    <Typography><b>  6. Visa Jurnalis/ Media</b></Typography>
                                    <Typography>  Visa Jurnalis adalah visa yang dikeluarkan untuk warga negara asing yang bermaksud mengunjungi Indonesia untuk kegiatan jurnalisme, peliputan media, atau kegiatan pembuatan film/video komersial maupun non-komersial. Visa ini harus diperoleh sebelum kedatangan di Indonesia dengan cara mengajukan permohonan ke kedutaan/konsulat Indonesia di negera asal mereka.</Typography>
                                </Box>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
          </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}